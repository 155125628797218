//PAN and Aadhaar under the Income Tax  Act

var qandas = [
{
 question:"Who are the persons who need to have a Permanent Account Number (PAN) under the Income Tax Act ?",
items:[
    {
        text:"Section 139A of the  Act specifies the persons who are required to apply for  a PAN Number within the prescribed time limit."
    },
    {
        type:"list",
        list:[
            " A person whose total income or the personal income of any other person in respect of which he is assessable under the Act during the previous year was above the basic exemption level.",


            " A person carrying on any business or profession whose turnover or gross receipts are or likely to exceed Rs.5,00,000 in any previous year.",


            " A person  required to file a return of Income under Section 139(4), i.e. CharitableTrusts and Institutions.",


            "  A resident, other than an individual, which enters into a financial transaction of an amount aggregating to Rs.2,50,000 in any previous year (clause iv)",


            " Anyone who is the Managing Director, Director, Partner, Trustee, Founder, Author, Karta, Chief Executive Officer, Principal Officer, Office Bearer of the person referred to in clause (iv) or any person competent to act on behalf of the person referred to in clause (iv)."
        ]
    },
    {
 
        text:"The Central Government for the purpose of collecting any relevant information under the Act can also, by way of notification, specify any class or classes of persons to apply for a PAN."
    }
]

},
{

    question:"Can a person apply for a PAN even though he is not required to?",
    items:[
        {
            text:"Yes. According to the Act, a person who does not fall under Section 139A(1) or Section 139A(2) can apply for a PAN under Section 139A(3)."
        }
    ]
},
{

    question:"For which class of people is PAN mandatory?",
    items:[
        {
            text:"The Central Government has the power to notify the class of persons for whom PAN is mandatory [Section 139(A) (1A)]."
        },
        {
            type:"list",
            list:[
                " All the taxpayers under the Income Tax Act or Goods and Services Tax Act (GST) or any other law must have a PAN. In fact, before applying for GST registration, the assessee should have a PAN.",


                " Exporters and Importers should also have a PAN. They should apply for a PAN before making any export or import.  "
            ]
        }
    ]


},
{


    question:"Is it necessary to quote the Aadhaar number while filing tax returns?",
    items:[
        {
            text:"Yes. "
        },
        {
            text:"As per Section 139AA(1), every person who is eligible to get an Aadhaar Number on or after 01/7/2017, must mention the Aadhaar Number in:"
        },
        {
            type:"list",
            list:[
                "The income tax returns",
                "The application for PAN"
            ]
        },
        {
            text:"As per Section 139AA(2), every person who has a PAN as on 01/ 7/2017 and is eligible to obtain an Aadhaar Number, must inform the Aadhaar No. to the relevant tax authority within the specified time."
        }
    ]
},
{

    question:"Which are the documents in which PAN has to be mentioned ?",
    items:[
        {
            text:"PAN to be given in the following documents:"
        },
        {
            type:"list",
            list:[
                "Income Tax returns filed by an assessee or any correspondence with the Income Tax Department,",


                "Challans for payment of any sum under the IncomeTax Act.",


                "Documents relating to transactions prescribed by the Central Board of Direct Taxes.",

                "When tax is deducted / collected at source, the deductee should inform the deductor of the PAN. This is to ensure that the amount paid to the tax authority is credited to the deductee’s account.",


                "A deductor, while remitting the deducted amount to the Income Tax Department should mention the PAN of the deductee, the purpose for which the deduction is made  and the particulars of the person to be credited."
            ]
    }
    ]
},
{


    question:"Which are the transactions for which PAN has to be quoted as per Income Tax rules?",
    items:[

        {

            text:"Clause ( c) of subsection (5) of Section 139A and Rule 114B list the transactions for which PAN has to be quoted."
        },
        {
            type:"list",
            list:[
        " Sale or purchase of motor vehicles as defined in Section 2(28) of the Motor Vehicles Act, 1988, which requires registration under chapter IV of that Act other than two wheelers. ",


        "  Opening a bank account with any bank or cooperative bank to which Banking Regulation Act, 1949 applies.", 


        " Applying for the issue of any Credit / Debit Card to any bank or banking  company governed by the Banking Regulation Act,1949.",


        " Opening of a Demat account with a depository /  participant / custodian or any other person registered under Section 12 (1A), Securities and Exchange Board of India Act, 1992.  ",


        " Making a payment to a hotel /  restaurant against a bill /  bills exceeding Rs 50,000 in cash.",


        " Making a payment related to foreign travel / purchase of foreign currency exceeding Rs 50,000 at a time in cash.",


        " Making a payment to any Mutual Fund for purchase of units exceeding Rs. 50,000 at one time.",


        " Making a payment to any company / institution for buying debentures exceeding Rs 50,000 at one time.",


        " Making a payment to the Reserve Bank of India for buying its bonds exceeding Rs 50,000.",


        " Making a  Deposit of more than Rs.50,000 in cash in one day, with a commercial bank or co-operative bank regulated by the Banking Regulation Act,1949.",


        " Buying bank drafts /  pay orders / bankers cheque for Rs. 50,000 or more, in cash on a day, from a Bank or a Co-operative Bank regulated by the Banking Regulation Act,1949.",




        " Making a  time deposit of more than Rs 50,000 at one time or exceeding Rs.5,00,000 in any financial year with a banking company /  co-operative bank / post office or Nidhi (Section 406, Companies Act 2013) or with a Non Banking Finance company NBFC.",


        " Making a payment exceeding Rs.50,000 in a year as life insurance premium to an insurer.",


        " Entering into  a  contract for purchase / sale of securities as defined in Section 2(h) of the Securities Contracts (Regulation) Act, 1956 for an amount exceeding Rs1,00,000 / transaction.",


        " Purchase / sale  of unlisted shares of a company for an amount exceeding Rs1,00,000 / transaction. ",


        " Purchase / sale of immovable property valued at more than Rs.10 lakhs.",


        " Purchase / sale of goods and services other than those listed above exceeding Rs.2 lakhs / transaction."
    
            ]
    }

    ]

},
{

    question:"What is the rule, as per the Income Tax Act, for a minor who enters into a transaction for which quoting of PAN is essential ? ",
    items:[
        {
            text:"If a transaction, which requires the quoting of PAN, is done by a minor who has no taxable income, he has to give  the PAN of his father / mother / guardian in the transaction document. "
        }
    ]
},
{


    question:"What’s the rule as per the Income Tax Act if a person quotes a wrong PAN in any relevant document? ",
    items:[
        {
            text:"According to Section 272B (2), if a person knowingly gives a wrong PAN  in any document / intimates a wrong PAN to any person or tax authority, the assessing officer shall impose a penalty of Rs.10,000."
        }
    ]
},
{
    question:"What is the penalty for failure to follow the rules relating to quoting of PAN ?",
    items:[
        {
            text:"As per Section 272B,  if a person does not comply with the rules relating to quoting of PAN, laid down in Section 139A, he will have to pay a penalty of Rs. 10,000 for each default."
        }
    ]


},
{


    question:"What is the rule relating to quoting of Aadhaar Number?",
    items:[
        {
            text:"As per Section 139AA (1) of the Income Tax Act, every person who has a  Aadhaar Number as on 01/7/2017 or later, has to quote it:"
        },
        {
            type:"list",
            list:[
                "while filing the tax returns",
                "while applying for the PAN"
            ]
        },
        {

            text:"If he does not have an Aadhaar Number, he has to quote the Enrolment ID of the Aadhaar form issued to him."
        }
]
},
{


        question:"Is it possible to quote the Aadhaar Number instead of the PAN ?",
        items:[
            {
                text:"Section 139A(1) lays down the rule relating to interchangeability of PAN and Aadhaar in prescribed transactions. "
            },
            {
                type:"list",
                items:[
                    "A person who has an Aadhaar Number but not a PAN, is allowed to quote or intimate his Aadhaar Number in prescribed transactions. Such a person will be given a PAN in the due manner.",


                    "A person who has a PAN and has linked it with his Aadhaar Number [Section 139AA(2)], can quote his Aadhaar Number in place of the PAN.",
                    "Both the PAN and Aadhaar Numbers have to be duly authenticated or verified by the tax authority [Section 139A(6A)]. "
                ]
            },
            {
                text:"The person receiving any such document which requires mention of PAN and Aadhaar, should ensure that these numbers have been authenticated and quoted in the document [Section 139A (6B)]."
            }    
    ]

},
{

        question:"What is the rule if a person does not link the PAN with the Aadhaar?",
        items:[
            {
                type:"list",
                list:[
                "It is compulsory to link the PAN and Aadhaar Number.",


                " As per Section 139AA(2), if a person does not intimate his Aadhaar Number to the tax authority within the given time limit and link it with the PAN, the PAN shall be made inoperative as per the due process.",


                "He shall  be liable to pay the prescribed fees, not exceeding Rs.1000/- at the time of making the intimation after the due date."
                ]     
    }
        ]
}


]

export {qandas}

