import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const BankBook = ({  }) => {

    var items = [
        {
            title:"Bank Book",
            type:'paragraph',
            text:"Bank transactions are recorded in the Bank Book. The transactions could be purchases or sales. It could be money transferred into the account as a deposit, investment or loan."
        },
        {
            type:'paragraph',
            text:"The transactions of the business unit which have to be recorded in the Bank Book are:"
        },
        {
            type:'list',
            list:[
                "Capital brought in by proprietor (by cheque) ",
                "Purchase of goods from suppliers by cheque / credit",
                "Sale of goods on credit and part payment by buyers",
                "Cash deposited in bank",
                "Payment of rent, tax, utility charges, bank charges etc."
            ]
        },
        {
            type:"paragraph",
            title:"Cash Book",
            text:"The Cash book is used to keep a record of all cash transactions and bank transactions. It contains the record of all the cash receipts and payments."
        },
    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Bank Book and Cash Book</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default BankBook
