import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';

import Section from '../pieces/Section'


const Whatisaccounting = ({  }) => {


    var items = [
        {
            type:'paragraph',
            text:'Accounting is everywhere. You might know or not know it.'
        },
        {
            type:'paragraph',
            text:'You get up in the morning, go outside and cross the street to the store across the road. You buy a packet of milk and the day’s newspaper (for the still conventional folks). You pay the store owner Rs 40. '
        },
        {
            type:'paragraph',
            text:'You might not realize it but you have done your first accounting transaction of the day. And the storekeeper has done an accounting transaction as well, although probably not his first for the day. You might not record this transaction down but the storekeeper definitely has to. He has to know how much money he received from you and whether he received it in cash or by card or using a payment app.'
        },
        {
            type:'paragraph',
            text:'He also needs to record what he gave you for the cash. He needs to know how much tax has been paid on that, and if in India, what the GST rate is. He has to issue you an invoice and/or a receipt. By the way, do you know the difference between an invoice and a receipt? Don’t worry if you don’t, we will cover that as we go on.'
        },
        {
            type:'paragraph',
            text:'Now, you are at home and your child is getting ready to go to school. You give him Rs 50 and ask him to buy something from the school canteen. That’s your second accounting transaction of the day and your child’s first. If you were noting this in a book of accounts, you would have to credit yourself the Rs 50 and debit your child Rs 50. And, if your child was maintaining his books of accounts he would have to make a set of records as well.'
        },
        {
            type:'paragraph',
            text:'As you can clearly see, every financial give and take we engage in, is an accounting transaction.'
        },
        {
            type:'paragraph',
            text:'Accounting means “a counting” of all the financial transactions and recording them in the Books of Account.'
        },
        {
            type:'paragraph',
            text:'It is very easy to learn provided one takes a little bit of interest in the subject.'
        },
        {
            type:'paragraph',
            text:'Knowingly or unknowingly, all the persons including the children, are somehow or other using the accounting system in their day to day life .They may be doing so orally or in writing. They may receive money from different sources and spend it for different purposes. '
        },
        {
            type:'paragraph',
            text:'The recording of these transactions is called accountancy.'
        },
        {
            type:'paragraph',
            text:'All the financial transactions are recorded in monetary terms as money is the medium of exchange of goods and services and also, a measure of value. Normally, accounting is done in the currency of the respective countries.'
        },
        {
            type:'paragraph',
            text:'Why do businesses, like your shopkeeper described above, need to keep accounts?'
        },
        {
            type:'paragraph',
            text:'Businesses perform multiple transactions on a daily basis. It would become impossible to understand how much money the business receives and how much it spends, without proper records. '
        },
        {
            type:'paragraph',
            text:'Using basic accounting principles, they can record every transaction correctly and know how much money has been spent, how much received. That helps them to calculate their profit and how much tax they have to pay. It also helps them to reduce costs in the right places and make the business more profitable.'
        },
        {
            type:'paragraph',
            text:'That might get you thinking, Well, I don’t have a business, do I need to understand, learn or use accounting?'
        },
        {
            type:'paragraph',
            text:'The answer is YES. Why?'
        },
        {
            type:'list',
            list:[
                'Well, you have a family to run.',
                'You receive income as a salary. You spend it to satisfy day- to- day requirements and save some amount, maybe as a bank deposit.',
                'You might make an investment like buying a house or equity shares.',
                'You might take a loan.',
                'You might have more than one source of income. There might even be more than one earning member in your family.',
                'If you understand the basics of  accounting and record financial transactions, you can make sure that you are using your money wisely.',
                'And, when the dreaded Tax season arrives, with good accounting practices you can be sure that you are only paying what you actually need to.'
            ]
        },
        {
            type:'paragraph',
            text:'By the time you are done with this online book, whether you are an individual or a small business or maybe a large million dollar business, you will have answers to the following questions:'
        },
        {           
             type:'list',
            list:[
                'What is debit and credit?',
                'What is revenue expenditure and  capital expenditure?',
                'What is revenue income and capital income ?',
                'What is a current liability and what is a long term liability?',
                'What is a fixed asset, investment and a current asset?',
                'What are the Books of Accounts?',
                'How are the different Books of Accounts written?',
                'How can you make sure your Books of Accounts are correct?',
                'How can you use the books of accounts to analyze and understand your business or your personal financial position?'
        ]

        }
        
    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>What is Accounting ?</p>
            </Card.Header>  
        </Card>


        <Section items={items}/>



        </>


    )
}


export default Whatisaccounting
