//Tax Deduction and Collection at Source


var qandas = [

{
	question:"Why is income tax deducted or collected at source?",
	items:[
		{
			type:"list",
			list:[
				"Regular assessment of income is always done one year ahead of the previous year (i.e. in the assessment year).",
				"But tax is payable in the same previous year in which it is earned.",
				"Therefore, the Government has introduced measures in the Income Tax Act to deduct or collect tax at source or by way of Advance Tax.",
				"These taxes collected in advance in the previous year are called pre-paid taxes.",
				"These taxes paid in advance are later adjusted against the total tax due from the assessee.",
				"Provisions relating to deduction of tax at source are laid down in Sections 190 to 206CA."
			]
	}
	]
},
{
	question:"What is meant by ‘tax deducted at source’ ?",
	items:[
		{
			type:"list",
			list:[
		"‘Tax deducted at source’ (TDS) is the income tax which is deducted from the payments made or amount credited in the books of account of an assessee against goods supplied / services rendered.",
		"Tax deduction at source is done by the payer at prescribed rates at the time of actual payment made or accrual, whichever is earlier.",
		"This TDS is later adjusted against the total tax due from the assessee."
			]
		}
	]

},
{

	question:"What is meant by ‘tax collected at source’ ?",
	items:[
		{
			type:"list",
			list:[
		"Tax collected at source (TCS) is the income tax which is collected, at source, by the seller from the buyer or a person from his lessee / licensee etc. ",
		"The tax is collected at  the time of debiting the amount to the account of the buyer / licensee / lessee or the receipt of payment whichever is earlier.",
		"This pre-paid tax is later adjusted against the total tax due from the assessee."
			]
		}
	]
},
{


	question:"What is meant by ‘Advance payment of tax’ ?",
	items:[
		{
			type:"list",
			list:[
				" ‘Advance payment of tax’ means that under certain conditions, an assessee may be required to pay some income tax, in advance, in the previous year itself.",
				" This tax is on the income earned in the previous year.",
				" The advance payment is done  in specified installments.",
				" This pre-paid tax is deductible from the total tax due from the assessee."
			]
		}
	]

},
{

 question:"Is income tax paid by an employer on non-monetary benefits given to the employees also a case of tax collection at source?",
items:[
{
	text:"Yes."
},
{
	text:"Income tax paid by an employer under Section 192(1A) on non-monetary benefits provided to employees is also a case of tax collection at source (Chapter XVII of the Income Tax Act)."
}
]

},
{

	question:"What is meant by self-assessment tax?",
	items:[
		{
			text:"Self-assessment tax is the tax an assessee has to pay at the time of filing his tax returns, based on his own calculations of the return of income, after eligible deductions [ Section 140 (A)]."
		}
]
},
{


	question:"What is the rule relating to deduction of tax at source from salary?",
	items:[
		{
			text:"Section 192 of the Income Tax Act lays down the rules relating to TDS from salary."
		},
		{
			type:"list",
			list:[
				"If the salary of an employee is more than the basic exemption salary level, the employer should deduct tax at source from that salary.",


				"While doing so, the employer  should take into account all the deductions allowable to the employee under various sections of the Income Tax Act.",


				" The employee is duty-bound to provide to the employer all the details of any other source of income / the deductions he is entitled to under different provisions of the Act.",


				"The employer has to calculate the total amount of tax deductible in the previous year and divide that amount  by twelve (months). The average tax per month is then to be deducted by the employer each month."
			]
		}
	]
},
{

	question:"Who is liable to deduct tax from dividends ?",
	items:[
		{
			text:"As per Section 194 of the Income Tax Act, the Principal officer of an Indian company or a company which has made the prescribed arrangements for the declaration and payment of  dividends in India, including dividend on preference shares, shall be liable to deduct tax at source. According to the Finance Act 2020, the current rate of TDS is 10%."
		}
	]

},
{


	question:"When is TDS on dividend deducted ?",
	items:[
		{
			text:"TDS is to be deducted before the company distributes or makes any payment by way of dividend, by any method, to its shareholders in India."
		}
	]
},
{

	question:"Which are the situations when no TDS is deducted on dividends ?",
	items:[
		{
			text:"The First and Second proviso to Section 194 lay down the situations when no TDS is to be deducted / deduction is to be done at a concessional rate."
		},
		{

			text:"When : "
		},
		{
			type:"list",
			list:[
				"the dividend payable to an ‘individual’ shareholder does not exceed Rs.5000",
				"the dividend is  paid by any mode other than cash"
			]
		},
		{

			text:"When the dividend is paid to : "
		},
		{
			type:"list",
			list:[
			"Life Insurance Corporation of India (LIC), General Insurance Corporation of India (GIC) or any company formed under it ",
			"any other insurance company or business trust which are shareholders of the dividend declaring company"
			]
		}
	]

},
{

	question:"What is the rule regarding TDS from interest paid?",
	items:[
		{
			text:"Section 193 lays down that when interest is paid / payable on securities to a resident of India, income tax at the prescribed rate has to be deducted from such income at the time of payment."
		}
	]
},
{


	question:"What is the rate of TDS to be deducted from winnings from lottery, games, horse race etc?",
	items:[
		{
			type:"list",
			list:[
				"As per Section 194B, 30% of the winning amount from lottery, games etc must be deducted at source by the payment authority.",
				"In the case of income from horse racing, 30% of the amount must be deducted if the gross amount is more than Rs.10,000 (Section 194BB)"
			]
		}
	]
},
{

	question:"Is TDS deductible from the commission earned by a resident insurance agent?",
	items:[
		{
			text:"As per Section 194D, when a resident person is paid a commission, more than Rs.15000 in any previous year, by an insurance company for procuring and sustaining insurance business: "
		},
		{
			type:"list",
			list:[
				"5% TDS should be deducted from such person ( other than a company) and,",
				"10% from a domestic company."
			]
		}
	]

},
{

		question:"What is the TDS rate on any amount received from a Life Insurance Company ?",
		items:[
			{
				text:"As per Section 194DA, if any resident person is paid any amount from a Life Insurance Company, including bonus, TDS is to be deducted at source [ unless exempted under Section 10(10D)]."
			},
			{

				text:"The deduction of tax will be:"
			},
			{
				type:"list",
				list:[
					"at 1% up to 31.8.2019 ",
					"at 5% w.e.f 01/9/ 2019 and", 
					"20% if the payee does not have a PAN."
				]
			}

		]
},
{



	question:"What is the TDS rate on an amount received under the National Savings Scheme?",
	items:[
		{
			text:"Section 194EE lays down the rule in this connection."
		},
		{
 			text:"When a payment is made to a person under the National Savings Scheme, against the amount standing to his credit, including accrued interest, the payer has to deduct : "
		},
		{
			type:"list",
			list:[
				"10% tax, and",
				"4% surcharge and education cess"
			]
		}
	]

},
{


		question:"Is there any TDS exemption in the case of the amount received under the National Savings Scheme ?",
		items:[
			{
				text:"Yes."
			},
			{
				text:"No TDS is to be deducted :"
			} ,
			{
				type:"list",
				list:[
					"If the amount paid is less than Rs.2500 in a year",
					"If the payment is made to a legal heir of the person after the person’s death."
				]
			}
		]
},
{


		question:"Is there any TDS on commission on sale of lottery tickets ?",
		items:[
			{
				text:"As per Section 194G, when a lottery agent gets a payment (commission, prize) for his work of buying, selling, stocking or distributing lottery tickets, TDS is 5% of the amount paid."
			}
		]


},
{

		question:"What is the rule relating to TDS on the transfer of immovable property other than agricultural land ?",
		items:[
			{
				text:"Section 194-1A lays down the rule relating to TDS on transfer of immovable property other than agricultural land."
			},
			{
				text:"When the consideration value of such immovable property is Rs.50 lakhs or above, and the stamp duty value is also more than Rs.50 lakhs : "
			},
			{
				type:"list",
				list:[
					"TDS must be deducted at 1% of the higher amount, and",
					"If the transferor does not have a PAN, TDS rate will be 20%."
				]
			}
		]

},
{


		question:"What are the conditions for the payment of TDS in the case of transfer of immovable property?",
		items:[
			{
				type:"list",
				list:[
					"It is mandatory to make online payment of TDS. The challan for online payment is available on the TIN NSDL website.",
					"It is necessary that both the buyer and seller of immovable property must have PAN.",
					"The transferee or buyer does not require TAN for making the TDS payment."
				]
			}
		]

},
{

		question:"What is the rule relating to TDS on rent paid by individuals / HUF ?",
		items:[
			{
				text:"As per Section 194-1B, any Individual or HUF who has to pay rent to a resident, exceeding Rs.50,000 per month : "
			},
			{
				type:"list",
				list:[
					"TDS to be deducted : 5% of the rent amount",
					"If  the owner does not have a PAN : TDS 20%"
				]
			}
		]
},
{


	question:"Will the tax be deductible at source from the GST amount charged in a bill for professional services?",
	items:[
		{
			text:"If, as per the agreement between a payer and a resident payee, the GST amount is indicated separately in the bill against professional services rendered : "
		},
		{
			type:"list",
			list:[
				"Tax shall be deducted at source  on the amount excluding the GST (Chapter XVII-B of the Income Tax Act), if the fee exceeds Rs.30,000 in a financial year.",
				"If the professional services fee is purely for the personal purposes of the individual / HUF - No TDS to be deducted."
			]
		}
	]
},
{


	question:"Is there a provision for an assessee to request for TDS at a lower rate or no TDS at all?",
	items:[
		{
			text:"Yes."
		},
		{
			type:"list",
			list:[
				 "As per Section 197, Rule 28 and 28AA, an assessee to whom professional / technical fees is payable, can apply in Form No.13 electronically, mentioning his PAN, for a certificate for exemption fromTDS / TDS at a lower rate.",
				 "Such a certificate is usually issued to the person liable to deduct the tax, with intimation to the applicant of the certificate."
			]
		}
	]

},
{


	question:"What is the TDS rule if payments are done in cash?",
	items:[
		{
			text:"As per Section 194N, TDS is deductible in the case of aggregate cash payments in a previous year: "
		},
		{
			type:"list",
			list:[
				"2% - if the cash withdrawal is between Rs.20 lakhs and Rs.1 crore.",
				"5% - if the cash withdrawal is more than Rs.1 crore."
			]
		}
	]

},
{


	question:"What is the TDS provision for a person (buyer) who purchases goods from a resident seller?",
	items:[
		{	
			text:"Section 194Q lays down the rule in this connection."
		},
		{
			type:"list",
			list:[
				"If a buyer purchases goods worth more than Rs.50 lakhs in any previous year, from a resident seller, the TDS to be deducted is : 0.1% of the amount in excess of Rs.50 lakhs.",
				"The tax is to be deducted when the amount is credited to the seller’s account / time of payment by any other method, whichever is earlier."
			]
	}
	]
},
{


		question:"Is there any TDS on benefits or perquisites given in connection with business or profession?",
		items:[
			{
				type:"list",
				list:[
				"Yes. According to Section 194R, TDS has to be deducted on perquisites or benefits given in connection with business / profession.",
				"The rate of TDS is 10% of the value of benefit / perquisites"
		]
			}	
	]

},
{


		question:"What is the TDS rule for a situation where benefits or perquisites are given wholly or partly in kind?",
		items:[
			{
				text:"In such a situation where benefit or perquisite is wholly or partly in kind, and the cash component is not enough to meet the tax liability, the person who provides the benefit has to ensure that the tax to be deducted has been paid before providing the benefit. "
			}
		]


},
{

	question:"What are the modes by which a digital asset can be transferred from one person to another?",
	items:[
		{
			text:"The consideration for transfer of a digital asset can be : "
		},
		{
			type:"list",
			list:[
				"Wholly in cash",
				"Wholly in kind / in exchange for another digital asset",
				"Partly in cash and partly in kind"
			]
		}
	]
 
},
{

	question:"Is there any TDS on payment received for transfer of a digital asset?",
	items:[
		{
			text:"According to Section 194(S)(1), inserted w.e.f. 01/7/2022 : "
		},
		{
			text:"TDS at the rate of1% has to be deducted from the amount received as consideration for transfer of a digital asset."
		}
	]
},
{


	question:"What is the TDS rule if consideration for the transfer of a digital asset is not wholly in cash?",
	items:[
		{
			text:"According to the Proviso to Section 194S(1), if the consideration for the transfer of a digital asset is : "
		},
		{
			type:"list",
			list:[
				"wholly in kind / in exchange for another digital asset, or ",
				"partly in cash and partly in kind, and",
				"the cash component is insufficient to pay the TDS on the entire consideration"
			]
		},
		{
			text:"The  person responsible for paying  such consideration must ensure that the  tax to be deducted has been paid before releasing the consideration."
		}
	]
},
{


	question:"What is meant by ‘Income tax payable Net of Tax’ ?",
	items:[
		{
 			text:"This concept has been dealt with in Section 195A of the Income Tax Act."
		},
		{
			text:"It implies that:"
		},
		{ 
			type:"list",
			list:[
				"On the basis of an agreement, the burden of the tax imposed on any income under Sections 192 -195, has to be borne by the person who is liable to pay it.",
				"Thus, the income has to be raised to such an extent that, after the tax is deducted at source, the income is equal to the net amount payable under the agreement.  ",
				"However, no grossing up will be required in the case of non-monetary benefits given to the employee."
			]
		}
	]
},
{


	question:"What is the TDS rule in connection with any sum payable by a person to the Government, RBI etc?",
	items:[
		{
			text:"As per Section 196, no tax deduction has to be made in the case of any sum (interest, dividend etc) payable to : "
		},
		{
			type:"list",
			list:[
				"The Government,",
				"The Reserve Bank of India",
				"A corporation, established under a Central Act, which is exempt from income tax ",
				"A mutual fund specified under Section 10(23D)."
			]
		}
		]

},
{

	question:"Is there any TDS on income from units of Mutual Funds in the case of non-residents?",
	items:[
		{		
			text:"According to the provisions of Section 196A of the Income Tax Act,"
		},
		{
			type:"list",
			list:[
				"If a non-resident ( not a company) or any foreign company is due to receive any income from units of a Mutual Fund specified under Section 10(23D) or of any specified company, the person responsible for making the payment  should deduct income tax at the rate of 20%.",
				"This deduction shall be done at the time of credit of such sum to the payee’s account or payment by any other mode (cash,cheque, draft etc), whichever is earlier."
			]
		}
	]
},
{


	question:"What is the TDS rule in relation to income of foreign institutional investors from securities?",
	items:[
		{
			text:"Section 196D of the Income Tax Act lays down that the person responsible for making a payment to a Foreign Institutional Investor, being income on securities referred to in Section 115AD(1)(a) other than referred to in Section 194LD),"
		},
		{
			type:"list",
			list:[
				"Should deduct income tax at the rate of 20% plus surcharge and health and education cess.",
				"The TDS should be done at the time of credit of the amount to the payee’s account or the time of actual payment, whichever is earlier."
			]
}
	]

},
{

	question:"Is tax deducted at source considered as income in the hands of the assessee?",
	items:[
		{
			text:"Yes. According to Section 198 of the Income Tax Act, any tax deducted at source  under Sections 192 to 196D, is considered to be part of the total income of the assessee."
		}
	]

},
{

	question:"What is the provision in the case of a person who is a non-filer of income tax returns but receives an amount which is subject to TDS under the Income Tax Act?",
	items:[
		{
			text:"Section 206AB of the Act lays down that when a ‘specified person’ who is a non-filer of income tax returns, receives an income / amount on whichTDS is deductible, tax is to be deducted by the deductee at the higher of the following:"
		},
		{
			type:"list",
			list:[
				"Twice the rate given in the relevant provision of the Act",
				"Twice the rate in force",
				"5%"
			]
		},
		{
			text:"The term ’specified person’ does not include persons  who are not required to file tax returns in India."
		}
	]
},
{

	question:"What is meant by ‘collection of tax at source’?",
	items:[
		{
			type:"list",
			list:[
				"When a seller sells certain ‘specified’ goods, and",
				"receives payment / debits the buyer’s account against the sales, whichever is earlier",
				"the seller should collect the tax at the prescribed rate from the buyer, and",
				"remit the collected tax to the Income Tax department within the specified time."
			]
		},
		{

			text:"This is called ‘tax collection at source’."
		}
	]
},
{

	question:"To which goods does tax collection at source (TCS) apply?",
	items:[
		{
			text:"As per Section 206C of the Income Tax Act, TCS (only basic rate of tax to be collected) applies to trading in ‘specified’ goods like:"
		},
		{
			type:"list",
			list:[
				"alcoholic liquor",
				"forest produce like: tendu leaves, timber",
				"Scrap",
				"Minerals, like, iron ore, coal"
			]
		},
		{
			text:"No surcharge and health and education cess to be collected from the buyer."
		},
		{
			text:"However, if these goods are purchased for the purpose of processing or producing / manufacturing articles and a declaration to this effect is submitted in Form 29C, no TCS is required."
		}
	]
},
{


	question:"What is the rule relating to TCS on lease of parking lots, toll plaza etc?",
	items:[
		{
			text:"As per Section 206C(1C), TCS at a tax rate of 2% has to be collected by a person who grants a licence / lease or transfers any right / interest to another person in:"
		},
		{
			type:"list",
			list:[
			"a parking lot",
			"a toll plaza",
			"mine or quarry ( except mineral oil)"
			]
		},
		{

			text:"The rate of TCS is twice the rate specified in the Act or 5%, whichever is higher, if the lessee does not have a PAN [Section 206CC(1)]."
		}
]
},
{


		question:"Is there any rule regarding TCS on purchase of a motor vehicle by a person?",
		items:[
			{
				text:"Section 206C(!F) lays down the rule in this connection,"
			},
			{
				type:"list",
				list:[
					"The seller of a motor vehicle to a ‘retail’ buyer, ",
					"at a value higher than Rs.10 lakhs, ",
					"should collect from the buyer ",
					"1% of the sale consideration as income tax."
				]
			}
		]

},
{

	question:"Is there any time limit for submission of tax collection statements and issue of tax collection certificates?",
	items:[
		{
			text:"Yes.Tax collection certificates have to be issued within 15 days of the filing of tax collection statements."
		},
		{
			text:"Quarter"
		},
		{
			type:"list",
			list:[
				"Submission of Tax collection statements (Form 27EQ)",
				"Issue of Tax collection certificates",
				"1st April-30th June(Q1)",
				"By 15th July",
				"On or before 30th July",
				"1st July-30th September(Q2)",
				"By 15th October",
				"On or before 30th Oct.",
				"1st Oct- 31st December(Q3)",
				"By 15th January",
				"On or before 30th Jan",
				"1st Jan- 31st March (Q4)",
				"By 15th May",
				"On or before 30th May"
			]
		}
	]
	
}
]



export {qandas}