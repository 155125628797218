import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';



// import DonutChart from '../components/viz/DonutChart'
// import PieChart from '../components/viz/PieChart'
import BarChart from '../components/viz/BarChart'

import Carousel from 'react-bootstrap/Carousel';



import Meta from '../components/Meta'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";



export const LandingPageScreen = ({ match, history }) => {


    const dispatch = useDispatch()




    useEffect(() => {
 


    }, [])




    return (
        <>

            <section style={{marginBottom:'20px'}}>
            <Card style={{border:'None', marginBottom:'20px'}}>
                <p className='h3' style={{textAlign:'center'}}>Learning Accounting and Finance for Business and Personal Applications</p>
            </Card>

            </section>


            
                <section style={{marginBottom:'20px'}}>
                <Card style={{border:'None', marginBottom:'20px'}}>
                    <Card.Header>
                    <p className='h4' style={{textAlign:'center'}}>Understand the Basic Concepts of Accounting with the e book. </p>
                    </Card.Header>
                    <Card.Body>

                        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Link to='/accounting'>
                                <Button style={{borderRadius:'20px', backgroundColor:'#940d31'}}>
                                    Accounting Language | Basics of Accounting
                                </Button>
                            </Link>
                        </div>


                    </Card.Body>

                </Card>



                </section>
                       
                <section style={{marginBottom:'20px'}}>
                <Card style={{border:'None', marginBottom:'20px'}}>
                    <Card.Header>
                    <p className='h4' style={{textAlign:'center'}}>Checkout the Tax FAQ book as a quick reference for your Tax Queries </p>
                    </Card.Header>
                    <Card.Body>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Link to='/itataglance'>
                        <Button style={{borderRadius:'20px', backgroundColor:'#940d31'}}>
                        Tax FAQS
                    </Button>
                        </Link>
                        </div>

                    </Card.Body>

                </Card>
                </section>
          


{/* 
            <section style={{marginBottom:'20px'}}>
            <Card style={{border:'None', marginBottom:'20px'}}>
                <Card.Header>
                    <p className='h4' style={{textAlign:'center'}}>Use FinTabb : Our Browser based Financial Spreadsheet App</p>
                </Card.Header>

                <Card.Body>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Link to='/fintabb'>
            <Button style={{borderRadius:'20px', backgroundColor:'#940d31'}}>
                Try out FinTabb
            </Button>
            </Link>
            </div>




            <Row>
                <Col>
                <Card style={{borderRadius:'20px', backgroundColor:'#b861fb', margin:'20px'}}>
                        <Card.Header style={{borderRadius:'20px', backgroundColor:'white', margin:'20px', padding:'20px'}}>

                    <p className='h4' style={{color:'black', textAlign:'center'}}>Expense Analysis</p>



                    <table style={{marginTop:'20px', marginBottom:'20px'}}>
                        <tbody>
                            <tr>
                                <td>
                                    Date
                                </td>
                                <td>
                                    Item
                                </td>
                                <td>
                                    Qty
                                </td>
                                <td>
                                    Price ($)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    11/05/202X
                                </td>
                                <td>
                                    Laptops
                                </td>
                                <td>
                                    3
                                </td>
                                <td>
                                    1000.0
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    14/05/202X
                                </td>
                                <td>
                                    USB Drives
                                </td>
                                <td>
                                    15
                                </td>
                                <td>
                                    2120
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    19/05/202X
                                </td>
                                <td>
                                    Smartphones
                                </td>
                                <td>
                                    20
                                </td>
                                <td>
                                    500
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    21/05/202X
                                </td>
                                <td>
                                    Tablets
                                </td>
                                <td>
                                    25
                                </td>
                                <td>
                                    1100
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    22/05/202X
                                </td>
                                <td>
                                    Chargers
                                </td>
                                <td>
                                    12
                                </td>
                                <td>
                                    700
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    27/05/202X
                                </td>
                                <td>
                                    Monitors
                                </td>
                                <td>
                                    23
                                </td>
                                <td>
                                    1750
                                </td>
                            </tr>

                        </tbody>
                    </table>


                        </Card.Header>
                    </Card>
                
                </Col>
                <Col style={{maxWidth:'40vW'}}>
                
                <Card className="d-flex" style={{borderRadius:'20px', backgroundColor:'#b861fb', margin:'20px'}}>
                        <Card.Header style={{borderRadius:'20px', backgroundColor:'white', margin:'20px'}}>

                            <p className='h4' style={{color:'black', textAlign:'center', marginTop:'10px'}}>Breakdown of May Expenses</p>
                        
                            <div className="align-self-center"  style={{marginBottom:'15px'}}>
                            
                            <BarChart pdata={[30,25,19,45,10]} labels={['Laptops', 'USBs', 'Phones', 'Tablets', 'Chargers']}/>
                            </div>


                        </Card.Header>
                        </Card>
                </Col>
            </Row>




                </Card.Body>

            </Card>








            </section> */}


            <section style={{marginBottom:'20px'}}>



            <Card style={{border:'None', marginBottom:'20px'}}>
                <p className='h3' style={{textAlign:'center'}}>FAQs </p>
            </Card>


            <ListGroup style={{border:'None'}}>
                <ListGroup.Item style={{border:'None'}}>
                    <Card style={{border:'None'}}>
                        <Card.Header>
                            <p className='h5'>Why Accounting Language?</p>
                        </Card.Header>
                        <Card.Body>
                            Accounting is the key to good financial management for businesses and individual. Our goal is to provide people with the knowledge and the tools to be able to make educated decisions.
                        </Card.Body>
                    </Card>
                </ListGroup.Item>
                <ListGroup.Item style={{border:'None'}}>
                    <Card style={{border:'None'}}>
                        <Card.Header>
                            <p className='h5'>Are you an online learning platform?</p>
                        </Card.Header>
                        <Card.Body>
                            We see this platform more as a mini online library. Our ebooks on accounting and tax faqs are designed to help provide a basic reference to important concepts. And the accounting tables make it possible to practically implement the theory being taught. 
                        </Card.Body>
                    </Card>
                </ListGroup.Item>
            </ListGroup>



            </section>




 


        </>
    )
}


export default LandingPageScreen
