import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const DoubleEntryBookkeeping = ({  }) => {



    var items = [

        {
            type:'paragraph',
            text:'The three mantras to keep in mind that massively simplify the accounting procedure are : '
        },
        {
            type:'list',
            list:[
                'Debit the receiver and credit the giver,',
                'Debit what comes in and credit what goes out (money, assets, goods etc),',
                'Debit all expenses and credit all income.',
            ]
        },
        {
            type:'paragraph',
            text:'It is important to remember, for every financial transaction, there must be a debit entry and a corresponding credit entry. This means that the sum total of all the debits should tally with the sum total of the credits in the Books of Account of a concern at any point of time.'
        },
        {
            type:'paragraph',
            text:'This is called the double- entry method of book keeping and is the normal method of maintaining accounts by business enterprises. It  is also known as the  conventional method or historical method.'
        },
        {
            type:'paragraph',
            text:'The general practice in the case of all types of organizations is to convert all the commercial transactions into values which are expressed in the currencies of the respective countries. '
        },
        {
            type:'paragraph',
            text:'For Example : If John pays Rs 200 to Sam on 21/10/2021 for milk, the accounting entry will be:'
        },
        {
            type:'table',
            title:"John’s ( Buyer) Journal Entry",
            headers:['Date', 'Account', 'Debit (Rs)', 'Credit (Rs)'],
            table: [
                ['21/10/2021', 'Milk', '200', '0.0'],
                ['21/10/2021', 'Cash', '0.0', '200']
            ]
        },
        {
            type:'table',
            title:"Sam’s ( Seller) Journal entry",
            headers:['Date', 'Account', 'Debit (Rs)', 'Credit (Rs)'],
            table: [
                ['21/10/2021', 'Milk', '0.0', '200'],
                ['21/10/2021', 'Cash', '200', '0.0']
            ]
        },
        {
            type:'paragraph',
            text:'Business Enterprises have a separate legal entity'
        },
        {
            type:'paragraph',
            text:"The basic accounting assumption in the case of a business entity is that all the financial transactions are with a third party, i.e. with a separate entity or a separate person."
        },
        {
            type:'paragraph',
            text:"That is why the share capital is always a Credit in the case of Books of Account of a Proprietary Concern, Partnership Firm, Company etc. though the owners, promoters contribute the funds. This is because the business entity is supposed to return the capital contributed by the owners. The only difference is that if the business entity makes profit, the capital and the profit will be of the owners. However, if the business entity makes losses, the owners will have to suffer the losses and they will be paid back their capital after deducting the losses. "
        },
        {
            type:'header',
            text:"Normal accounting entries in the Books of Account of commercial organizations"
        },
        {
            type:'list',
            list:[
                "(i) When the promoters ( individuals in the case of a Proprietary concern, Hindu Undivided Family; Partners in the case of Partnership Firm; Shareholders in the case of a Company; Association of Persons or Body of individuals or any other Artificial Judicial person) bring in capital either as cash or in kind (Fixed Assets), then debit cash / bank / fixed asset and credit the capital account in the books of the respective business enterprises.",
                "(ii) Financial Statements such as Trial Balance, Profit and Loss account, Balance Sheet and Cash Flow Statement are prepared by the enterprise at the end of a financial year.  The Financial Statements can also be prepared periodically at the discretion of the management."
            ]
        },
        {
            type:'paragraph',
            text:"Detailed information is given under the Head “ How to prepare the Financial Statements”"
        },
        {
            type:'header',
            text:"Need for maintaining proper Books of Account"
        },
        {
            type:'paragraph',
            text:"The large volume of financial transactions have made it necessary  to maintain proper books of account to ascertain whether the money has been spent at the right time for the right purpose.  Employees and professionals receive money against services and others against supply of goods. Employees spend money for meeting their official and personal expenses while others  spend money to purchase goods and services and meet other needs."
        },
        {
            type:'paragraph',
            text:"Money is very precious and very difficult to make, but very easy to spend. Therefore, while spending, one needs to be extra careful. It is important to keep the expenses within the means and, if possible, save at least something for the future. This will reduce pressure and mental tension. "
        },
        {
            type:'header',
            text:"Notes forming part of the Statement of Accounts"
        },
        {
            type:'paragraph',
            text:"When there are contingent liabilities, financial implications due to events / changes in accounting policies which occur after the Balance Sheet date but before signing the Accounts, they have to be mentioned in the Notes which form part of the Statement of Accounts."
        },
        {
            type:'paragraph',
            text:"In the case of a company, the Balance Sheet and Profit and Loss Account should be prepared in Schedule VI of the Companies Act, 2013."
        },
        {
            type:'paragraph',
            text:"The company should follow Indian Accounting and Auditing Standards or International Auditing and Accounting Standards.  Statutory audit is compulsory."
        },
        {
            type:'paragraph',
            text:"The company should hold its Annual General Meeting(AGM) within six months from the end of the financial year and approve the audited accounts in the AGM. In the case of a new  company, it is allowed to hold the First AGM within 18 months from the end of the financial year."
        },
        {
            type:'paragraph',
            text:"The appointment of Statutory Auditor for the next financial year is recommended by the Board of Directors in their Board Meeting and the shareholders should approve the same in the AGM of the company."
        },
        {
            type:'paragraph',
            text:"The Directors have to  present the report of the company in the AGM which will form part of the annual accounts of the company."
        },
        {
            type:'paragraph',
            text:"The company should strictly comply with all the statutory requirements, as  per the Companies Act, Income Tax Act, GST Act, etc. during the financial year under audit. In case of failure to do so,  the Statutory Auditor should include the same in their  audit report."
        },
        {
            type:'paragraph',
            text:"In the case of electricity companies, banks, and government companies, the method of accounting is different"
        },
        {
            type:'paragraph',
            text:"Similarly, in the case of agency and dealership business and professional services, only Trial Balance, Profit and Loss Account and Balance Sheet is prepared."
        },
        {
            type:'header',
            text:"Digitization of Accounts"
        },
        {
            type:'paragraph',
            text:"The Digitalization of accounts has made it very easy to prepare the statements of accounts once the Head of Account is classified as expense or income or asset or liability. Each Head of account is given a specific number and the accounting software automatically prepares the Trial Balance, Profit and Loss account, Balance Sheet, Cash flow Statement, Trial balances of General ledger, Debtors and Creditors Ledgers, Schedule of Fixed Assets, list of inventory of raw materials, stores and spares, fuel and fixed assets register. The most important point in the case of digitization is that the input entry should be correct and properly classified. "
        },
        {
            type:'paragraph',
            text:"In the case of big companies, Internal Auditors  audit the books of accounts regularly and discrepancies, if any, are brought to the notice of the Management for appropriate action"
        }

    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Double Entry Method of Bookkeeping</p>
            </Card.Header>  
        </Card>


        <Section items={items}/>


        </>


    )
}


export default DoubleEntryBookkeeping
