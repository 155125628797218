import {

    ADD_EXPENSE_ITEM,
    EXPENSE_TABLE_FAIL,

    ADD_INCOME_ITEM,
    INCOME_TABLE_FAIL,

    ADD_INVENTORY_ITEM,
    INVENTORY_TABLE_FAIL,

    ADD_EXPENSE_BIZ_ITEM,
    EXPENSE_BIZ_TABLE_FAIL,

    ADD_TABLES_LIST,
    TABLES_LIST_FAIL,

    ADD_TABLE_ITEM,
    DELETE_TABLE_ITEM,
    UPDATE_TABLE_ITEM,
    ADD_TABLE_ROW,
    ADD_TABLE_COL,
    TABLE_FAIL,

    ADD_TABLE_PLOT,
    TABLE_PLOT_FAIL,

    INITIALIZE_TABLE,
    INITIALIZE_PLOTS



} from '../constants/tableConstants.js';





export const tableListReducer = (state = { actables:[]}, action) => {

    switch(action.type){

        case ADD_TABLES_LIST:
            return { loading: false, expenses: [action.payload, ...state.expenses] }
        case TABLES_LIST_FAIL:
            return { loading: false, error: action.payload, expenses:state.expenses }
        default:
            return state
    }

}



export const tableDetailReducer = (state = { title:'Table', tbdata:{}, columns:[{title:'S No'},{title:'C0'}, {title:'C1'}], nrows:100, ncols:30}, action) => {

    switch(action.type){

        case INITIALIZE_TABLE:
            return { loading: false, tbdata: action.payload.tbdata, columns:action.payload.columns, nrows:action.payload.nrows, ncols:action.payload.ncols }

        case ADD_TABLE_ITEM:
            return { loading: false, tbdata: action.payload.tbdata }


        case UPDATE_TABLE_ITEM:
            return { loading: false, tbdata:action.payload.values, nrows: state.nrows, ncols: state.ncols, columns:state.columns }


        case ADD_TABLE_ROW:
            return { loading: false, tbdata: state.tbdata, columns:state.columns, nrows:action.payload.nrows, ncols:state.ncols }

        case ADD_TABLE_COL:
            return { loading: false,  tbdata: state.tbdata, columns:[...state.columns,action.payload.columns], nrows:state.nrows, ncols:action.payload.ncols }


        case TABLE_FAIL:
            return { loading: false, error: action.payload, tbdata: state.tbdata, columns:state.columns, nrows:state.nrows, ncols:state.ncols }
        
        default:
            return state
    }

}




export const plotListReducer = (state = { cplots:[]}, action) => {

    switch(action.type){

        case INITIALIZE_PLOTS:
            return { loading: false, cplots: action.payload }
        case ADD_TABLE_PLOT:
            return { loading: false, cplots: [action.payload, ...state.cplots] }
        case TABLE_PLOT_FAIL:
            return { loading: false, error: action.payload, cplots:state.cplots }
        default:
            return state
    }

}



//------------------------------------------------------------------------------


export const expenseTableReducer = (state = { expenses:[]}, action) => {

    switch(action.type){

        case ADD_EXPENSE_ITEM:
            return { loading: false, expenses: [action.payload, ...state.expenses] }
        case EXPENSE_TABLE_FAIL:
            return { loading: false, error: action.payload, expenses:state.expenses }
        default:
            return state
    }

}



export const incomeTableReducer = (state = { incomes:[]}, action) => {

    switch(action.type){

        case ADD_INCOME_ITEM:
            return { loading: false, incomes: [action.payload, ...state.incomes] }
        case INCOME_TABLE_FAIL:
            return { loading: false, error: action.payload, incomes:state.incomes }
        default:
            return state
    }

}



export const inventoryTableReducer = (state = { inventory:[]}, action) => {

    switch(action.type){

        case ADD_INVENTORY_ITEM:
            return { loading: false, inventory: [action.payload, ...state.inventory] }
        case INVENTORY_TABLE_FAIL:
            return { loading: false, error: action.payload, inventory:state.inventory }
        default:
            return state
    }

}



export const expenseBizTableReducer = (state = { expenses:[]}, action) => {

    switch(action.type){

        case ADD_EXPENSE_BIZ_ITEM:
            return { loading: false, expenses: [action.payload, ...state.expenses] }
        case EXPENSE_BIZ_TABLE_FAIL:
            return { loading: false, error: action.payload, expenses:state.expenses }
        default:
            return state
    }

}
