import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const BalanceSheet = ({  }) => {


    var items = [
        {
            type:"paragraph",
            text:"The Balance Sheet is a great way to compare the assets and liabilities of a company and estimate the actual value created by the company also known as the shareholder's equity. "
        },
        {
            type:"paragraph",
            text:"The Balance Sheet is always on a particular date."
        },
        {
            type:"list",
            list:[
                "Assets - The assets side shows the gross value of fixed assets less depreciation, investments, if any, made by the organization, membership fee receivable, stock of any goods, prepared expenses and closing balances of cash and bank balance.",
                "Liabilities -The liability side of the Balance Sheet should start with the Capital Fund. The excess of income over expenditure, Life Membership fee, the entrance fee etc. should be added with the Capital Fund. The next, in order, is any special fund collected such as Building Fund etc. The liability side should include subscription fees received in advance, creditors for supply of goods and liability for expenses"
            ]
        },
        {
            type:"paragraph",
            text:"Let us look at an Example of a Balance Sheet. We are showing you the Balance Sheet of HDFC Bank for the 2021-22"
        },
        {
            type:'table',
            url:"https://www.hdfcbank.com/content/bbp/repositories/723fb80a-2dde-42a3-9793-7ae1be57c87f/?path=/Footer/About%20Us/Investor%20Relation/annual%20reports/pdf/HDFC-Bank-IAR-FY22-21-6-22.pdf",
            title:"Consolidated Balance Sheet as of March 31, 2022, in crore Rs",
            headers:['Capital', 'Schedule', 'As at March 31,2022', 'As at March 31, 2021'],
            table: [
                ['Capital and Liabilities', '', '', ''],
                ['Capital', '1', '554.55', '551.28'],
                ['Reserves and surplus', '2', '246,771.62', '209,258.90'],
                ['Minority Interest', '2A', '720.41', '632.76'],
                ['Deposits', '3', '1,558,003.03', '1,333,720.87'],
                ['Borrowings', '4', '226,966.50', '177,696.75'],
                ['Other liabilities and provisions', '5', '89,918.19', '77,646.07'],
                ['Total', '', '2,122,934.30', '1,799,506.63'],
                ['', '', '', ''],
                ['Assets', '', '', ''],
                ['Cash and balances with Reserve Bank of India', '6', '130,030.71', '97,370.35'],
                ["Balances with banks and money at call and short notice", "7", "25,355.02", "23,902.16"],
                ["Investments", "8", "449,263.86", "438,823.11"],
                ["Advances", "9", "1,420,942.28", "1,185,283.52"],
                ["Fixed assets", "10", "6,283.28", "5,099.56"],
                ["Other assets", "11", "90,910.36", "48,879.14"],
                ["Goodwill on Consolidation","" ,"148.79", "148.79"],
                ["Total", "2","122,934.30", "1,799,506.63"],
                ["Contingent liabilities", "12", "1,400,197.64", "975,280.66"],
                ["Bills for collection", "", "56,968.05", "44,748.14"]
            ]
        },
        {
            type:'header',
            text:"How to write the Balance Sheet"
        },
        {
            type:"list",
            list:[
                "The Balance sheet can be prepared in the horizontal or vertical format in the case of a company.",
                "In the horizontal format, all the liabilities will be shown on the left hand side and all the assets on the right hand side.",
                "In the case of vertical format, all the sources of funds will be shown on the top under the head” Sources of Funds” and all the funds used  will be shown down below under the head “Application of funds” .",
                "The total sources of funds and the total application of funds should be equal"
            ]
        },
        {
            type:'paragraph',
            text:"In India, the company prepares the Balance sheet as per Schedule VI of the Companies Act. For other countries, you will need to follow the systems laid down by the rules valid in that country."
        },
        {
            type:"header",
            text:"Liabilities side of balance sheet"
        },
        {
            type:"paragraph",
            text:"Liabilities on the balance sheet are generally classified as :"
        },
        {
            type:"list",
            list:[
                "Long Term Liabilities", 
                "Current or Short Term Liabilities"
            ]
        },
        {
            type:"paragraph",
            text:"In the horizontal format of Balance Sheet, the liabilities of the following types are shown:"
        },
        {
            type:'paragraph',
            title:"Shareholders' Fund (Long Term Liability)",
            text:"Shareholders Fund includes the Share capital, Reserves and Surplus which include, share premium account, general reserve and profit and loss account balance (profit)."
        },
        {
            type:'paragraph',
            title:"Capital Reserve  (Long Term Liability)",
            text:"Capital Reserve is an item in the liability side of the balance sheet and can be used for future expenses and to offset capital losses. It is the accumulated capital surplus of a company and created out of its profit .It is also the surplus on the revaluation of assets."
        },
        {
            type:'paragraph',
            title:"Long Term Debt Instruments  (Long Term Liability)",
            text:"There are different debt instruments that a company can avail to raise capital : "   
        },
        {
            type:'list',
            list:[
                "Debentures : Debentures are debt instruments issued by a company to the public at a fixed rate of interest and repayable after a fixed period.",
                "Secured Loans are from financial Institutions and banks secured by charges created on the assets of the enterprise, repayable after a period of more than one year.",
                "Unsecured Loan means loan without any security or guarantee and repayable after a period of more than a year."
            ]  
        },
        {
            type:'paragraph',
            title:"Current liabilities and provisions",
            text:""   
        },
        {
            type:'list',
            list:[
                "Current liabilities include, creditors, liability for expenses, advance received from debtors, deposit received from suppliers, etc. ",
                "Provisions include, provision for Income Tax, dividend payable etc"
            ]  
        },
        {
            type:"header",
            text:"Assets side of Balance sheet"
        },
        {
            type:'paragraph',
            title:"Fixed Assets",
            text:"A schedule of fixed assets is prepared showing the value of fixed assets at the beginning of the year, value of additions during the year, cost of fixed assets sold during the year,  gross value of fixed assets, depreciation for the year, depreciation written back on the sale of fixed assets and the total depreciation written off so far and Net Fixed assets value."   
        },
        {
            type:'paragraph',
            text:"In the Balance Sheet, the gross fixed assets value less the total depreciation written off and the cost of assets sold or written off and the net fixed assets value will be shown."
        },
        {
            type:'paragraph',
            text:"The fixed assets are Land, buildings, Plant and Machinery, Office Equipments, Vehicles, Furniture and Fittings, etc (Tangible Assets)."
        },
        {
            type:"paragraph",
            text:"Intangible assets include Goodwill, Patents, Copyrights, Trademarks, etc."
        },
        {
            type:'paragraph',
            title:"Fixed Asset Register",
            text:"In India, as per the Companies Act, a company should maintain a fixed assets register with complete details of the cost of each fixed asset including the location of the asset, the assigned number of the asset, the cost of the asset, the addition of each asset in each year, the sale of each asset in each year, the depreciation written off each year, and the net value of each asset. The total gross value of fixed assets, the depreciation written off so far and the depreciation for the year and net written down value of the total assets should tally with the net value shown in the Balance Sheet."   
        },
        {
            type:'paragraph',
            text:"It is a must that the fixed assets should be physically verified at least once in a year, at the close of the year and the difference, if any, should be adjusted by writing off in the Profit and Loss account."
        },
        {
            type:'paragraph',
            title:"Depreciation of Fixed Assets",
            text:"In the case of financial accounting, straight line method of depreciation is calculated though the Income Tax Act prescribes written down value method of computing depreciation. However, in the case of electricity companies, the Income Tax Act allows straight line method of computing depreciation. The rates of depreciation in the Income Tax Rules and the financial accounting are different."
        },
        {
            type:"paragraph",
            title:"Investment ",
            text:"If the enterprise has invested in shares and securities etc. they have to be valued at the year end at the market price and the profit or loss on them i.e. the difference between the cost and market price at year end should be adjusted in the Profit and Loss account."
        },
        {
            type:"paragraph",
            title:"Current Assets, Loans and Advances",
            text:"Current assets include, Cash on hand, Bank Balances, Receivables, stock of finished goods, work in progress, raw materials, stores and spares, fuel etc."
        },
        {
            type:'paragraph',
            text:"The finished goods should be valued at cost or market price, whichever is less .The finished goods, work in process, raw materials, stores and spares and fuel should be physically verified at the end of the year and should tally with the finished goods register, raw materials register, stores and spares register, fuel register etc. The company should follow a consistent method of valuation of inventory."
        },
        {
            type:"paragraph",
            title:"Loans and advances",
            text:"Loans include loans to employees. Advances include advances paid to suppliers, deposits paid to electricity companies, telephone companies, local internet providers etc."
        },
        {
            type:'paragraph',
            title:"Miscellaneous Expenditure",
            text:"This includes balance of Preliminary Expense not written off, deferred revenue expenditure balance and debit balance of Profit and Loss account."
        },
        {
            type:"paragraph",
            text:"It is a must that all the figures in the financial statements including Trial Balance, Profit and Loss Account and Balance Sheet should mention the currency in which  the statements are prepared."
        }


    ]

    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Balance Sheet</p>
            </Card.Header>  
        </Card>


        <Section items={items}/>


        </>


    )
}


export default BalanceSheet
