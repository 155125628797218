import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const Ratios = ({  }) => {


    var items = [
        {
            type:"paragraph",
            text:"Accounting Ratios are used to analyze the information contained in the financial statements in order to assess the solvency and profitability of a business undertaking. These ratios are calculated by comparing two accounting numbers derived from the financial statements."
        },
        {
            type:"paragraph",
            text:"The Accounting Ratios indicate :"
        },
        {
            type:"list",
            list:[
                "how the elements of cost have impacted the liquidity and profitability of the enterprise.", 
                "how the changes in the assets and liabilities of the enterprise have affected the shareholders funds."
            ]
        },
        {
            type:"paragraph",
            text:"These parameters help the management to identify bottlenecks and take right decisions on time."
        },
        {
            type:"paragraph",
            text:"The standard financial ratios used for business analysis are:"
        },
        {
            type:"paragraph",
            text:"Statement of profit and loss ratios. The enterprise also calculates solvency ratios. This is to ensure that the funds provided to the enterprise are safe and secure with the enterprise."
        },
        {
            type:"table",
            headers:["Ratio","How it is calculated"],
            table:[
                ["Turnover ratio", "Cost of revenue from operations/Average Inventory"],
                ["Trade Receivables turnover ratio. ", "Net Credit Revenue from operations /Average Trade Receivables"],
                ["Trade payable Turnover Ratio", "Net Credit Purchases/Average trade payable."],
                ["Interest coverage ratio", "Net Profit before interest and tax /interest on long term debts."],
                ["Liquidity ratio is ", "Net Profit before interest and tax /interest on long term debts."],
                ["Liquidity ratio is ", "equal to cash, bank and receivables divided by current liabilities. This is also referred to as acid test ratio."],
                ["Current Ratio", "equal to current assets divided by current liabilities."],
                ["Debt –Equity Ratio ", "Long Term debts Divided by Shareholders funds."],
                ["Debt to capital employed ratio", "Long Term debts Divided by Shareholders funds."],
                ["Proprietary ratio", "Shareholders Funds /Capital employed."],
                ["Total Assets to Debt ratio","Total Assets /Long Term debts"],
                ["Net Assets or Capital Employed Turnover Ratio", "Revenue from Operation /Capital Employed."],
                ["Operating ratio", "Cost of revenue from operations +operating expenses/net revenue from operations"],
                ["Operating profit ratio", "operating profit/revenue from operations"],
                ["Return on Investment (capital Employed) ", "Profit before interest and taxes/capital employed"],
                ["Return on Shareholders fund", "Profit after tax/shareholders funds."],
                ["Earnings per share (EPS) ", "Profit available for equity shareholders/Number of Equity shares"],
                ["Dividend Payout Ratio", "Dividend per share/Earnings per share"],
                ["Price/Earnings Ratio (P/E Ratio) ", "Market Price of a share/Earnings per share"]

            ]
        }
    ]

    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Ratio Analysis</p>
            </Card.Header>  
        </Card>


        <Section items={items}/>

        </>


    )
}


export default Ratios
