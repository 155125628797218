import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const AboutMe = ({  }) => {

    var items = [
        {
            type:'paragraph',
            text:"Accounting Language is the work of N Sethumadhavan, a Chartered Accountant with over 50 years of experience. He has worked in India and abroad at various top level financial and executive positions. He also has entrepreneurial experience. He currently serves as Director of Pixagan Technologies advising the company on financial matters."
        },
        // {
        //     type:'paragraph',
        //     text:"The FinTabbs tool has been developed in collaboration with Dr Anil Variyar (PhD)."
        // }
    ]


    return (

        <>

        <Card style={{borderRadius:'20px', marginLeft:'20px', marginRight:'20px'}}>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>About Us</p>
            </Card.Header> 

            <Section items={items}/> 
        </Card>




        </>


    )
}


export default AboutMe
