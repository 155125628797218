export const ADD_EXPENSE_ITEM = 'ADD_EXPENSE_ITEM';
export const EXPENSE_TABLE_FAIL = 'EXPENSE_TABLE_FAIL'


export const ADD_INCOME_ITEM = 'ADD_INCOME_ITEM';
export const INCOME_TABLE_FAIL = 'INCOME_TABLE_FAIL'

export const ADD_INVENTORY_ITEM = 'ADD_INVENTORY_ITEM';
export const INVENTORY_TABLE_FAIL = 'INVENTORY_TABLE_FAIL'

export const ADD_EXPENSE_BIZ_ITEM = 'ADD_EXPENSE_BIZ_ITEM';
export const EXPENSE_BIZ_TABLE_FAIL = 'EXPENSE_BIZ_TABLE_FAIL'



export const UPDATE_ROW_HEADER = 'UPDATE_ROW_HEADER';
export const UPDATE_COL_HEADER = 'UPDATE_COL_HEADER';


export const UPDATE_TITLE = 'UPDATE_TITLE'

export const ADD_TABLES_LIST = 'ADD_TABLES_LIST';
export const TABLES_LIST_FAIL = 'TABLES_LIST_FAIL'

export const ADD_TABLE_ITEM = 'ADD_TABLE_ITEM';

export const ADD_TABLE_ROW = 'ADD_TABLE_ROW';
export const ADD_TABLE_COL = 'ADD_TABLE_COL';
export const DELETE_TABLE_ROW = 'DELETE_TABLE_ROW';
export const DELETE_TABLE_COL = 'DELETE_TABLE_COL';
export const INSERT_TABLE_ROW = 'INSERT_TABLE_ROW';
export const INSERT_TABLE_COL = 'INSERT_TABLE_COL';

export const DELETE_TABLE_ITEM = 'DELETE_TABLE_ITEM';
export const UPDATE_TABLE_ITEM = 'UPDATE_TABLE_ITEM';
export const TABLE_FAIL = 'TABLE_FAIL'

export const ADD_TABLE_PLOT = 'ADD_TABLE_PLOT';
export const UPDATE_TABLE_PLOT = 'UPDATE_TABLE_PLOT';
export const DELETE_TABLE_PLOT = 'DELETE_TABLE_PLOT';
export const TABLE_PLOT_FAIL = 'TABLE_PLOT_FAIL';

export const INITIALIZE_TABLE = 'INITIALIZE_TABLE';
export const INITIALIZE_PLOTS = 'INITIALIZE_PLOTS';
