//Deductions allowed with respect to certain Incomes
  
var qandas = 
[
    {
        question:"What is the rule relating to deduction in respect of profits and gains from industrial undertakings or enterprises engaged in infrastructure development ?",
        items:[
            {
                type:"list",
                list:[
                    "Deduction is available under Section 80-IA to an assessee whose gross total income includes any profits and gains derived from the business of developing, operating and maintaining any infrastructure facility.",


                    " The infrastructure facility includes, road including bridge, a rail system, highway project including housing or other activities being an integral part of the highway project, a water supply project, water treatment system, irrigation project, sanitation and sewerage system or solid waste management system, a sea port, an airport, inland waterways, or inland port or navigational channel in the sea.",


                    "This deduction is applicable only to new enterprises and industrial undertakings. The enterprise or undertaking must get its accounts audited by a chartered accountant and submits a report in Form 10CCB on or before 1 October of the Assessment Year with effect from Assessment Year 2020-2021.",


                    "Where there is a transfer of goods by any unit or undertaking of the assessee to a new enterprise or undertaking (inter-unit transfer) other than at market price i.e. at arm’s length price, the difference between the market price and the transfer price will be adjusted in the  computation  of taxable income..",


                    "Double deduction is not allowed. Any deduction of profits and gains allowed under this Section will not be allowed under any other Sections of the Act. The deduction is allowed only once.",


                    "Excessive profits are restricted. If the assessing officer is of the opinion that a closely related or connected enterprise or undertaking has transferred goods at a price more than the market price to increase the profit  of the assessee, the assessing officer may adjust the excessive profits and compute the profits accordingly.",


                    "The deduction under Section 80-1A is also applicable to undertakings or enterprises engaged in the generation and distribution of power. [Section 80-1A (4) (IV)] "
            
                ]
    }        
]

    },
{
    question:"What is the period of deduction on income?",
    items:[
        {
            text:"The assessee will get 100 % of profits and gains made by these enterprises or undertakings as deduction for 10 consecutive Assessment Years out of 15 Assessment Years from the commencement of business."
        },
        {
            text:"In the case of infrastructural facilities other than sea  port, airport, inland waterways or inland port or navigational channel, the period is extended to 20 years."
        }
    ]

},
{

    question:"Is there any special provision for startups in respect of startups?",
    items:[
        {
            text:"Special provision for startups has been laid down in Section 80-1AC."
        },
        {
            type:"list",
            list:[
                 "Where the gross total income of an assessee, being an eligible start-up, includes any profits and gains derived from eligible business, a deduction of an amount equal to one hundred percent of the profits and gains derived from such business, is allowed,  for three consecutive Assessment Years out of ten Previous Years( subject to the provisions of the Section).",


                 "The assessee can claim this deduction for any three consecutive Assessment Years out of ten years beginning from the year in which the eligible start up was started.",


                 "The threshold limit of startups eligible for deduction under this Section is Rs25 crores.",


                 "The deduction is applicable only to a new start-up and not to an undertaking formed by splitting up or reconstruction of an existing business.",


                 "The deduction will be available only if the assessee furnishes the return of income one month before the due date for filing the return of income under Section 139(1)."
            ]
        }
    ]
},

{
        question:"Is any deduction allowed in respect of housing projects ?",
        items:[
            {
            text:"According to Section 80-IBA, where the gross total income of an assessee includes any profits and gains derived from the business of developing and building housing projects there shall, subject to the provisions of this Section, be allowed a deduction of an amount equal to 100% of the profits and gains derived from such business provided the assessee  fulfills the conditions mentioned in section 80-IBA (2)."
            }
        ]
},
{

    question:"Is there any provision for deduction in respect of employment of new employees ?",
    items:[
        {
            text:"Section 80 JJAA lays down the rule in this connection."
        },
        {
            type:"list",
            list:[
                "The assessee is given a deduction of 30% of additional employee cost for three consecutive Assessment Years [Section 80JJAA (1).The additional employee cost is the emoluments paid or payable to employees employed in  the Previous Year.",


            "This Section is applicable only to a new business undertaking or enterprise.",


            "The emoluments shall be paid by account payee cheque or draft or through any electronic payment / clearing system.",


            "The deduction will not be allowed unless the assessee furnishes along with the return of income a report from a Chartered Accountant at least one month before the due date for filing the return of income as referred to in Section 139 (1)."
    ]
    }
]
},
{

    question:"What is the deduction allowed in respect of Co-operative Societies ?",
    items:[
        {
            text:"As per Section 80 P (2)(a), 100% of profits made is allowed as deduction in the case of co-operative societies who are engaged in the following activities: "
        },
        {
            items:'list',
            list:[
                "Banking business or providing credit facilities to its members",
                "A cottage industry",
                "The marketing of agricultural produce grown by its members",
                "The purchase of agricultural implements, seeds, livestock or other articles intended for agriculture for the purpose of supplying them to its members",
                "The processing, without the aid of power, of the agricultural produce of its members.",
                "Fishing and allied activities or the purchase of materials and equipment in that connection for the purpose of supplying them to its members."
            ]
    }
    ]
},

{

    question:"What is the rule regarding deduction in the case of royalty income from books?",
    items:[
        {
            text:"Section 80 QQB lays down the rules regarding deduction on royalty income in the case of books other than textbooks."
        },
        {
            text:"Deduction is allowable to the author of a book, other than a textbook. under the following conditions:"
        },
        {
            type:"list",
            list:[
            "The author should be a resident in India,",
            "The book should be a work of literary, artistic or scientific nature,",
            "The income must be derived by him in exercise of his profession,",
            "The income must be either;",
            "(a) on account of any lump sum consideration for the assignment or grant of any of his interests in the copyright of such book, or",
            "(b) of royalty or copyright fees."
            ]
        },
        {
            text:"The deduction allowed in any previous year is 100% of such income or Rs.3,00,000 whichever is less."
        },
        {
            type:"list",
            list:[
                "However, the deduction will be allowed only if the assessee furnishes a certificate in the prescribed form (Form no 10CCD) and in the prescribed manner duly verified by any person responsible for making such payment to the assessee, along with the return of income, setting forth such other particulars as may be prescribed.",
                "No deduction under this Section shall be allowed in respect of any income earned outside India, unless the assessee furnishes a certificate in the prescribed form, Form No 10 (H) from the prescribed authority, along with the return of income in the prescribed manner."
            ]
        }
    ]
},
{

    question:"Is tax deduction allowed in respect of royalty on patents?",
    items:[
        {
            text:"According to Section 80RRB,"
        },
        {
            type:"list",
            list:[
                "Deduction is allowed to a patentee who is a resident in India and the patent is registered under the Patents Act 1970 and his gross total income includes royalty in respect of such patent.",


                "The deduction allowed is 100 % of such income or Rs.3,00,000 whichever is less.",


                "No deduction under this Section is allowed unless the assessee furnishes a certificate in the prescribed form(Form No 10 CCD)  duly signed by the prescribed authority along with the return of income setting forth such other particulars as may be prescribed .",


                " No deduction under this Section shall be allowed in respect of any income earned from  any source outside India, unless the assessee furnishes a certificate in the prescribed form{Form No 10 H}  from the prescribed authority, along with the return of income in the prescribed manner.",


                "If any deduction is allowed under this Section, no other deduction is allowed for the same  income under any other provisions of the Act."
            ]
        }
    ]

},
{


    question:"What is the tax deduction allowed in respect of interest on Saving Bank accounts ?",
    items:[
        {
            text:"As per Section 80 TTA"
        },
        {
            text:"Where the gross total income of  an individual or HUF (other than a senior citizen), includes any income by way of interest on savings bank accounts (not time deposits) with :"
        },
        {
            type:"list",
            list:[
                "A banking company to which Banking Regulation Act 1949, applies,",
                "A Cooperative society engaged in the carrying on of  the business of banking (including a co –operative Land Mortgage Bank or a Co-operative Land Development Bank), or",
                "A Post Office as defined in cause (k) of section 2 of the Indian Post Office Act, 1898,"
            ]
        },
        {
            text:"A deduction of such interest shall be allowed up to a maximum extent of Rs 10,000.",
        }
    ]

},
{
    question:"What is the tax deduction allowed in respect of interest on deposits in the case of Senior Citizens?",
    items:[
        {
            text:"According to Section 80 DTTB :"
        },
        {   
            text:"If the gross total income of a Senior Citizen includes interest on  deposits with;"
        },
        {
            type:"list",
            list:[
                 "A banking company to which the Banking Regulation Act, 1949 applies (including any bank or banking institution referred to in Section 51 of the Banking Regulation  Act.)",
                 "Co–Operative society engaged in carrying on the business of banking (including a Co-operative Land Mortgage Bank or a Co-operative Land Development Bank); or",
                 "A Post Office under Section 2(k) of the Indian Post Office Act,1898. "
            ]
        },
        {
            text:"A deduction of  the actual interest received or Rs 50,000, whichever is less, is allowed."
        }
    ]

}


]


export {qandas}


//                                      —----------------------