import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';

import QuestionItem from './QuestionItem'

const IncomeTaxIndia = ({ items }) => {


    var qandas = [
        {
            question:'How are Income Tax rules framed in India?',
            items:[
                {
                    type:'paragraph',
                    text:"Income Tax rules in India are based on the Income Tax Act, 1961 which is a Central Act, enacted in 1961, given effect from 1st April 1962. This Act is applicable throughout India.",
                },
                {
                    type:'paragraph',
                    text:"For effective implementation of the Act, guidelines have been framed in the Income Tax Rules and the power to implement the Act has been entrusted with the Central Board of Direct Taxes. The Act has undergone a number of changes from time to time through various Finance Acts and also by Amendment Acts and Ordinances passed by the Central Government."
                },
                {
                    type:"paragraph",
                    text:"The Central Board of Direct Taxes works as a wing of the Ministry of Finance of the Central Government."
                }

            ]
        },
        {
            question:"What is the scope of the Income Tax Act in India?",
            items:[
                {
                    type:"paragraph",
                 text:"The  Income Tax Act is meant for assessment of taxable income and tax liability of an assessee and the procedure for assessment, appeals, and penalties including prosecution. It lays down powers and duties of tax authorities."
                },
                {
                    type:"paragraph",
                text:"Every Act gives powers to an authority for implementation of the Act and Section 295 of the Act empowers the Central Board of Direct Taxes to make rules and publish in the Gazette of India, subject to the approval of the Central Government. (both Houses of Parliament)"
                },
                {
                    type:"paragraph",
                text:"Notifications are issued by Central Government or Central Board of Direct Taxes whereas circulars are issued by Central Board of Direct Taxes."
                }
            ]
        },
        {
            question:"What is the role of the Judiciary under the Income Tax Act in India?",
            items:[
                {
                    type:"paragraph",
                    text: "The judiciary has an important role to play in the Income Tax system."
                },
                {
                    type:"paragraph",
                    text:"Judgments given by judicial authorities on an appeal filed before them, are known as judicial decisions. Any decision by the Supreme Court becomes a law which is binding on all the courts, Appellate Tribunals and the Income Tax Authorities, as well as, on all the assessees. Decisions given by a High Court and Income Tax Appellate Tribunal are binding on all the assessees as well as the Income Tax Authorities which  fall under their jurisdiction unless overruled by a higher authority." 
                }
            ]
        }

    ]


    return (

        <>

        <Card style={{marginBottom:'10px'}}>
            <Card.Header>
                <p className='h3'  style={{textAlign:'left'}}>Income Tax System in India</p>
            </Card.Header>  
        </Card>

        {qandas.map((qanda, index)=>(
            <QuestionItem question={qanda.question} items={qanda.items}/>
        ))}
        


        </>


    )
}


export default IncomeTaxIndia
