import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';

import Meta from '../components/Meta'

import IncomeTaxIndia from '../components/taxfaqs/Ch1_IncomeTaxIndia'
import ImportantDefinitions from '../components/taxfaqs/Ch2_ImportantDefinitions'
import TaxLiability from '../components/taxfaqs/Ch3_TaxLiability'
import IncomeFromHouseOrProperty from '../components/taxfaqs/Ch4_IncomeFromHouseOrProperty'
import IncomeUnderTheSalaryHead from '../components/taxfaqs/Ch5_IncomeUnderTheSalaryHead'
import AmendmentsIncomeTaxRates from '../components/taxfaqs/Ch6_AmendmentsIncomeTaxRates'
import IncomesNotPartOfTotalIncome from '../components/taxfaqs/Ch7_IncomesNotPartOfTotalIncome'
import ComputationOfTotalIncome from '../components/taxfaqs/Ch8_ComputationOfTotalIncome'
import ProfitAndGainsOfBusinessOrProfession from '../components/taxfaqs/Ch9_ProfitAndGainsOfBusinessOrProfession'
import CapitalGainsTax from '../components/taxfaqs/Ch10_CapitalGainsTax'
import IncomeFromOtherSources from '../components/taxfaqs/Ch11_IncomeFromOtherSources'
import CashCreditsUnexplainedInvestment from '../components/taxfaqs/Ch12_CashCreditsUnexplainedInvestment'
import SetOffOrCarryForwardLosses from '../components/taxfaqs/Ch13_SetOffOrCarryForwardLosses'
import DeductionsAllowedOnTotalIncomeForPaymentsMade from '../components/taxfaqs/Ch14_DeductionsAllowedOnTotalIncomeForPaymentsMade'
import DeductionsAllowedWithRespectToCertainIncomes from '../components/taxfaqs/Ch15_DeductionsAllowedWithRespectToCertainIncomes'
import AgriculturalIncomeAndItsTaxTreatment from '../components/taxfaqs/Ch16_AgriculturalIncomeAndItsTaxTreatment'
import AssessmentOfIndividualIncome from '../components/taxfaqs/Ch17_AssessmentOfIndividualIncome'
import AssessmentOfHinduUndividedFamily from '../components/taxfaqs/Ch18_AssessmentOfHinduUndividedFamily'
import AssessmentOfPartnershipFirm from '../components/taxfaqs/Ch19_AssessmentOfPartnershipFirm'
import AssessmentOfCompaniesSomeDefinitions from '../components/taxfaqs/Ch20_AssessmentOfCompaniesSomeDefinitions'
import AssessmentOfPOBIAJB from '../components/taxfaqs/Ch21_AssessmentOfPOBIAJP'
import AlternateMinimumTaxAMT from '../components/taxfaqs/Ch22_AlternateMinimumTaxAMT'
import MinimumAlternateTax from '../components/taxfaqs/Ch23_MinimumAlternateTax'
import ReturnOfIncome from '../components/taxfaqs/Ch24_ReturnOfIncome'
import ProcedureforTaxAssessment from '../components/taxfaqs/Ch25_ProcedureforTaxAssessment'
import PanAndAadharUnderTheIncomeTaxAct from '../components/taxfaqs/Ch26_PanAndAadharUnderTheIncomeTaxAct'
import TaxDeductionAndCollectionAtSource from '../components/taxfaqs/Ch27_TaxDeductionAndCollectionAtSource'
import AdvanceTax from '../components/taxfaqs/Ch28_AdvanceTax'
import TaxRefunds from '../components/taxfaqs/Ch29_TaxRefunds'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";



import {taxSEOTitle, taxSEODescription, taxSEOTags} from '../seo/TaxSEO'



export const TaxFAQsScreen = ({ match, history }) => {


    const dispatch = useDispatch()

    const curl = match.params.section_url


    const [chapterSelect, setChapterSelect] = useState("incometaxindia") 
    const toggleChapterSelect = (chapter_in) => {

        console.log("Selected Chapter ", chapter_in)
        setChapterSelect(chapter_in)

    }


    const url_chapter_map = {
        'income-tax-system-in-india':'incometaxindia',
        'important-definitions':'importantdefinitions',
        'tax-liability':'taxliability',
        'income-from-house-or-property':'incomefromhouseorproperty',
        'income-under-the-salary-head':'incomeunderthesalaryhead',
        'amendments-income-tax-rates':'amednmentsincometaxrates',
        'incomes-not-part-of-total-income':'incomesnotpartoftotalincome',
        'computation-of-total-income':'computationoftotalincome',
        'profit-and-gains-of-business-or-profession':'profitandgainsofbusinessorprofession',
        'capital-gains-tax':'capitalgainstax',
        'income-from-other-sources':'incomefromothersources',
        'cash-credits-unexplained-investment':'cashcreditsunexplainedinvestment',
        'set-off-or-carry-forward-losses':'setofforcarryforwardlosses',
        'deductions-allowed-on-total-income-for-payments-made':'deductionsallowedontotalincomeforpaymentsmade',
        'deductions-allowed-with-respect-to-certain-incomes':'deductionsallowedwithrespecttocertainincomes',
        'agricultural-income-and-its-tax-treatment':'agriculturalincomeanditstaxtreatment',
        'assessment-of-individual-income':'assessmentofindividualincome',
        'assessment-of-hindu-undivided-family':'assessmentofhinduundividedfamily',
        'assessment-of-partnership-firm':'assessmentofpartnershipfirm',
        'assessment-of-companies-some-definitions':'assessmentofcompaniessomedefinitions',
        'assessment-of-association-of-persons-or-body-of-individual-and-artificial-judicial-person':'AssessmentOfPOBIAJB',
        'alternate-minimum-tax':'alternateminimumtax',
        'minimum-alternate-tax':'minimumalternatetax',
        'return-of-income':'returnofincome',
        'procedure-for-tax-assessment':'procedurefortaxassessment',
        'pan-and-aadhar-under-the-income-tax-act':'panandaadharundertheincometaxact',
        'tax-deduction-and-collection-at-source':'taxdeductionandcollectionatsource',
        'advance-tax':'advancetax',
        'tax-refunds':'taxrefunds'


    }


    useEffect(() => {
 
        if(curl){
            console.log("Url ", curl)
            console.log("Curl ", url_chapter_map[curl])
            toggleChapterSelect(url_chapter_map[curl])
        }

    }, [])




    return (
        <>


            {curl ? (
            <Meta title={taxSEOTitle[curl]} description={taxSEODescription[curl]} keywords={taxSEOTags[curl]}/>

            ):
            (
                <Meta title={taxSEOTitle['income-tax-system-in-india']} description={taxSEODescription['income-tax-system-in-india']} keywords={taxSEOTags['income-tax-system-in-india']}/>

            )}

            <p className='h3' style={{textAlign:'center'}}>Income Tax FAQs</p>


            {isMobile && (

                <Row>
                <Col>


                <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                Topics
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item onClick={()=>toggleChapterSelect('incometaxindia')}><p className='h5'>Income Tax System in India</p></Dropdown.Item>
                
                    <Dropdown.Item onClick={()=>toggleChapterSelect('importantdefinitions')}>
                        <p className='h5'>Important Definitions</p>
                    </Dropdown.Item>
                
                
                    <Dropdown.Item onClick={()=>toggleChapterSelect('taxliability')}>
                        <p className='h5'>Tax Liability</p>
                    </Dropdown.Item>
                
                
                    <Dropdown.Item onClick={()=>toggleChapterSelect('incomefromhouseorproperty')}>
                        <p className='h5'>Income From House or Property</p>
                    </Dropdown.Item>
                
                
                    <Dropdown.Item onClick={()=>toggleChapterSelect('incomeunderthesalaryhead')}>
                        <p className='h5'>Income Under the Salary Head</p>
                    </Dropdown.Item>
                
                
                    <Dropdown.Item onClick={()=>toggleChapterSelect('amednmentsincometaxrates')}>
                        <p className='h5'>Tax Rates Amendments</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('incomesnotpartoftotalincome')}>
                        <p className='h5'>Incomes Not Part of Total Income</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('computationoftotalincome')}>
                        <p className='h5'>Computation of Total Income</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('profitandgainsofbusinessorprofession')}>
                        <p className='h5'>Profit and Gains of Business or Profession</p>
                    </Dropdown.Item>


                    <Dropdown.Item onClick={()=>toggleChapterSelect('capitalgainstax')}>
                        <p className='h5'>Capital Gains Tax</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('incomefromothersources')}>
                        <p className='h5'>Income From Other SOurces</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('cashcreditsunexplainedinvestment')}>
                        <p className='h5'>Cash Credits Unexplained Investment</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('setofforcarryforwardlosses')}>
                        <p className='h5'>Set Off Or Carry Forward Losses</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('deductionsallowedontotalincomeforpaymentsmade')}>
                        <p className='h5'>Deductions Allowed On Total Income For Payments Made</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('deductionsallowedwithrespecttocertainincomes')}>
                        <p className='h5'>Deductions Allowed With Respect To Certain Incomes</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('agriculturalincomeanditstaxtreatment')}>
                        <p className='h5'>Agricultural Income And Its Tax Treatment</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('assessmentofindividualincome')}>
                        <p className='h5'>Assessment Of Individual Income</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('assessmentofhinduundividedfamily')}>
                        <p className='h5'>Assessment Of Hindu Undivided Family</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('assessmentofpartnershipfirm')}>
                        <p className='h5'>Assessment Of Partnership Firm</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('assessmentofcompaniessomedefinitions')}>
                        <p className='h5'>Assessment Of Companies Some Definitions</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('AssessmentOfPOBIAJB')}>
                        <p className='h5'>Assessment of Association of Persons or Body of Individual and Artificial Judicial Person</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('alternateminimumtax')}>
                        <p className='h5'>Alternate Minimum Tax</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('minimumalternatetax')}>
                        <p className='h5'>Minimum Alternate Tax</p>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('returnofincome')}>
                        <p className='h5'>Return of Income</p>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('procedurefortaxassessment')}>
                        <p className='h5'>Procedure For Tax Assessment</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('panandaadharundertheincometaxact')}>
                        <p className='h5'>Pan And Aadhar Under The Income Tax Act</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('taxdeductionandcollectionatsource')}>
                        <p className='h5'>Tax Deduction And Collection At Source</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('advancetax')}>
                        <p className='h5'>Advance Tax</p>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('taxrefunds')}>
                        <p className='h5'>Tax Refunds</p>
                    </Dropdown.Item>

                </Dropdown.Menu>
                </Dropdown>


                </Col>
                </Row>

            )}







            <Row>

            {!isMobile && (

                <Col style={{maxWidth:'15vw', maxHeight:'95vh', overflow:'scroll', padding:'0px', paddingLeft:'5px'}}>

                    
                    <ListGroup>
                        <ListGroup.Item style={{backgroundColor:'gray', color:'white'}}>
                            <p className='h5'>Topics</p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('incometaxindia')}>
                                <p className='h5'>Income Tax System in India</p>
                            </Card>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('importantdefinitions')}>
                                <p className='h5'>Important Definitions</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('taxliability')}>
                                <p className='h5'>Tax Liability</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('incomefromhouseorproperty')}>
                                <p className='h5'>Income From House or Property</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('incomeunderthesalaryhead')}>
                                <p className='h5'>Income Under the Salary Head</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('amednmentsincometaxrates')}>
                                <p className='h5'>Tax Rates Amendments</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('incomesnotpartoftotalincome')}>
                                <p className='h5'>Incomes Not Part of Total Income</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('computationoftotalincome')}>
                                <p className='h5'>Computation of Total Income</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('profitandgainsofbusinessorprofession')}>
                                <p className='h5'>Profit and Gains of Business or Profession</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('capitalgainstax')}>
                                <p className='h5'>Capital Gains Tax</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('incomefromothersources')}>
                                <p className='h5'>Income From Other Sources</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('cashcreditsunexplainedinvestment')}>
                                <p className='h5'>Cash Credits Unexplained Investment</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('setofforcarryforwardlosses')}>
                                <p className='h5'>Set Off Or Carry Forward Losses</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('deductionsallowedontotalincomeforpaymentsmade')}>
                                <p className='h5'>Deductions Allowed On Total Income For Payments Made</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('deductionsallowedwithrespecttocertainincomes')}>
                                <p className='h5'>Deductions Allowed With Respect To Certain Incomes</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('agriculturalincomeanditstaxtreatment')}>
                                <p className='h5'>Agricultural Income And Its Tax Treatment</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('assessmentofindividualincome')}>
                                <p className='h5'>Assessment Of Individual Income</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('assessmentofhinduundividedfamily')}>
                                <p className='h5'>Assessment Of Hindu Undivided Family</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('assessmentofpartnershipfirm')}>
                                <p className='h5'>Assessment Of Partnership Firm</p>
                            </Card>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('assessmentofcompaniessomedefinitions')}>
                                <p className='h5'>Assessment Of Companies Some Definitions</p>
                            </Card>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('AssessmentOfPOBIAJB')}>
                                <p className='h5'>Assessment of Association of Persons or Body of Individual and Artificial Judicial Person</p>
                            </Card>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('alternateminimumtax')}>
                                <p className='h5'>Alternate Minimum Tax</p>
                            </Card>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('minimumalternatetax')}>
                                <p className='h5'>Minimum Alternate Tax</p>
                            </Card>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('returnofincome')}>
                                <p className='h5'>Return Of Income</p>
                            </Card>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('procedurefortaxassessment')}>
                                <p className='h5'>Procedure For Tax Assessment</p>
                            </Card>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('panandaadharundertheincometaxact')}>
                                <p className='h5'>Pan And Aadhar Under The Income Tax Act</p>
                            </Card>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('taxdeductionandcollectionatsource')}>
                                <p className='h5'>Tax Deduction And Collection At Source</p>
                            </Card>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('advancetax')}>
                                <p className='h5'>Advance Tax</p>
                            </Card>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('taxrefunds')}>
                                <p className='h5'>Tax Refunds</p>
                            </Card>
                        </ListGroup.Item>
                    </ListGroup>

                
                </Col>

                )}

                <Col style={{width:'85vw', maxHeight:'95vh', overflow:'scroll'}}>

                    {chapterSelect == 'incometaxindia' && (
                        <>
                            <IncomeTaxIndia />
                        </>
                    )}


                    {chapterSelect == 'importantdefinitions' && (
                        <>
                            <ImportantDefinitions />
                        </>
                    )}

                    {chapterSelect == 'taxliability' && (
                        <>
                            <TaxLiability />
                        </>
                    )}
                    {chapterSelect == 'incomefromhouseorproperty' && (
                        <>
                            <IncomeFromHouseOrProperty />
                        </>

                    )}
                    {chapterSelect == 'incomeunderthesalaryhead' && (
                        <>
                            <IncomeUnderTheSalaryHead />
                        </>
                    )}
                    {chapterSelect == 'amednmentsincometaxrates' && (
                        <>
                        
                            <AmendmentsIncomeTaxRates />
                        </>

                    )}
                    {chapterSelect == 'incomesnotpartoftotalincome' && (
                        <>
                        
                            <IncomesNotPartOfTotalIncome />
                        </>

                    )}
                    {chapterSelect == 'computationoftotalincome' && (
                        <>
                        
                            <ComputationOfTotalIncome />
                        </>

                    )}

                    {chapterSelect == 'profitandgainsofbusinessorprofession' && (
                        <>
                        
                            <ProfitAndGainsOfBusinessOrProfession />
                        </>

                    )}

                    {chapterSelect == 'capitalgainstax' && (
                        <>
                        
                            <CapitalGainsTax />
                        </>

                    )}

                    {chapterSelect == 'incomefromothersources' && (
                        <>
                        
                            <IncomeFromOtherSources />
                        </>

                    )}

                    {chapterSelect == 'cashcreditsunexplainedinvestment' && (
                        <>
                        
                            <CashCreditsUnexplainedInvestment />
                        </>

                    )}

                    {chapterSelect == 'setofforcarryforwardlosses' && (
                        <>
                        
                            <SetOffOrCarryForwardLosses />
                        </>

                    )}

                    {chapterSelect == 'deductionsallowedontotalincomeforpaymentsmade' && (
                        <>
                        
                            <DeductionsAllowedOnTotalIncomeForPaymentsMade />
                        </>

                    )}
                    
                    {chapterSelect == 'deductionsallowedwithrespecttocertainincomes' && (
                        <>
                        
                            <DeductionsAllowedWithRespectToCertainIncomes />
                        </>

                    )}

                    {chapterSelect == 'agriculturalincomeanditstaxtreatment' && (
                        <>
                        
                            <AgriculturalIncomeAndItsTaxTreatment />
                        </>

                    )}


                    {chapterSelect == 'assessmentofindividualincome' && (
                        <>
                        
                            <AssessmentOfIndividualIncome />
                        </>

                    )}


                    {chapterSelect == 'assessmentofhinduundividedfamily' && (
                        <>
                        
                            <AssessmentOfHinduUndividedFamily />
                        </>

                    )}


                    {chapterSelect == 'assessmentofpartnershipfirm' && (
                        <>
                        
                            <AssessmentOfPartnershipFirm />
                        </>

                    )}


                    {chapterSelect == 'assessmentofcompaniessomedefinitions' && (
                        <>
                        
                            <AssessmentOfCompaniesSomeDefinitions />
                        </>

                    )}


                    {chapterSelect == 'assessmentofPOBIAJB' && (
                        <>
                        
                            <AssessmentOfPOBIAJB />
                        </>

                    )}


                    {chapterSelect == 'alternateminimumtax' && (
                        <>
                        
                            <AlternateMinimumTaxAMT />
                        </>

                    )}


                    {chapterSelect == 'minimumalternatetax' && (
                        <>
                        
                            <MinimumAlternateTax />
                        </>

                    )}


                    {chapterSelect == 'returnofincome' && (
                        <>
                        
                            <ReturnOfIncome />
                        </>

                    )}

                    {chapterSelect == 'procedurefortaxassessment' && (
                        <>
                        
                            <ProcedureforTaxAssessment />
                        </>

                    )}

                    {chapterSelect == 'panandaadharundertheincometaxact' && (
                        <>
                        
                            <PanAndAadharUnderTheIncomeTaxAct />
                        </>

                    )}

                    {chapterSelect == 'taxdeductionandcollectionatsource' && (
                        <>
                        
                            <TaxDeductionAndCollectionAtSource />
                        </>

                    )}


                    {chapterSelect == 'advancetax' && (
                        <>
                        
                            <AdvanceTax />
                        </>

                    )}

                    {chapterSelect == 'taxrefunds' && (
                        <>
                        
                            <TaxRefunds />
                        </>

                    )}

 


                
                </Col>
            </Row>




        </>
    )
}


export default TaxFAQsScreen
