//Set Off or Carry Forward Losses

var qandas = [

    {
        question:"On which income is Income tax payable?",

        items:[
            {
            text:"Income tax is payable on the net income after setting off all the intra-Head and inter-Head adjustments of the previous year and after setting off all the allowable losses of  the previous years carried forward (Sections 70 to 80)."
            }
        ]
    },
    {

        question:"What is the rule relating to set off of loss from one source against income from another source, under the same Head of income?",
        items:[
            {
                text:"As per Section 70, where the net result in any Assessment Year in respect of any source of income falling under any Head of income other than capital gains is a loss, the assessee shall be entitled to have the amount of such loss set off against his income from any other source under the same Head of income. This is  referred to as intra-head adjustment ."
            }
    ]
},

    {

        question:"Which are the situations under which loss from one source cannot be adjusted against income from another source of income, although it falls under the same Head of income? ",
        items:[
            {
                text:"As per Section 73, any loss in respect of a speculation business carried on by an assessee shall be set off only against income of another speculation business."
            },
            {
                type:"list",
                list:[
                    "It cannot be set off against income from any non-speculation business, although speculation business also falls under the Head profits and gains of business or profession.",
                    "Business loss can be set off against income from speculation business but vice versa is not allowed. "
                ]        
    }
    ]
    },
    {

        question:"What is meant by ‘speculative transaction’?",
        items:[
            {
                text:"Speculative transaction means a transaction in which a contract for purchase or sale of any commodity including shares and stocks is periodically or ultimately settled otherwise than by actual delivery or transfer of commodity or scraps."
            }
        ]
    },

    {

        question:"How is profit / loss from derivative trading, carried out through arecognised stock exchange, treated under the Income tax Act?",
        items:[
            {
                text:"Any loss or profit from a derivative trading carried out through a recognized stock exchange shall not be treated as profit or loss from a speculation business. It shall be treated as income or loss of a non- speculative business. Further, derivative trading in foreign exchange shall also be treated as non-speculative business."
            }
        ]
    },
    {

    question:"What is the tax provision in relation to profit / loss in any ‘specified business’?",
    items:[
        {
            type:"list",
            list:[
                "Loss in ‘specified’ business has been referred to in Section 35AD.",
                "As per Section 73A, any loss computed in respect of any ‘specified business’ referred to in Section 35 AD can be set off against profit from any other ‘specified business’. ",
                "It cannot be set off against income from any other normal business."
        ]
        }
        ]
    
    },

    {
        question:"What is the rule relating to loss from owning and maintaining race horses?",
        items:[
            {
                type:"list",
                list:[
                    " As per Section 74A, the loss incurred by an assessee, in the activity of owning and maintaining race horses, shall only be set off against income of such activity.", 
                    "It cannot be set off against the income from any other source."
                ]
            }
        ]
    },

    {
        question:"What is the loss set-off rule in the case of loss incurred on account of lottery, crossword puzzles, card games, entertainment, reality shows etc.?",
        items:[
            {
                type:"list",
                list:[
                    "Loss incurred on account of lottery, crossword puzzles, card games etc cannot be set off against winnings from lotteries, crossword puzzles, card games.etc",
                    " In short, the losses from the above activities will not be allowed to be set off against income from any other such activity.",
                    "Also, no expenditure or allowance is allowed as deduction from winnings from lotteries, crossword puzzles, card games  etc."
                ]
            }
        ]
    },

    {


    question:"What is meant by Inter-Head adjustments? What is the loss set -off rule in this connection?",
        items:[
            {
                text:"As per Section 71(1), where in respect of any assessment year, if after setting off losses under the same Head ( intra-head adjustment), the net result of the computation under any other Head of income other than capital gains, is a loss, the assessee shall be entitled to have the amount of such loss set off against his income under any other Head of income."
            }
        ]
    },

    {

    question:"What is the provision to set-off loss under the Head ‘Income from business or profession’?",
        items:[
            {
                text:"According to Section 72, the loss made under the Head ‘Income from business or profession’ in any previous year can be set off against income from house property and income from other sources of the same Assessment Year, but not against ‘Income from capital gains’ or ‘income from salary’."
            }
        ]
    },
    {

    question:"What is the rule relating to loss under the Head ‘ Income from capital gains’?",
        items:[
            {
                type:"list",
                list:[
                    "Section 74 lays down that short term capital loss can be set off against short term capital gains or long term capital gains in the same Previous Year. ",
                    "The long term capital loss can be set off only against long term capital gains and not against short term capital gain."
                ]
            }
        ]
    },

    {

        question:"What is the provision for Loss under the Head ‘Income from house property’?",
        items:[
            {
                type:"list",
                list:[
                    "Loss under the Head ‘Income from house property’ is allowed to be set off against any other Head of income i.e. salary, income from business or profession, income from any other source excluding capital gains, subject to a maximum of Rs. 2 lakhs in any previous year.(Section 71B)",
                    "The remaining loss under the Head ‘Income from house property’ in any previous year, is allowed to be carried forward for the next eight Assessment Years."
                ]    
        }
        ]
    },

    {


    question:"What are the cases where intra-head and inter-head adjustments are not allowed?",
    items:[
        {
            text:"In the following cases, intra head adjustments are not permitted and hence inter head adjustments are also not allowed."
        },
        {
            type:"list",
            list:[
                "Loss from a speculation business.",
            "Loss of owning and maintaining race horses.",
            "Loss of lottery, crossword puzzles, card games, entertainment and reality shows etc  cannot be set off against income from any other source under the same head of income.",
            "oss from a source which is exempt."
            ]
        },
        {
            text:"In the case of loss from speculation business, loss from specified business, loss from horse race etc, can be set off only against the income from the same business and therefore they can be carried forward for set off against future profits made by the same business in the subsequent four assessment years."
        }
]
    },
    {

        question:"What are the losses which can be carried forward?",
        items:[
            {
                text:"The following losses are allowed to be carried forward:"
            },
            {
                type:"list",
                list:[
                    "Loss under the Head ‘Income from  house property’.",
                    "Loss  under the Head ‘Profits and gains of business  or profession’.",
                    "Speculation business loss under the Head ‘profits and gains of business or profession’.",
                    "Specified business loss under the Head ‘profits and gains of business or profession’.",
                    "Loss on account of owning and maintaining racehorse under the Head ‘Income from other sources’.",
                    "Loss under the Head ‘capital gains’."
                ]
            }
        ]
    },
    {

        question:"Why is there no loss under the Head ‘Salary”?",
        items:[
            {
                type:"list",
                list:[
                    "There is no question of  loss under the Head ‘Salary’ because income tax is payable only on the salary received or receivable.",


                    "The salary income can, however,  be used to set-off loss under the Head ‘Income from house property’ and the Head  ‘Income from other sources’.",


                    "The salary income cannot be set off against loss under the Head ‘Profits and gains of business or profession’ or under the Head ‘Capital gains’."
                ]
            }
    ]

    },
    {

        question:"What is the rule relating to carry forward and set off  loss under the Head ‘Income from house property’.",
        items:[
            {
                text:"According to Section 71B, the unabsorbed loss under the Head ‘Income from house property’ for a previous  year can be carried forward for eight assessment years, even though the return of loss is not filed before the due date."
            },
            {
                type:"list",
                list:[
                    "The maximum amount allowed to be set off against any other allowable head of income in any previous year is Rs. 200,000.",


                    " The balance loss can be carried forward for subsequent eight assessment years to be set off against income  from house property.",


                    " Inter-head adjustments are allowed only in the previous year in which the loss is incurred."
                ]   
        }
        ]
    },

    {

        question:"What is the provision in the Income Tax Act for set off and carry forward of losses under the Head ‘Profits and gains from business or profession’. ",
        items:[
            {
                text:"As per Section 72, a normal business loss can be carried forward and set off against business income for eight Assessment Years only if the return of loss is filed before the due date for filing the return of income."
            },
            {

                text:"The business loss can be set off against business income though the loss incurred by the same business may or may not be continued."
            },
            {

                text:"Loss can be set off only by the person who has incurred the loss except in the following circumstances:"
            },
            {
                type:"list",
                list:[
                    "Inheritance,",
                    "Amalgamation,",
                    "Succession of proprietary concern by firm or company,",
                    "Succession of a private limited company by a LLP,",
                    "Demerger"
                ]
            }
        ]

    },
    {


        question:"What is the rule relating to set off and carry forward of speculation business loss?",
        items:[
            {   
                text:"The  business loss from speculation business can be carried forward for four Assessment Years to be set off against income from speculation  business. "
            }
            ]
    },
    {

        question:"What is the provision for set off and carry forward of specified business losses?",
        items:[
            {
                type:"list",
                list:[
                    "As per Section 70A,  loss of specified business can be carried forward and set off indefinitely against profits made from specified business in the subsequent Assessment Years. ",
                    "There is no time limit for carry forward of specified business loss.",
                    "It is necessary to file the return of loss before the due date for filing the return of income to claim carry forward and set off of losses."
                ]
            }
    ]
    },
    {

        question:"What is the rule for set off and carry forward of losses under the Head ‘Capital gains’?",
        items:[
            {
                text:"Section 74 lays down that the loss incurred  under the Head ‘Capital gains,’ whether short term loss or long term, can be carried forward for eight Assessment Years."
            }
        ]
    }

]

export {qandas}

 //                                   —----------------------------