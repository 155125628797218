//Advance Tax

var qandas = [

   {
   question:"What is meant by Advance tax?",
      items:[
         {
            type:"list",
            list:[
               "Advance Tax is a method of collection of tax beforehand like tax deduction at source (TDS) and Tax collection at  source (TCS).",
               "It is laid down by the Central Government.",
               "Advance tax is a pre-paid tax.",
               "The concept of advance tax is based on the principle of ‘Pay as you earn’.",
               "It involves the payment of tax in instalments.",
               "Provisions of Advance tax are contained in Sections 207-211, 217 and 219 of the Income Tax Act."
            ]   
      }
      ]
   },
   {


   question:"What is the tax liability under Advance Tax?",
   items:[

      {
         text:"Sections 207 to 219 of the Income Tax Act lay down the different provisions relating to Advance tax."

      },
      {
         type:"list",
         list:[
"Normally, the total income of an  assessee in a financial year is computed and the tax due on the income is paid in the following assessment year.",

"However, in the case of advance tax, the tax on total income computed in a financial year is paid in the very same financial year.",


"Such total income, on which advance tax is paid, is called ‘current income’.",


"Advance tax includes surcharge and health and education cess.",

"Assessees who do not have income under the Head ‘ Profits and gains from business or profession’ do not have to pay Advance tax.",
   "* Similarly, assessees who are above the age of 60 ( senior citizens), are exempted from the payment of Advance tax."
         ]

      }

   ]

   },
   {

      question:"What is meant by ‘current income’?",
      items:[
   {
      text:"‘Current income’ comprises all kinds of income earned by an assessee in a given financial year."
   }   
]

   },
   {

      question:"How can an assessee calculate Advance Tax?",
      items:[
{
   text:"The method of computation of advance tax by an assessee on his estimated income has been laid down in Section 209 of the Income Tax Act."
},
{
   type:"list",
   list:[
         " First, draw up an estimate of the income in the relevant financial year.",
         " Then calculate the tax on this income according to the specified rate in the Act.",
         " Add surcharge ( if applicable) on the tax calculated.",
         " Add health and education cess, if applicable",
         " If eligible for any tax benefit or relief, deduct the same from the amount calculated.",
         " If any credit is available, deduct the same.",
         " Again, deduct any TDS or TCS i.e. tax which has already been deducted or collected at source.",
         " The balance that remains is the  advance tax, which has to be paid in instalments.",
         " However, if the balance is less than Rs.10,000, no advance tax has to be paid."
   ]
}
      ]
   },
   {


   question:"When are the advance tax instalments due?",
   items:[
{
   text:"As per Section 211 of the Income Tax Act, advance tax is payable in quarterly instalments in each financial year."
},
{
   type:"list",
   list:[
      " On or before 15th June (Q1)",
      " On or before 15th September ( Q2)",
      " On or before 15thDecember ( Q3)",
      " On or before 15th March (Q4)"
   ]
},
{

 text:"The assessee is allowed to revise the estimates interim and pay the advance tax on the basis of the revised estimate and pay equally in the remaining installments ."

},
   ]
},
{

   question:"Do all assessees have to pay advance tax?",
   items:[
{
   text:"No."
},
{ 
   text:"Section 207(2) provides exemptions to certain individuals from paying advance tax."
},
{
   type:"list",
   list:[
      "Individuals not having income under the head ‘Profits and Gains of business or profession’ ",
      "Individuals aged 60 years and above(senior citizens)"
   ]
}
   ]

   },
   {


   question:"What is the amount of advance tax to be paid each quarter of the financial year?",
   items:[
{
      text:"As per Section 211 of the Income tax Act, the advance tax to be paid each quarter is: "
},
{
   type:"list",
   list:[
      "Q1 (on or before 15th June) :  At least 15% of the  total  advance tax payable",


      "Q2 ( on or before 15th September) :  At least 45% of the total advance tax payable ( including 1st instalment)",


      "Q3 (on or before 15th December) :  At least 75% of the total advance tax payable ( including 1st & 2nd instalments)",


      "Q4 ( on or before 15th March) :  100% of advance tax (including 1st, 2nd and 3rd instalments)"
   ]
}
   ]
   },
   {

   question:"Is advance tax payment taken into account during the regular assessment of tax returns?",
      items:[
         {
            text:"Yes."
         },
         {
            text:"As per Section 219 of the Income Tax Act, credit is given to advance tax payments by an assessee."
         },
         {
            text:"Any amount received from an assessee ( apart from interest or penalty) is recognised as advance tax payment and credit is given for it during regular assessment of income tax returns."
         }      
]
   }

]

export {qandas}