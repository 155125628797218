import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const Invoicesvsreceipts = ({  }) => {


    var items = [
        {
            type:"paragraph",
            text:"Whether you are running a business and providing / receiving goods or services or you are an individual spending money in day to day life, you would have come across invoices and receipts many times."
        },
        {
            type:"paragraph",
            text:"An invoice and a receipt are two very different things even though many people use them interchangeably."
        },
        {
            type:"paragraph",
            text:"An invoice is issued by a business requesting a payment for the sale of goods or for services provided. This does not indicate any payment made. It merely confirms that the customer has received the goods promised or the services requested."
        },
        {
            type:"paragraph",
            text:"So, invoices can tell you have much income or expense has been accrued by the business."
        },
        {
            type:"paragraph",
            text:"A receipt indicates that the payment has been received. It is proof that payment for the invoice issued has been received by the business."
        },
        {
            type:"paragraph",
            text:"A receipt, thus, tells you how much income or expenditure has actually occurred i.e. how much money has actually changed hands."
        },
        {
            type:"paragraph",
            text:"It is essential as a business to ensure that you issue invoices and receipts and keep all bills / invoices received from other businesses. This will ensure the accounting is accurate and, also, allow for future cross checking."
        },
        {
            type:"paragraph",
            text:"For an individual, keeping a record of all invoices provides a good understanding of the spending pattern."
        }
    ]

    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Invoices and Receipts</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default Invoicesvsreceipts
