//Alternate Minimum Tax (AMT)

var qandas = [

{

    question:"What is meant by Alternate Minimum Tax?",
    items:[
        {
            text:"Alternate Minimum Tax (AMT) is the minimum income tax payable on the normal business income by a person other than a company in any previous year."
        },
        {
            text:"It is applicable to all persons other than companies [[Section 115JC to Section 115 JF]. "
        },
        {
            text:"AMT arises : "
        },
        {
            type:"list",
            list:[
                " when  the assessee  makes a profit as per the financial  statements  prepared by him in any previous year",


                "without making any deductions for special incentives and allowances under the Act",


                "and the computation of income tax as per the  Income Tax Act  is less than the book profit or loss.",


                " In such a case, the adjusted total income shall be deemed to be the total income of such person and he shall be liable to pay income tax on such total income at the rate of 18.5%. [Section 115JC (1)]",


                " However, the Alternate MinimumTax paid in any previous year will be allowed to be set off against the tax payable on the regular taxable income after allowing all the deductions under the Act in the ten subsequent assessment years, if the  regular taxable income is more than the Alternate Tax.",


                "In the case of a unit located in any international financial services centre which derives income solely in convertible foreign exchange, the Alternate MinimumTax shall be 9% instead of 18.5%(Section 115JC(4)].",


                "In the case of a co-operative society, the AMT shall be  fifteen percent."
            ]
        }

    ]

},
{


    question:"What is meant by Regular Income Tax?",
    items:[
        {
            text:"Regular income tax is the income tax payable for a previous year by a person other than a company on his total income computed in accordance with the provisions of the Income Tax Act, other than Chapter XII-BA {Section 115JF (d)]."
        }
    ]
}

]


export {qandas}