//Income under the Head ‘Salary’

var qandas = [
{
question:"What is the definition of  ‘Salary’ under Income Tax rules in India?",
items:[
   {
      text:"Salary [ Sections 15 to 17] is the remuneration paid by an employer to an employee for his services."
   },
   {
      text:"The employee should be an individual, whereas an employer can be any person."                                                                           
   },
   {
      text:"There should be a contract of employment in writing or oral between the employer and the employee specifying the terms and conditions in the contract of employment. ",
   },
   {  
      text:"The remuneration maybe :"
   },
   {
      type:"list",
      list:[
         "Any salary due from an employer or former employer to the employee in the previous year.",
         "Any salary paid to him by an employer or previous employer, whether it is due or not in the last year.",
         "Any arrears of salary paid by an employer or previous employer in the previous year."
      ]
   }
   ]
},
{
   question:"What does the term ‘Salary’ include?",
   items:[
      {
         text:"Salary under Section (17(1), includes :"
      },
      {
         type:"list",
         list:[
            "Wages,",
            "Annuity or Pension,",
            "Gratuity,",
            "Any fees, commission, perquisites, or profits instead of, or in addition to, any salary or wage,",
            "Any advance salary,",
            "Employer’s contribution to Approved Provident Fund over 12% of the salary and interest on the Provident Fund over 9.5% per annum.",
            "The aggregate of all sums that make up the transferred balance of an employee participating in a recognized Provident Fund to the extent to which it is chargeable to tax.",
            "The contribution made by the Central Government or any other employer in the previous year to the account of an employee under a Notified Pension Scheme referred to in Section 80CCD. The Central Government’s contribution to the Agniveer Corpus Fund account of an individual enrolled in the Agnipath scheme shall be considered as salary per the provisions of section 17A; corresponding deduction shall be allowed under Section 80CCH for the same.",
            "Salary in lieu of the notice period.",
            "Overtime.",
            "Bonus.",
            "House rent allowance. In the case of house rent allowance, the amount chargeable to tax is higher than the following three amounts:- (a) Actual house rent allowance received (b) Allowance over 10% of the salary,  (c) 50% of the allowance received in Mumbai, Kolkata, Delhi, and Chennai, and 40% in other places."
         ]
      }
   ]
}, 


{
   question:"What are the prescribed allowances which are allowed as deduction from salary?",
   items:[
      {
         text:"The following prescribed allowances are allowed as a deduction from the Salary [Section 10(14)]. Allowances are given to meet expenses related to employment."
      },
      {
         type:"list",
         list:[
         "Travelling Allowance is given to meet expenses on official tours.",
         "Daily Allowance is given to meet boarding and lodging expenses on official tours, including conveyance expenses at the place of official work.", 
         "Local conveyance paid for official work.",
         "Any expense incurred by an employee on a helper for official work.",
         "Any allowance given to encourage academic, research, and training pursuits in educational and research institutions.",
         "Any allowance to purchase and maintain uniforms used during office hours."
         ]
      },
      {
         text:"The above expenses are allowed to the extent of the allowance received or the actual amount spent, whichever is less."
      }  
   ]
},
{

   question:"Which allowances given to employees are allowed as deduction from salary?",
   items:[
      {
         text:"The following allowances given to an employee like personal expense, are allowed as a deduction from the salary :",
      },
      {
         type:"list",
         list:[
            "Tuition fee of Rs 100 per month to an employee’s child, but subject to a maximum of two children.",
            "Hostel allowance of Rs 300 per month paid to an employee’s child subject to a maximum of two children. Under the New Tax regime, this is not allowed as a deduction.",
            "Tribal area/ Scheduled Area/Agency area allowance, received by an individual exempt up to the extent of the actual amount received or Rs.200 per month, whichever is less",
            "Special compensatory hilly area allowance or high altitude allowance paid to army personnel, exemption varies from Rs.300 to Rs7000 per month. ",
            "Border Area, remote area allowance, disturbed area allowance paid to army personnel, and exemption varies from Rs.200 to Rs1,300 per month.",
            "Compensatory field area allowance paid to army personnel, exempt to the extent of Rs.2600 per month.",
            "Compensatory modified field area allowance to an army man, exempt to Rs.1,000 per month.",
            "Counter-insurgency allowance granted to Armed Forces members, exempt to Rs.3,900 per month.",
            "Transport allowance  given to an employee who is blind, dumb or deaf or orthopedically handicapped with a disability of lower extremities is exempt to Rs 3,200 per month.",
            "Underground allowance given to an employee working in unnatural climatic conditions, such as workers working in underground mines, shall be exempt to Rs.800 per month.",
            "High Altitude allowance (uncongenial climate)  given to the Armed Forces members exempt for altitudes 9,000 to 15,000- Rs1,060 per month and altitude above 15,000- Rs1,600 per month.",
            "Special compensatory highly active field area allowance granted to Armed Forces members, exempt to Rs.4200 per month.",
            "Inland (duty) allowance given to the members of Armed Forces in Andaman and Nicobar and Lakshadweep group of Islands, exempt to Rs.3250 per month.",
            "Allowance to transport employees working in any transport company. The employer gives the employees working in the transport system a fixed amount to meet personal expenses incurred while running the transport from one place to another. The amount of allowance exempted shall be 70% of the allowance given or Rs10,000 per month, whichever is less.",
            "Entertainment Allowance is allowed only in the case of Government employees, and the amount allowed in any previous year is the lowest of the following amounts (i)The actual entertainment allowance received,(ii) 20% of the salary, or (iii)Rs.5,000.",
            "These allowances are exempted to the extent of the actual allowance received or the specified limits."
          ]
      }
]
},

{

   question:"Which allowances given to employees are fully taxable ?",
   items:[
      {
         text:"The following allowances given to employees are fully taxable:"
      },
      {
         type:"list",
         list:[
"Dearness Allowance",
"City Compensatory Allowance",
"Medical Allowance is fully taxable irrespective of whether any amount has been spent on medical treatment or not",
"Lunch allowance / Tiffin allowance ",
"Overtime allowance",
"Servant allowance",
"Non-practicing allowance.",
"Family allowance.",
"Warden allowance."
         ]
      }
   ]

},



{
   question:"What is meant by the term ‘perquisite’ ?",
   items:[
      {
         text:"As per Section 17(2) of the Income Tax Act 1961, perquisite means :"
      },
      {
         type:"list",
         list:[
"The value of rent-free accommodation provided by the employer to the employee.",
"Any concession  given in the rent-free accommodation provided by the employer to the employee.",
"The value of any benefit or amenity granted or provided free of cost or at a concessional rate in any of the following cases to an employee who is a specified employee:- by a company to a director who is an employee, by a company to an employee who has a substantial interest in the company, i.e.an employee who is the beneficial owner of shares having a voting power of more than 20%, by an employer (Including a company) to an employee to whom the provisions of clauses (a) and (b) do not apply and whose income under the Head ‘Salaries’ (whether due or paid or allowed by one or more employers) exclusive of the value of all benefits or amenities not provided for by way of monetary payment exceeds Rs.50,000 per month ",
"Any sum paid by an employer, which, but for such payment, would have been payable by the employee.",
"Any sum payable by the employer to effect insurance on an employee’s life or to effect a contract for an annuity.",
"The value of any specified security or sweat equity shares allotted or transferred directly or indirectly by the employer or former employer free of cost or at a concessional rate to the employee.",
"The amount of contribution by the employer to an approved superannuation fund for the employee exceeding Rs1,50,000 per annum",
"The value of any fringe benefit or amenity the employer provides."
         ]
      }
]
},
{
   question:"What are the Tax-free perquisites available to all employees ?",
   items:[
      {
         type:"list",
         list:[
   "Medical facility provided to an employee or his family members by the hospital, dispensary, or nursing home maintained by the employer,",
"Any expenditure incurred on training the employees, payment of fees for refresher courses.",
"Use of health clubs, sports, and similar facilities provided by the employer uniformly to the entire employees.",
"Expenses on the telephone, including mobile phone, actually incurred by the employer for the employee.",
"Employer’s contribution to Superannuation fund less than Rs.1,50,000 per employee per year.",
"Insurance premium paid on an accident insurance policy taken by the employer on the employee.",
"Scholarship granted to the children of the employees.",
"Free food and non-alcoholic beverages provided to an employee during working hours in his workplace; the amount should be at most Rs.50 per meal.",
"Payment through non-transferable vouchers to eateries for employees. ",
"Tea or snacks provided during working hours. ",
"Free food and non-alcoholic beverages provided in a remote area during working hours.",
"Loan to employees. : 1) Loan to the employee up to a maximum of Rs20,000 per annum. 2) Loans given for medical treatment concerning diseases specified in rule 3A of the Income-tax Rules. The exemption will not be available to the extent of the amount reimbursed to the employee under medical insurance.",
" Perquisites provided by the Government to its employees serving outside India.",
" Rent-free accommodation and conveyance provided to a Supreme Court judge.",
" Rent-free accommodation provided to an officer of Parliament, Union Minister, or Leader of Opposition in Parliament.",
" The accommodation provided shall be a tax-free perquisite if the total is delivered to an employee working at a mining site, an offshore oil exploration site or a project site or a project execution site or a dam site, or a power generation site or an offshore site which, : 1) being of a temporary nature and having a plinth area not exceeding 800 sq feet located not less than eight kilometers away from the local limits of any Municipality or a Cantonment Board, or 2) is located in a remote area.",
"Educational facility for children of the employee. Where the employee’s children are studying in an educational institution run by the employer or the education provided by the employer to the children of the employee costing less than Rs 1,000 per month.",
"Use of laptops and computers owned or hired by the employer and used by the employee or his family is a tax-free perquisite.",
"Leave Travel Concession,: 1) Ticket for self and spouse and two children for travel in India (to and fro) by the shortest route once in two years or two times in a block of four years, 2) Due to Covid Pandemic, with effect from the Assessment Year 2021-2022, the value of any leave travel concession or allowance received by or due to an individual shall also be exempt under this clause subject to fulfillment of conditions to be prescribed.",
"Medical Treatment: The Following provision has been inserted in section 17(2) with retrospective effect from the Assessment Year 2020-2021. 1) To provide the relief as stated in the press statement released by the Finance Ministry on 25/6/2021, the Finance Act has amended section 17(2) and inserted clause (c )in the proviso to state that any sum paid by the employer in respect of any expenditure incurred by the employee on his medical treatment or treatment of any family member in respect of any illness relating to COVID 19 subject to such conditions as may be notified by the Central Government shall not be forming part of ‘perquisite’ 2) Expenditure incurred by any person on the medical treatment of any other person or their family member on the treatment of COVID-19 and ex-gratia payment received by family members of a person from the employer of such person or from another person on the death of a person on account of COVID- 19 not to be taxed under section 56(2)(x)  ",
"Income Tax paid by the employer on non-monetary perquisites (other than cash payment made) provided by the employer to the employee is tax-free.",
"Medical Treatment outside India. The medical expenses incurred by the employer on the treatment of an employee or his family members abroad are tax-free, subject to the following conditions:- 1)The expenses should be approved by the Reserve Bank of India. The expenses of the stay abroad of the employee or family member with one attendant accompanying the patient, subject to the Reserve Bank approval. Travel expenses of the patient with one attendant accompanying him. This is available to an employee provided the salary (exclusive of this medical expense) does not exceed more than Rs.2 lakh per annum."
         ]
      }
   ]
},

{
   question:"Under what conditions is salary treated as profit for tax purposes?",
   items:[
      {
         text:"Profits instead of salary are taxable. [Section 17(3)]"
      },
      {
         type:"list",
         items:[
"The amount of compensation received by the employee on termination or modification of the terms and conditions of the contract of employment from the employer or former employer is profits instead of salary.",
"Payment received from an Unrecognized Provident Fund or Unrecognized Superannuation Fund to the extent of the employer’s contribution and the interest accrued thereon is taxable in the hands of the employee.",
"Any sum received under a key-man Insurance Policy, including bonus, will be treated as profits instead of salary. ",
"Any sum received from the employer before joining or after cessation of employment will be treated as profits instead of salary."
         ]
      }
   ]
},
{
   question:"What are the rules regarding retirement benefits for tax purposes?",
   items:[
      {
         text:"The following amounts received from the employer after allowing deduction under Section 10 will be treated as profits instead of salary:- ",
      },
      {
         text:"Death cum retirement gratuity. The amount exempted under Section 10(10) is as follows:-",
      },
      {
         type:"list",
         list:[
      "In the case of a Government employee, full gratuity received is exempted,",
      "In the case of other employees, the lowest of the following, 1) The actual gratuity received, or 2) In the case of employees covered under the Gratuity Act, 15 days’ salary for each completed year of service or Rs 20 Lakhs, 3) In the case of any other employee, it is half a month’s salary for each completed year of service, or Rs 20 Lakhs."
         ]
      }
   ]
},

{
   question:"What are the tax rules regarding Pension ?",
   items:[
      {
         text:"Pension is the reward paid by an employer after an employee’s retirement for the services rendered during his employment."
      },
      {
         text:"In the case of a non-commuted pension, i.e., periodic pension, it is fully taxable in the hands of Government employees and non- Government employees."
      },
      {
         text:"Some employers commute the pension and pay a lump sum amount at the time of retirement, and the balance is paid in monthly installments."
      },
      {  
         text:"The amount exempted under Section10 (10A) is,"
      },
      {
         type:"list",
         list:[
      "In the case of Government employees, employees of Local Authorities, and Statutory Corporations, the commuted pension is fully exempted.",
      "In the case of other employees, if they receive gratuity from the employer, it is 1/3rd of the commuted pension received, and",
      "In the case of others who do not receive gratuity, it is 50% of the commuted pension received."
         ]
      }
         ]
   },
{
   question:"What are the tax rules relating to Encashment of Leave Salary ? ",
   items:[
      {
         text:"The amount exempted under Section10 (10AA) is as follows:-",
      },
      {
         type:"list",
         list:[
   "In the case of Central and State Government employees, it is fully exempted.",
   "In the case of any other employees, the lowest of the following amounts: 1) The actual amount received against leave salary, 2) 10 months average salary, 3) Cash equivalent of unavailed leave calculated based on a maximum of 30 days leave for every completed year of service., 4) Rs3,00,000."
      ]
   }
   ]
},

{
   question:"What is the tax rule relating to Retrenchment compensation ?",
   items:[
   {
      text: "In the case of the compensation received by a worker under the Industrial Disputes Act, 1947, or any other Act notified or any standing order or any award, contract of service, or otherwise, the amount exempted under Section 10(10B) is the lowest of the following amounts:"
   },
   {
      type:'list',
      list:[
   "The actual amount received,",
   "15 days average pay for every completed year of service or part thereof, if it exceeds more than six months,",
   "Rs5,00,000."
   ]
   }
   ]
}


]

export {qandas}
//____________________________________________________________________