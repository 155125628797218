//Assessment of Partnership Firm

var qandas =[

  {

    question:"What is the position of a Limited Liability Partnership Firm under the Income Tax Act, 1961?",
    items:[
      {
        type:"list",
        list:[
       "A partnership firm does not have a separate legal entity apart from that of the partners,  as the partners are the owners of the firm.",
       "However, a firm is treated as a separate tax entity under the Income Tax Act for tax purposes. ",                                             
       "Therefore, a Limited Liability Partnership Firm ( LLP) will be treated like  any other partnership firm under the Income Tax Act. "
        ]
      }  
  ]
  },
  {

    question:"How is the tax liability of a Partnership  Firm assessed?",
    items:[
      {
        text:"The total income of a Partnership Firm is computed under four Heads of income;"
      },
      {
        type:"list",
        list:[
          "Income from house property",
          "Profits and Gains of business or profession",
          "Capital Gains",
          "Income From other sources"
        ]
      },
      {
        text:"There will be no ‘Income from salary’ for a partnership firm as only ‘individuals’  can be employees who receive salary."
      },
      {
        text:"While computing the income under the Head ‘Profits and Gains of Business or Profession’, the deductions under Sections 30 to 37 will be allowed."
      },
      {
        text:"Apart from this, the  remuneration to working partners and interest paid to the partners on the capital / loan provided  by them to the firm will also be allowed as deduction."
      }
      ]
    },{

  question:"Are there any limits for payment of remuneration and interest to partners in a Partnership firm?",
      items:[
        {
          text:"Section 40(b) has laid down certain limits for payment of remuneration and interest to partners."
        },
        {
          type:"list",
          list:[
            "Remuneration to working partners and interest paid to the partners on capital or loan will not be allowed as deduction if the firm opts for presumptive income method of paying tax under Section 44AD or Section 44ADA.",
            "The firm will be taxed at 30% after allowing deduction for remuneration and interest on capital / loan of the partners and remuneration to working partners.",
            " There will be a surcharge of 12%  if the firm has a total income exceeding Rs.1 crore and health and education cess at 4% on income tax and surcharge."
          ]

}    
]
  },
  {

    question:"Is every partnership concern a ‘firm’ for tax purposes?",
    items:[
      {
        text:"A Partnership  firm will be assessed as a firm only if  the conditions mentioned in Section184 are satisfied. "
      },
      {
        tyoe:"list",
        list:[
             " In case, the conditions mentioned in Section 184 are not adhered to by the firm in a particular year to the satisfaction of the assessing officer, the assessing officer will assess the firm  as a firm , but no deduction by way of interest, salary, bonus, commission or remuneration, by whatever name called, made to the partners shall be allowed while commuting the income chargeable under the Head ‘Income from business or profession’.",


             "Such interest, salary, remuneration, bonus, commission etc shall not be chargeable in the hands of the partners  because the amount paid to the partners, not being allowed as deduction by the firm, is automatically taxed as income of the firm."
            ]  
      }  
    ]

  },
  {

      question:"What is the tax rule for the share of profit received by a partner from a partnership firm?",
      items:[
        {
          type:"list",
          list:[
            "The share of profit received by a partner from a partnership firm will be exempt in the hands of the partners{Section 10(3).",
            "However, the interest and remuneration which was allowed as deduction to the partnership firm will be taxed in the hands of the partners as income under the Head ‘Share of Profits of Business or Profession’."
          ]
        }
      ]

},
{

  question:"What are the essential conditions to be satisfied by a firm to be assessed as a ‘firm’ and to be eligible for deduction of interest, salary, etc to the partners?",
  items:[

      {
        text:"Section 184 lays down the conditions that must be satisfied by the firm in the First Assessment Year to be assessed as a firm:"
      },

      {
        text:"1) There should be a written document specifying the terms and conditions of partnership."
      },
      {
        type:"list",
        list:[
            "This document is called Partnership Deed",
            "The individual share of the partners is specified in the Partnership Deed.",
            "A certified copy of the partnership deed must be filed along with the return of income in respect of the assessment year for which the assessment as a firm is first sought."
        ]

      },
      {

        text:"2) The ‘firm’ shall be assessed in the same capacity for every subsequent assessment year unless there is a change in the constitution of the firm or the share of the partners."
      },
      {

        text:"3) When there is a change in the partnership deed in any previous year, a revised certified copy of the Partnership Deed should be attached along with the return of income."
      }
]
},
{


    question:"Is payment of remuneration to working partners allowed as deduction from the ‘Income from business or profession’ ?",
    items:[
      {
        type:"list",
        list:[
          "The payment of remuneration to the working partners and interest paid to the partners is allowed as deduction only when it is authorized in the partnership deed.",


          "If payment of remuneration, interest etc is authorized in the partnership deed, but if the payment pertains  to a date prior to the date of the partnership deed, it will not be allowed as deduction.",


          "The interest to partners can be paid up to a maximum of 12% per annum, irrespective of the rate authorized in the Partnership Deed. ",


          "However, payment of remuneration to non-working partners is not allowed as a deduction from the profits and gains of  business or profession.",


          " Interest paid to a representative of the partner will be allowed as deduction under Section 40(b)."
        ]   
  }  
  ]

},
{

    question:"Can losses of partnership firms be set off or carried forward by the partners?",
    items:[
      {
        text:"No. The losses and unabsorbed depreciation of a partnership firm can be carried forward by the firm but not by the partners."
      }
  ]
},
{
  question:"What is the rule regardi  ng carry forward and set off losses in case of change in constitution of a partnership firm ?",
  items:[
    {
      type:"list",
      list:[
          "Where a change has occurred in the constitution of the firm, due to retirement of a partner or death of a partner, the firm shall be entitled to carry forward and set off  loss only to the extent of the share of profit of the retiring / deceased partner, if any, in respect of the previous year [Section 78(1)].",


          "here any person carrying on any business or profession has been succeeded in such capacity by another person otherwise than by inheritance, no person other than the person incurring the loss shall be entitled to have it carried forward and set off against his income. [Section 78(2)]"
      ]
    },
    {

      text:"To compute the same:"
    },
    {
      type:"list",
      list:[
        " Calculate the share of profit of the retiring/deceased partner in the year in which there is a change in the constitution due to such retirement/death.",


        " Compute the share of loss of the retiring/deceased partner in the brought forward loss of the firm.",


        " Set off the share in the brought forward losses of the retiring/deceased partner from his share of the profit of the current year.",


        " This set off of share of brought forward loss will be allowed to the extent of share of profit of such partner.",


        " The balance loss, if any, will not be allowed to be carried forward either to such a partner or the firm.",


        " On the other hand, if, in the current year also the share of the partner is a loss, then,  the share of the brought forward loss along with the share of loss of current year, will not be allowed to be set off and carried forward. "
      ]    
}

]
},
{


    question:"How is the Total Income of a Firm (Including LLP) computed?",
  items:[
{
  type:"list",
  list:[
      "First compute the Gross total income of the firm under the four Heads of income, i.e 1) Income from house property 2) Profits and gains of business or profession  3) Capital gains  4) Income from other sources",


      "While computing business income, remuneration and interest paid to the partners will be allowed subject to Section 40(b).",


      "Apply clubbing of income under Sections 60 and 61.",


      "Make adjustments for brought forward loss, unabsorbed depreciation etc in the income computed and deduct losses to get Gross total income.",


      "From the Gross total income, allow the deductions allowable to a firm under Chapter V1A of the Income tax Act. ",


      "This is the total taxable income of the firm."
  ]
}
  ]

}

]

export {qandas}