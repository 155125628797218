import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'
import {useHistory} from 'react-router-dom'


const ToolMain = ({ case_in }) => {

    const history = useHistory()

    const [viewMode, setViewMode] = useState("transactions") 

    const toggleViewMode = (mode_in) => {

        if(viewMode == mode_in){
            setViewMode('transactions')
        }else{
            setViewMode(mode_in)
        }

    }



    return (

        <>
 
            <InputGroup>
            <Button className='button1' style={{paddingTop:'0px', paddingBottom:'0px', borderRadius:'10px', marginBottom:'5px'}} onClick={()=>toggleViewMode('transactions')}>
                Transaction
            </Button>
            <Button className='button1' style={{paddingTop:'0px', paddingBottom:'0px', borderRadius:'10px', marginBottom:'5px'}} onClick={()=>toggleViewMode('ledger')}>
                Ledger
            </Button>
            <Button className='button1' style={{paddingTop:'0px', paddingBottom:'0px', borderRadius:'10px', marginBottom:'5px'}} onClick={()=>toggleViewMode('bankbook')}>
                Bank Book
            </Button>
            <Button className='button1' style={{paddingTop:'0px', paddingBottom:'0px', borderRadius:'10px', marginBottom:'5px'}} onClick={()=>toggleViewMode('cashbook')}>
                Cash Book
            </Button>
            <Button className='button1' style={{paddingTop:'0px', paddingBottom:'0px', borderRadius:'10px', marginBottom:'5px'}} onClick={()=>toggleViewMode('journal')}>
                Journal
            </Button>
            <Button className='button1' style={{paddingTop:'0px', paddingBottom:'0px', borderRadius:'10px', marginBottom:'5px'}} onClick={()=>toggleViewMode('trialbalance')}>
                Trial Balance
            </Button>
            <Button className='button1' style={{paddingTop:'0px', paddingBottom:'0px', borderRadius:'10px', marginBottom:'5px'}} onClick={()=>toggleViewMode('pandl')}>
                Profit and Loss
            </Button>
            <Button className='button1' style={{paddingTop:'0px', paddingBottom:'0px', borderRadius:'10px', marginBottom:'5px'}} onClick={()=>toggleViewMode('balancesheet')}>
                Balance Sheet
            </Button>
            <Button className='button1' style={{paddingTop:'0px', paddingBottom:'0px', borderRadius:'10px', marginBottom:'5px'}} onClick={()=>toggleViewMode('cashflow')}>
                Cashflow
            </Button>
            <Button className='button1' style={{paddingTop:'0px', paddingBottom:'0px', borderRadius:'10px', marginBottom:'5px'}} onClick={()=>toggleViewMode('ratios')}>
                Ratios
            </Button>
            
            </InputGroup>


            
        </>


    )
}


export default ToolMain
