import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const Disclaimer = ({  }) => {

    var items = [
        {
            type:'paragraph',
            text:"The website/platform, all the content and tools have been provided as an educational tool to help individual and small business understand how to integrate basic accounting and financial principles in their business. Please consult a Professional CA or Finance Expert before you take any important financial decisions and use any of the tools provided in commerce, business or any area where there is a risk of financial loss. Please read the Terms and Conditions page."
        }
    ]


    return (

        <>

        <Card style={{borderRadius:'20px', marginLeft:'20px', marginRight:'20px'}}>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Disclaimer</p>
            </Card.Header> 

            <Section items={items}/> 
        </Card>




        </>


    )
}


export default Disclaimer
