//Cash Credits, Unexplained Investment

var qandas = 
[
{
   question:"What is the rule relating to cash credits in an assessee’s account?",
   items:[
      {
         type:"list",
         list:[
            "Sections 68 to 69D deal with cash credits , investments, expenditure, assets, jewellery, bullion, cash and other financial transactions in the books of account of an assessee which, in the opinion of the assessing officer, seem to be from undisclosed or concealed sources of income.",
            "The assessee offers no explanation or unsatisfactory explanation about the source of income for these credits.",
            "The assessing officer feels the sum credited may be treated as concealed income and should be subject to income tax."
         ]
   },
      {

         text:"In such a case the assessee should : "
      },
      {
         type:"list",
         list:[
" explain to the assessing officer satisfactorily the nature and source of the sum so credited, AND",
" establish that the sum credited is not his income i.e received from somebody else or the credit is out of tax-paid income.",
" If he does not offer any  satisfactory explanation, such sum credited  may be included in the total income of the assessee.",
"It is the duty of the assessee to establish the genuineness of the credits in his books of account,",
" If the money is borrowed from a creditor, the assessee must prove the following: - 1) the identity of the creditor 2) The creditworthiness of the creditor to advance money, 3) The genuineness of the transaction,",
" After the assessee has adduced evidence to establish prima facie the truth of the entry, the onus shifts to the Income Tax Department to prove otherwise."
         ]
      }
   ]
},
{


   question:"When does Section 68 relating to undisclosed sources of funds apply?",
   items:[
      {
         text:"Section 68 comes into operation only when the following conditions are satisfied:"
      },
      {
         list:[
            "the assessee  maintains  books of account for all the financial transactions in the previous year",
            "there should be a credit entry in the books of account of the assessee."
         ]
      }
   ]

},
{

   question:"What are the situations covered by Section 68?",
   items:[
      {
         text:"Some of the situations covered under Section 68 of the Income Tax Act are:"
      },
      {
         type:"list",
         list:[
            "Cash credits in the books of account without convincing explanation documents about the source of funds.",


            "When cheques are not presented for collection, but credited in  the books of account of the assessee.",


            "Share application money is received  by a  company. The company must establish the identity, creditworthiness and genuineness of the subscriber.",


            "Gifts received  by an assessee need to be proved as bonafide." 
         ]
      }
   ]

},
{


   question:"What is the rule relating to credits in books of account in the form of loans / borrowing?",
   items:[
      {
         text:"According to Section 68 and the insertion made therein by the Finance Act 2022 : where the sum credited consists of loan or borrowing or any such amount by whatever name called, any explanation offered by such assessee shall be deemed to be not satisfactory unless : "
      },
      {
         type:"list",
         list:[
             "the person from whom the credit is availed  is also having corresponding entry in his books of account,",
             "he also offers an explanation about the nature and source of such sum loaned ,and ",
             "such explanation in the opinion of the assessing officer has been found to be satisfactory"
         ]
      }
   ]
},
{

   question:"What is the rule relating to unexplained investments?",
   items:[
      {
         text:"Section 69 of the Act deals with unexplained investments."
      },
      {
         type:"list",
         list:[

            "Where, in any Financial Year immediately preceding the previous year, the assessee has made   investments which are not recorded in the books of account maintained by the assessee, and",
            "the assessee offers no explanation about the nature and source of funds for the investments, or",
            " the explanation offered by him for the  investments,  in the opinion of the assessing officer, is not satisfactory,",
            " the value of investments made may be deemed to be the income of the assessee of such financial year, and",
            " the assessing officer may treat the same as concealed income or undisclosed income, and",
            " he may assess accordingly imposing fine, penalty and penal interest apart from  income tax,surcharge and health and education cess.",
            " However the assesses should be given an opportunity of being heard before  the assessment is finalised"
         ]
      }
   ]
},
{

   question:"What are the provisions relating to unexplained bullion, jewellery and other valuables in the possession of an assessee?",
   items:[
      {
         text:"Unexplained  bullion, jewellery and valuable articles etc. are covered under Section 69A."
      },
      {
         type:"list",
         list:[
         "Where in any financial year, an assessee is found to be the owner of any  bullion, jewellery or other valuable article and such bullion or valuable article is not recorded in the books of accounts of the assessee, if any, maintained by the assessee for the source of income or funds for the purchase of the above items, and",
         " the assessee offers no explanation about the nature and source of funds for acquisition of such money, bullion, jewellery or other valuable article, or",
         " the explanation offered by the assessee regarding the source of funds is in the opinion of the assessing officer unsatisfactory,",
         " the bullion, jewellery or other valuable article purchased may be treated as from concealed or undisclosed income, and ",
         "the assessing officer may  assess them as tax evaded income and  accordingly impose fine, penalty, penal interest etc apart from applicable income tax, surcharge and health and education cess.",
         "It is the duty of the assessee to prove that the credits in the books of account maintained by him and the investments made by him in the preceding previous year or the possession of the valuable and precious articles is financed from tax paid income, or",
         " borrowed from reliable genuine sources or gifted by genuine persons or relatives so that it is proved beyond doubt that there is no evasion of tax by the assessee.",
         "Once the assessee has proved beyond doubt that the aforesaid items are  purchased  from  genuine sources i.e. tax paid  funds,  it is the duty of the assessing officer to prove  otherwise.", 
         "It is, also, the duty of the assessing officer to  give an opportunity to the assessee  to be heard  before finalizing the assessment.  "
         ]
      }
   ]
},

{
   question:"What is the rule relating to unexplained expenditure under the Income Tax Act?",
   items:[
   {
      text:"Section 69C of the Act deals with unexplained expenditure."
   },
   {
      type:"list",
      list:[
       "Where,  in any financial year, it has come to the notice of the assessing officer that the assessee has incurred certain expenditure, and",


       " in reply to the  notice of the assessing officer,  the assessee offers no explanation about the source of funds for such expenditure or part of such expenditure, or ",


       " the explanation offered by him, in the opinion of the assessing officer, is not satisfactory, ",


       "the amount covered by such expenditure or part thereof, as the case may be deemed to be, in the opinion of the assessing officer, concealed or undisclosed income of the assessee for such financial year. "
      ]
   },
   {

      text:"Further, notwithstanding anything contained in any other provisions of the Income Tax Act, such expenditure, which is deemed to be from the concealed income of the assessee, shall not be allowed as a deduction under any other provisions of the Act. (Proviso to Section 69C)"
   },
   {
      type:"list",
      list:[
"However, where purchases made by the assessee is supported by bills and receipts from the suppliers, and ",


"the payments were made to the suppliers by account payee cheques or other electronic mode of payments, and",


" the seller also confirmed the transactions, and ",


" the assessing officer has no evidence to show that the amount was recycled back to the assessee, ",


"the assessing officer is  not justified in treating such purchases as bogus under section 69C and treating the same as concealed income."
      ]
   }

   ]
},
{

     question:"What is the rule relating to Hundi payments?",
   items:[
      {
         type:"list",
         list:[
            "According to Section 69D, any amount borrowed  or repaid  through Hundi ((otherwise than through an account payee cheque drawn on a bank or other electronic mode of receipts or  payments) by an assessee,",


            " is not recognised as a genuine transaction for tax purposes, and", 


            "the amount will be treated as income in the hands of the borrower."
         ]
      }
]
}


]

export {qandas}

 //                                  —----------------