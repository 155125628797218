//Assessment of Hindu Undivided Family

var qandas = [

    {
        question:"How is a Hindu Undivided Family assessed under the Income Tax Act?",
        items:[
            {

                type:"list",
                list:[
        "Hindu Undivided Family or HUF is a separate and independent tax entity and assessed separately on the income earned by it.",


        " This income is not taxed in the hands of its members ( to avoid double taxation) unless specifically provided by law.",


        "From the Assessment Year 2021-2022( Previous Year 2020-2021), HUF also has the same option, like individuals, to adopt Section 115 BAC. ",


        "All the conditions applicable to an individual under the new tax regime will be applicable to HUF if the new option of taxation is accepted .",


        "However, any sum received by an individual as a member of a HUF where such sum has been paid out of the family or impartible estate belonging to the family, shall be exempt in the hands of the members of HUF as per Section 10(2).",


        "The liability of the HUF depends upon the residential status of the HUF.",


        "It cannot make any gift of its own property to any of the coparceners or any other person. Any gift made by HUF is ab-initio void",


        "However,the Karta of the family can give a reasonable amount as a gift to any person.",


        " If partition takes place in HUF, there should be a complete partition and no partial partition is recognized by the Income Tax Act.",


        "On partition of HUF, the mother, I.e the wife of Karta  also takes a share equal to the son and daughter. However, they can mutually decide to take equal shares.",


        "As per Section 47 of the Act, no capital gain arises on the distribution of assets on partition of HUF."
                ]

            }
]                                                     
    },
    {
                                                            
        question:"How is the total income of a HUF computed?",
        items:[
            {
                text:"While computing the total income of a HUF, the following points have to be kept in mind:"
            },
            {

                text:"As per Section 64(2), income from the transfer of a self acquired asset of a member of the joint family without adequate consideration or conversion of the same into joint family property shall be treated as the income of HUF. The income will be taxed in the hands of the individual  who transferred  the property to  the HUF."
            },
            {

                text:"Any fee or remuneration received by a member of the HUF as the Director of a company / partner of a firm, which is the result of investment made in such concern out of the funds of HUF, shall be treated as income of the HUF."

            },
            {
                text:"However, if such fee or remuneration is earned by the member as a Director or partner for services rendered by him  purely in his personal capacity / individual contribution, to the business of the concern in which investment is made, it shall be treated as the income of the individual and not of the HUF."
            }
        ]
    },
    {

        question:"What is the rule relating to any remuneration paid to the Karta of a HUF ?",
        items:[
            {
                text:"Any  reasonable remuneration  paid to the Karta of HUF, or any other member of HUF :"
            },
            {
                type:"list",
                list:[
                    "  under a bonafide, valid agreement",
                    "  in the interest of and expedient to the business of the joint family",
                    "  is allowable as expenditure while computing the income of the HUF, and",
                    " such salary shall be taxable in the hands of Karta / member of HUF as his income."
                ]  
}
]
    },
    {


        question:"How is the income of HUF computed ?",
        items:[
            {
                type:"list",
                list:[
        "The gross total income shall be computed under four heads of income like any other person. ",


        "However, there will be no income under the Head ‘Income from salary’ because ‘employees’ can  only be ‘individuals’.",


        "Section 60 to 63 relating to ‘Income of other persons’ is applicable, except Section 64 (income of minor child)which is applicable only in the case of an ‘individual’.",


        "Set off of losses is allowable while aggregating income under various heads of income. ",


        "Carry forward and set off of losses of past years, if permissible, is allowed.",


        "From the gross total income computed after set off, deductions are allowed under Sections  80C to 80U.",


        "The balance income after allowing deductions is the Total Income which will be rounded off to the nearest Rs10.",


        "Tax is computed on such Total Income at prescribed rates of tax.",


        "Add surcharge at: 1) 10% if the total income exceeds more than Rs.50 lakhs and up to 2) Rs 1 Crore; and 3) 15% in the case of HUF having a total income exceeding Rs 1 Crore.",


        "Health and Education cess@4% on the tax plus surcharge, if  surcharge  is levied.",


        "Deduct the TDS, advance tax paid, and double taxation relief under Sections 90, 90A and 91.",


        "The balance is the net tax payable which will be rounded off to the nearest Re.10 and must be paid as self assessment tax along with the return of income filed.",


        "The net amount is the tax payable by the HUF / refund due to HUF."
                ]
            } 
        ]

    },

    {

        question:"Is there a provision for partition of a HUF under the Income Tax Act?",
        items:[
            {
                text:"Yes. A HUF can be partitioned both as regards to persons and as regards to property."
            },
            {
                text:"The partition can be of two types: "
            },
            {
                type:"list",
                list:[
                "Total or complete partition under which all the properties of the HUF are divided among its members. Once the total partition is complete, the HUF ceases to exist.",
 
                " Partial Partition, under which some of the constituents of the HUF go out of the HUF while the others remain. Or, some of the properties of the HUF are divided while the remaining properties remain jointly in the name of the remaining members."
                ]
},
            {
                text:"However, Partial partition after 31.12.1978 is not recognized for tax purposes."
            }    
    ]
    },
    {

        question:"Who are the persons entitled to claim partition of a HUF?",
        items:[
            {
                text:"Partition can be claimed by a member of the family ( Coparcener)."
            }
        ]


    },

    {

        question:"Who are the persons entitled to a share in the assets of a HUF in the event of a partition?",
        items:[
            {
                text:"The following persons are entitled to a share in the assets of  HUF after a partition:"
            },
            {
                type:"list",
                list:[
                    "All coparceners(all members).",


                    "Mother is entitled to a share equal to that of a son if the partition takes place in the case of death of the father.",


                    "The wife gets a share equal to that of a son if the partition takes place between her husband and his sons.This share is separate,  apart from her husband’s share.",


                    "A son in the womb of the mother at the time of partition."
                ]
            }
        ]

    }

]

export {qandas}