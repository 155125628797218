import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';

import Section from '../pieces/Section'

const Cashflow = ({  }) => {


    var items = [
        {
            type:"paragraph",
            text:"Funds in a business is like blood in the human body. If the blood circulation is regular and proper and the heart functions normally, a human being will be active and healthy. If there is a block in the flow of blood, it can adversely affect the health of a person and can even lead to loss of life. "
        },
        {
            type:"paragraph",
            text:"The same analogy is applicable in the case of the flow of funds in an enterprise. A scarcity of funds or a bottleneck in the timely availability of financial resources can end up in closure of business of an organization."
        },
        {
            type:"paragraph",
            text:"A Cash Flow Statement is prepared :"
        },
        {
            type:"list",
            list:[
                "to find out the sources of funds for the business and its application thereof.",
                "to ensure that there has been a smooth flow of funds for operational activities and work was not affected or interrupted due to shortage of funds or non -availability of funds on time.",
               "to find out whether there are any surplus or idle resources in the firm which can be invested temporarily or for a longer period in schemes yielding better returns."
            ]
        },
        {
            type:"paragraph",
            text:"Normally, fixed assets are financed through medium or long term loans from financial institutions and working capital  through commercial banks and creditors."
        },
        {
            type:"paragraph",
            text:"Let us look at an Example of a Cash flow Statement. We are showing you the Cashflow statement of HDFC Bank for the 2021-22"
        },
        {
            type:'table',
            url:"https://www.hdfcbank.com/content/bbp/repositories/723fb80a-2dde-42a3-9793-7ae1be57c87f/?path=/Footer/About%20Us/Investor%20Relation/annual%20reports/pdf/HDFC-Bank-IAR-FY22-21-6-22.pdf",
            title:"Consolidated Cash flow Statement for HDFC Bank , for 21-22 FY, in crore Rs ",
            headers:["", "Year ended, March 31, 2022","Year ended, March 31, 2021"],
            table:[
                ["Cash flows from operating activities:","",""],
                ["Consolidated profit before income tax", "50,775.24", "42,772.58"],
                ["Adjustments for:", "", ""],
                ["Depreciation on fixed assets", "1,680.73", "1,385.01"],
                ["(Profit) / loss on revaluation of investments", "(1,546.40)",  "1,485.32"],
                ["Amortisation of premium on held to maturity investments", "821.32", "765.47"],
                ["(Profit) / loss on sale of fixed assets",  "3.25", "0.29"],
                ["Provision / charge for non performing assets",  "13,286.95", "13,927.01"],
                ["Provision for standard assets and contingencies", "5,418.21", "5,283.07"],
                ["Employee Stock Options Expense", "341.24", "-"],
                ["","70,780.55", "65,618.75"],
                ["Adjustments for:", "",""],
                ["Increase in investments", "(10,849.22)", "(50,156.64)"],
                ["Increase in advances", "(248,946.13)", "(155,681.07)"],
                ["Increase in deposits", "224,282.15", "187,513.74"],
                ["(Increase) / decrease in other assets", "(40,044.70)", "8,307.68"],
                ["Increase / (decrease) in other liabilities and provisions", "7,655.94", "(104.56)"],
                ["","2,878.59", "55,497.90"],
                ["Direct taxes paid (net of refunds)",  "(14,838.16)", "(13,021.45)"],
                ["Net cash flow (used in) / from operating activities", "(11,959.57)","42,476.45"],
                ["Cash flows from investing activities:", "", ""],
                ["Purchase of fixed assets", "(2,236.24)", "(1,696.15)"],
                ["Proceeds from sale of fixed assets", "19.91", "15.28"],
                ["Net cash flow used in investing activities", "(2,216.33)", "(1,680.87)"],
                ["Cash flows from financing activities:", "", ""],
                ["Increase in minority interest", "135.83", "56.12"],
                ["Proceeds from issue of share capital, net of issue expenses", "2,609.76", "1,760.10"],
                ["Proceeds from issue of Additional Tier I and Tier II capital bonds", "8,312.75", "356.50"],
                ["Redemption of Tier II capital bonds", "(3,650.00)", "(1,105.00)"],
                ["Net proceeds / (repayments) in other borrowings", "44,308.08", "(8,389.07)"],
                ["Dividend paid during the year", "(3,592.40)", "-"],
                ["Net cash flow from / (used in) financing activities", "48,124.02", "(7,321.35)"],
                ["Effect of exchange fluctuation on translation reserve", "165.10", "(141.83)"],
                ["Net increase in cash and cash equivalents", "34,113.22", "33,332.40"],
                ["Cash and cash equivalents as at April 1st", "121,272.51", "87,940.11"],
                ["Cash and cash equivalents as at March 31st", "155,385.73", "121,272.51"]
            ]

        }
    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Cashflow Statement</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>

        </>


    )
}


export default Cashflow
