//Tax Refunds

var qandas = [

  {
  question:"When is an assessee entitled to a tax refund?",
    items:[
      {
        text:"As per Section 237, an assessee is entitled to a tax refund when he has : "
      },
      {
        type:"list",
        list:[
          "Submitted his tax return",
          "Paid  tax  in excess of what is chargeable",
          "Satisfied the assessing officer that a refund is due"
        ]
      },
      {
        text:"Such refund is to be granted by the assessing officer on his own."
      },
      {
        text:"The assessee is not required to file any claim for such refund."
      }

    ]
  },
{
  question:"When can an assessee claim refund?",
  items:[
    {
      text:"If a person is not liable to pay income tax but tax has been deducted at source from his income, a claim for refund can be submitted through Form 30. "
    },
    {
      text:"A return of income must also be furnished along with the tax deduction certificate."
    }  
]

},
{

  question:"Who, apart from an assessee, can claim a tax refund?",
  items:[
    {
      text:"As per Section 238 of the Income Tax Act, persons other than an assessee, can also submit a claim for tax refund."
    },
    {
      type:"list",
      list:[
          "If the income of one person is included  in the income of another  as per the provisions of the Act, only the second person can claim a refund.",


          "If the value of non-monetary benefits provided by one employer is included in the fringe benefits provided by another employer, only the latter can claim a refund in this connection.",


          "If a person is unable to claim a tax refund due to death, insolvency, liquidation or any other cause, his legal representative can claim the tax refund on his behalf."
      ]
    }
  ]
},
{

 question:"What is the rule relating to refund on appeal?",
    items:[
      {
      text:"As per Section 240 of the Income Tax Act, when a refund has become due, on an appeal, revision etc, the assessing officer has to refund the excess tax paid on his own accord without any claim being made again by the assessee."
      }
      ]
},
{

  question:"Can a final assessment order be questioned for correctness?",
  items:[
    {
      text:"No."
    },
    {
      text:"Section 242 lays down that a final, conclusive assessment order cannot be questioned on grounds of correctness."
    },
    {
      text:"Only refund of excess tax paid is possible."
    }
  ]
},
{

  question:"Can a tax refund be adjusted against any unpaid tax arrears?",
  items:[
    {
      text:"Yes."
    },
    {   
      text:"If there is any amount outstanding by way of tax, interest or penalty against an assessee, then the assessing officer can adjust any tax refund due to the same person, against it."
    }
    ]
  }
]




export {qandas}