import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {projectlistReducer, projectDetailReducer} from './reducers/projectReducers'

import {tableDetailReducer, tableListReducer, expenseTableReducer, incomeTableReducer, inventoryTableReducer, expenseBizTableReducer} from './reducers/tablesReducers'

import {plotlistReducer, selectedplotReducer} from './reducers/plotReducers'

import {formulalistReducer, selectedformulaReducer} from './reducers/formulaReducer'

import {currentAssetsReducer} from './reducers/balanceSheetReducers'

import {selectionReducer, selectionToggleReducer } from './reducers/selectionReducers'

const reducer = combineReducers({


    tableDetail: tableDetailReducer,
    plotlist: plotlistReducer,
    formulalist: formulalistReducer,
    selectedformula:selectedformulaReducer,

    selectionR: selectionReducer,
    selectionToggleR: selectionToggleReducer,

    projectlist: projectlistReducer,
    projectDetail: projectDetailReducer,

    expenseTable: expenseTableReducer,
    incomeTable: incomeTableReducer,
    inventoryTable: inventoryTableReducer,

    currentAssetsR: currentAssetsReducer,

    expenseBizTable: expenseBizTableReducer,




})


const expensesFromStorage = sessionStorage.getItem('expenses') ? JSON.parse(sessionStorage.getItem('expenses')) : []
const incomesFromStorage = sessionStorage.getItem('incomes') ? JSON.parse(sessionStorage.getItem('incomes')) : []
const inventoryFromStorage = sessionStorage.getItem('inventory') ? JSON.parse(sessionStorage.getItem('inventory')) : []

const expensesBizFromStorage = sessionStorage.getItem('expensesbiz') ? JSON.parse(sessionStorage.getItem('expensesbiz')) : []

const initialState = {
    expenseTable: { expenses: expensesFromStorage },
    incomeTable: { incomes: incomesFromStorage },
    inventoryTable: { inventory: inventoryFromStorage},

    expenseBizTable: { expenses: expensesBizFromStorage },
} 

const middleware = [thunk]
const store = createStore(
    reducer, 
    initialState, 
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store