import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const Assetsandliabilities = ({  }) => {

    var items = [
        {
            type:'paragraph',
            text:"Let us now familiarize ourselves with a few terms which we will come across in the course of our accounting journey."
        },
        {
            type:'header',
            text:"Assets"
        },
        {
            type:'paragraph',
            text:"Assets are resources owned by an individual or enterprise which command an economic value. It is beneficial for people and business units to increase their assets because economic benefit can be derived from them in the long run."
        },
        {
            type:'paragraph',
            text:"On the basis of liquidity, Assets can be classified into two : "
        },
        {
            type:'list',
            list:[
                "Fixed Assets / Non-Current assets",
                "Current Assets"
            ]
        },
        {
            type:'header',
            text:"Fixed Assets"
        },
        {
            type:'paragraph',
            text:"Fixed Assets or Non-Current assets are owned by people or business enterprises to be held and used for a long period of time. They are not expected to be consumed or converted into cash in the short term, i.e. within less than a year. They are very vital in the production of goods and services and the running of the business."
        },
        {
            type:'paragraph',
            text:"On the basis of their nature of existence, Fixed Assets can be classified into Tangible Assets and Intangible Assets."
        },
        {
            type:'paragraph',
            text:"Fixed Tangible Assets are those assets which exist in physical form and can, therefore, be perceived by our senses. They include land, building, plant & machinery, office equipment, furniture & fixtures, vehicles etc."
        },
        {
            type:'paragraph',
            text:"Fixed Intangible Assets do not have a physical form. We cannot see or touch them. They include goodwill, patents, copyrights, trademarks, etc. which add considerable value to business."
        },
        {
            type:'header',
            text:"Current Assets"
        },
        {
            type:'paragraph',
            text:'Current assets are liquid assets which can, in the normal course, be expected to be consumed or converted into cash in the short term, i.e. within one year. They include cash on hand, bank balances, stock of finished goods, raw materials, stores & spares, fuel etc.'
        },
        {
            type:'header',
            text:'Liabilities'
        },
        {
            type:'paragraph',
            text:"Liabilities are financial obligations which individuals or companies have to meet in the course of their activities. On the basis of time, liabilities are divided into :"
        },
        {
            type:'list',
            list:["Current Liabilities", "Non-Current / Long Term Liabilities."]
        },
        {
            type:'paragraph',
            text:"Current Liabilities are the short term financial obligations and debts which arise during the course of business and have to be paid or settled within one year. They include pending bills, payable interest, deposits received from suppliers etc."
        },
        {
            type:'paragraph',
            text:"Non-Current or Long Term Liabilities are the debts and financial obligations which are repayable in the long term, i.e. in more than one year. They comprise of long term sources of funding like mortgage, lease, bonds etc. Next, we will learn about the different kinds of Journals, Registers and Ledgers which every business entity needs to maintain."
        }
    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Assets and Liabilities</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default Assetsandliabilities
