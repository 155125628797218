//Deductions allowed on Total Income for payments made

var qandas = [
{
  question:"What is meant by the term ‘Gross Total Income’?",
  items:[
  {
    text:"The aggregate of income computed under each Head of income after giving effect to the provisions of clubbing of income and set off of losses is known as ‘Gross Total Income’."
  }
  ]
},
{
  question:"What are the deductions allowed on Gross Total Income ?",
  items:[
    {
      text:"While computing the total income of an assessee, there are certain deductions allowed from the gross total income under Section 80C to 80U of the IncomeTax Act to arrive at the net total income or taxable income. The taxable income is rounded off to the nearest Rs 10."
    },
    {

      text:"These deductions allowed on Gross Total Income are of two types:"
    },
    {
      type:"list",
      list:[
      "Deductions on account of payments and investments covered under Sections 80C to 80GGC.",
      "Deductions on account of certain incomes already included in the ‘gross total Income’ which are  covered  under Sections 80-IA to 80U."
      ]
    }
  ]

},
{

 question:"Which are the incomes on which deductions under Section 80C to 80U are not allowed?",
  items:[
    {
      text:"The deductions under Section 80C to 80 U are not allowed from the following incomes though these form part of gross total income:"
    },
    {
      type:"list",
      list:[
        "Long term capital gains.",


        "Short term capital gains on transfer of equity shares / units of equity oriented fund / units of business trust transacted through a recognized stock exchange.",


        "Winnings from lottery crossword puzzles, card games, etc.",


        "Income of Non –Residents taxed at special rates"
      ]
    },
    {
      text:"It is to be noted that those  assessees who opt for the new tax regime introduced in the Finance Act 2020 will not be entitled for deduction under Sections 80A to 80U ( with a few exceptions)."
    }
  ]
},
{

  question:"What are the basic rules for deductions on Gross Total Income?",
  items:[
    {
      text:"Sections 80A / 80AB / 80AC lay down the basic rules for deductions. "
    },
  {
    text:"Some of these rules are:"
  },
  {
    type:"list",
    list:[
      " Deductions under Section 80 should not  exceed the Gross Total Income, [Section 80A (2)].",


      "The aggregate of deductions under Sections 80C to 80U shall not exceed the gross total income (exclusive of the incomes not included in the gross total income for deductions under Sections 80C To 80U. Therefore, the total income after all the deductions will either be positive or nil, but never negative.",


      " If the gross total income is negative or nil, no deduction shall be permitted under this chapter.",


      "Double deduction is not allowed and deduction cannot exceed the profit of a particular undertaking or  enterprise. [Section 80A (4)]",


      " Deduction is allowed only when it is claimed by the assessee.[Section 80A (5)]",


      "Where the assessee fails to make a claim in his return of income for any deduction under Section 10AA or under Sections 80-IA to 80 RRB, no deduction shall be allowed to him under the same sections.",


      " It is the assessee’s duty to place all the relevant materials before the assessing officer to claim the deduction.",


      "Benefit of certain deductions not to be allowed in certain cases where return is not filed  before the due date for filing the return of income.[Section 80(AC)]."
  
    ]
}
]
}

]

export {qandas}


//                                  —-------------------------------
