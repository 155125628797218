//Income From House or Property

var qandas = [
{
    question:"What is the rule regarding Income Tax on income from house property?",
    items:[
    {
        text:"Income Tax is payable on the income earned from house property let out during the previous year."
    },
    {
        text:"For those who own more than two houses, income tax is payable on the notional income of more than two houses owned by the assessee. There is no income tax on the income of two houses supposed to be used for one’s residence whether used for one’s residence or not, and the assessee can choose these two houses of his wish.[Section 22] Income Tax Act"
    },
    {
        text:"The Income Tax is calculated on the annual value of the Property, i.e., in the common person’s language, the rent received or receivable from the Property he let out for the Previous Year. "
    }
    ]
},

{
    question:"What is ‘Residential Property’ ?",
    items:[
        {
            text:"Residential property means the building which is used for residence and not for other purposes."
        }
    ]
},
{
    question:"What is meant by Annual Value of Property?",
    items:[
        {
            text:"As per the Income Tax Act, the Annual Value of the property for computation of income tax is the sum for which the property must reasonably be expected to be let from year to year in that area."
        },
        {
            text:"The rent received from the building or the land adjacent to or surrounding the building is charged under the Head ‘Income from house property’."
        } 
    ]
},

{
    question:"What is the meaning of ‘Deemed ownership’ ?",
    items:[
        {
            text:"Deemed ownership.[Section 27]"
        },
        {
            text:"In the following cases, though the Property is in the name of other persons, the ownership is deemed with the original owner. Therefore, the income from house property is taxed alongwith the income of the original owner : "
        },
        {
            type:"list",
            list:[        
                "Transfer to a spouse.[Section 27(i)] : The Property transferred to a spouse only, with adequate consideration, if there is an agreement between the two to live apart, is included in the transferor’s income.",
                "Transfer to a minor child : Property transferred to a minor child without adequate consideration is regarded as deemed ownership unless the minor is a married daughter.",
                "Holder of an impartible estate.[Section 27(ii)] : An individual owns the Property in the estate, which is not divisible.",
                "A member of a housing society where the member is a part owner of the building through the ownership belongs to the society.[Section 27(iii)]",
                "A person is allowed to take possession of the building or part thereof in part performance of a contract referred to in Section 53A of the Transfer of Property Act, 1882. [Section 27(iii)}",
                "Person has had a property right for more than 12 years. A person with property rights under a lease for 12 years is deemed the property owner unless the lease is from month to month or a period not exceeding 12 months.[Section 27(iiib)",
                "Suppose the assessee uses the building for his own business or profession. In that case, the annual value of the Property will be included under the Head ‘Profits and Gains of Business or Profession’."
            ]
        }
    ]
},
{
    question:"How is the Annual Value of a Property computed?",
    items:[
        {
            text:"Annual value of Property.[Section 23(i)]"
        },
        {
            text:"The annual value of a house property, for computation of income tax, is taken as the highest of the following amounts:"
        },
        {
            type:"list",
            list:[
                "The actual rent received or receivable,",
                "The Municipal valuation, ",
                "The fair rent, i.e., the rent prevailing in the area where the property is located",
                "The standard rent, i.e., the rent fixed by the Rent Control Authority or any Statutory Authority that can set the rent.",
                "It is to be noted that the fair rent should not exceed the standard rent of the Property. Therefore, the annual value should be the actual rent received or the Municipal valuation or the standard rent, whichever is higher."
            ]
        }
    ]
},  
{
    question:"What are the different situations while computing the Annual Value of a house?",
    items:[
        {
            text:"There are six scenarios while computing the annual value of a house property in any previous year.",
        },
        {
            type:"list",
            list:[  
                "House property which is let out throughout the year.",
                "House property which is used for one’s own residence throughout the year.",
                "Part of the house property is used for residence, and the rest is let out throughout the year.",
                "House property used part of the year for own residence and part of the year let out.",
                "House property let out for part of the year, and part of the year remained vacant.",
                "The house property which is vacant throughout the year."
            ]
        }
    ]
},


{
    question:"How is the tax on property calculated?",
    items:[
        {
            text:"The Income Tax Act, as amended by the Finance (No2)Bill of 2019, exempted two houses as one’s residence, and if a person owns more than two houses, he should pay tax on the notional income on more than two houses whether the properties are let out or not. But the assessee can choose any two houses of his will and wish."
        },
        {
            type:"list",
            list:[        
    "House property is let out throughout the year. The annual value for the entire year will be taken as income for the tax computation. The actual rent received or the fair rent for the whole year, whichever is higher, will be taken as the income from the house property.",
    "House property used for one’s residence throughout the year. No income tax is payable on the house property used for its residents as the annual value is nil.",
    "House property partly used for own residence and partly let out throughout the year. The municipal valuation or the standard rent of the portion of the house property let out throughout the year, or the actual rent received on the part let out, whichever is higher, will be the annual value of the house property for computation of income tax.",
    "If the house/property is used for one’s residence for part of the year and the remaining period of the previous year remained vacant, the annual value of the Property for the entire year will be treated as income from house property chargeable to income tax provided the owner owns more than two houses. ",
    "If the house property was rented out for part of the year, and the remaining period was vacant, the annual value of the Property for the entire year or the actual rent received for the period rented out, whichever is higher, will be taken as the yearly value for the computation of income tax.",
    "If the house property remained vacant throughout the year, since the assessee is allowed to keep two houses for one’s residence as per the Act, he has to pay notional tax for the previous year on more than the two houses he owned, even if they are vacant throughout the year."
    ]
        }
    ]
},

{
    question:"What are the deductions allowed from the Annual Value of the Property which is let out ?",
    items:[
        {
            text:"In the case of house property let out, the following deductions are allowed:-"
        },
        {
            type:"list",
            list:[
            "The property tax and land rent are allowed as a deduction from the gross annual value to arrive at the net annual value.",
            "Standard deduction of 30% of the net annual value is allowed for maintenance, wear and tear, etc., for house property that is let out.",
            "Interest paid or payable on the loan taken for buying or constructing a house property is allowed as a deduction from the net annual value of the previous year if the house property is rented during the Previous Year."
            ]
        }
    ]
},

{
    questions:"What are the deductions allowed in the case of house property used as  one’s own residence ?",
    items:[
        {
            text:"In the case of house property used for own residence, the following deductions are allowed :",
        },
        {
            type:"list",
            list:[

            "The interest on the loan for buying or constructing a house property used for one’s residence is allowed as a deduction in the previous year up to a maximum of Rs 2,00,000 in any prior year. ",
            "The loss under this head can be set off from any other source of income up to a maximum of Rs 2,00,000 in any previous year. The loss for any prior year after setting off Rs 2,00,000 against any other source of income can be carried forward for set off against only income from house property for the next eight years.",
            "Similarly, interest up to Rs30,000 is allowed as a deduction in the case of a loan taken for the renovation of the house used for one’s own residence.",
            "However, in a case where the owner keeps two houses for his residence, the aggregate deduction allowed for interest for two residential dwellings will be Rs 2,00,000 for new homes and for renovation Rs.30, 000 only."
            ]
        }
    ]
},

{
    question:"What is the rule regarding Interest on money borrowed from abroad for buying / constructing house property?",
    items:[
        {
            text:"Interest on money borrowed from abroad for buying or constructing the house property is not deductible from the income from the house property.[Section 25]."
        },
        {
            text:"Interest payable on money borrowed from outside India shall not be allowed as a deduction under Section 24(b) unless the tax on the same has been paid or deducted at source and in respect of which no person in India may be treated as the agent of the recipient for such purpose."
        },
        {
            text:"In the case of first-time buyers of residential house property, a deduction up to a maximum amount of Rs 1, 50,000 or the actual interest paid, whichever is less, is allowed as an additional deduction subject to the following:-[Section 80 EEA]"
        },
        {
            type:"list",
            list:[
            "The stamp duty value of the house property should not exceed more than Rs.45 Lakhs.",
            "The loan should be sanctioned from 1/4/2019 to 31 /3 /2022.",
            "The interest allowed is a maximum of Rs1,50,000 per annum from the previous year, 1/4/2019, and will be continued in the subsequent years."
            ]
        }
]

},




{
    question:"Is unrealized rent allowed as a deduction from the Annual Value ?",
    items:[
    {
        text:"Unrealized rent is allowed as a deduction from the annual value provided:-"
    },
    {
        type:"list",
        list:[
    "The unrealized rent must have been taken as income in the previous years,",
    "The tenant who defaulted on the rent should have vacated the house property when claiming the deduction of unrealized rent.",
    "The tenant should not occupy any other house property of the owner.",
    "All legal steps have been taken to recover the rent arrears. Therefore, the assessee believes further expenditure on recovering the rent arrears is a waste."
        ]
    },
    {
        text:"The assessing officer should be convinced of the explanation given by the house property owner."
    }
    ]
},


{
    question:"What are the special provisions for arrears of rent and unrealized rent received subsequently ?",
    items:[
        {
            text:"According to Section 25A, the arrears of rent and unrealized rent received subsequently be taxed under the Head ‘Income from the house property’ in the financial year it is received. Therefore, they shall be included in the total income whether the assessee is the owner in that previous year or not."
        },
        {
            text:"A standard deduction of 30% will be allowed from the arrears or unrealized rent received."
        }
    ]
},

{
    question:"Can the net annual value of the house property be negative?",
    items:[
        {
            text:"The annual value of the house property can be negative only when the municipal taxes or the land rent paid by the owner is more than the gross annual value."
        }
    ]
}

]

export {qandas}

//—-----------------------------------------------------------------