import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';

import Section from '../pieces/Section'

import ToolMain from '../tool/ToolMain'


const Casestudy = ({  }) => {

    var items = [
        {
            type:'paragraph',
            text:"The best way to learn accounting is to take up a case-study step by step."
        },
        {
            type:'paragraph',
            text:"Let us introduce a case here for XYZ Private Limited, a small proprietary concern which sells stationery products. Let us assume the shop started its operations on 01/04/2021."
        },
        {
            type:'paragraph',
            text:"We will look at how to :"
        },
        {
            type:'list',
            list:[
                "handle transactions and journal entries",
                "update the Ledger based on the transactions",
                "maintain a Cash Book and a Bank Book",
                "compute the Trial Balance to ensure that the accounting is correct",
                "use this Trial Balance to write a Profit and Loss Statement for the Company",
                "prepare a Balance Sheet for the Company",
                "and, finally, compute Accounting Ratios based on the Books of Accounts.",
                
            ]
        },
        {
            type:'paragraph',
            text:"These parameters will throw light on the financial state and health of the company."
        },
    ]

    var books = {}


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Case Study</p>
            </Card.Header>  
        </Card>


        <Section items={items}/>

        <ToolMain books={books}/>


        </>


    )
}


export default Casestudy
