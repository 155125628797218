//Procedure for Tax Assessment

var qandas = [

    {
        question:"How is the tax return submitted by an assessee, processed by the tax authorities?",
        items:[
            {
                text:"According to the provisions of Section 143(1) of the Income Tax Act, the return submitted under Section 139(1) or Section 142(1) should be processed thus:"                        
            },
            {
                text:"The total income or loss should be computed after making adjustments for : "
            },
            {
                type:"list",
                list:[
                    " Any arithmetical error in the return",
                    " An incorrect claim, if apparent from any submitted information ",
                    " Disallowance of loss claimed, if the return of the previous year for which such set off loss is claimed, was not furnished at least one month before the due date for filing the return of income under Section 139(1), ",
                    " Disallowance of increase in income  or expenditure indicated in the audit report, if it was not taken into account  in the return of income furnished. This clause of Section 143(1)(a) has been amended in the Finance Act 2021 to allow for adjustment on account of increase in income indicated in the audit report, but not taken into account in computing the taxable income.",


                    " Disallowance of deduction claimed under Sections 10AA, 80-1A, 80–IAB, 80-IB, 80-IC, 80-ID or Section80-IE, if the return of income was not submitted at least one month before the due date for filing the return of income under Section 139(1),or",
                    " Addition of income appearing in Form No 26AS, or Form No 16A or Form No 16 which has not been included in the  return of income submitted."
                ]    
        }    
    ]    

},
    {

        question:"What details are included in the assessment order issued by the tax authority?",
        items:[
            {
                text:"Apart from income tax, the assessment order must include any interest, fine, penalty etc payable by the assessee for any offense committed under the Act. "
            }
        ]
},
    {

        question:"What is the ‘net tax payable’ by an assessee after the processing of the income tax return?",
        items:[
            {
                text:"The net tax payable by an assessee consists of:"
            },
            {
                type:"list",
                list:[
                    "the income tax +surcharge + health and education cess +  interest, fine and penalty after adjustment of ",
                    " the tax deducted at source / tax collected at source / advance tax paid / adjustment of tax credits / tax paid on self assessment etc. "
             ]
            }
        ]
    },
    {

        question:"What is the process of intimation of tax payable to the assessee by the tax authorities?",
        items:[
                {
                    text:"The Income Tax Act has laid down a clear procedure to intimate the assessee once the tax authority has processed the tax returns filed by the assessee, "
                },
                {
                    type:"list",
                    list:[    
                        " An intimation shall be prepared / generated by the assessing officer and sent to the assessee specifying the amount payable / the amount of refund due to him.",


                        " The amount of refund due to the assessee should be paid immediately.",


                        " If there is a delay in payment of refund, interest should be paid on the refund from the date of assessment till the date of payment.",


                        " An intimation shall also be sent to the assessee where the loss returned by the assessee is adjusted and no tax / interest / fine is payable by the assessee and no refund is due to him.",


                        " If no refund is due to the assessee, or no tax is payable by him, the acknowledgement of return is treated as the intimation for the final assessment .",


                        " No intimation shall be sent to the assessee by the assessing officer after the expiry of nine months from the end of the financial year for which the return is made."
                    ]       
        }
        ]

    },
    {



    question:"In what situation is ‘compulsory service of notice’ applicable ?",
    items:[
        {
            question:"Section 143(2) deals with the situation under which an assessing officer / tax authority feels it necessary to issue  notice to an assessee who has filed his tax return under Section 139 or Section 142(1)."
        },
        {

            question:"This is done to ensure that:"
        },
        {
            type:"list",
            list:[
                "The assessee has not understated his income.",
                " He has not calculated excessive loss ",
                " Has not under-paid the tax in any manner"
            ]

        },
        {

            text:"The notice requires the assessee :"
        },
        {
            type:"list",
            list:[
                " To come to the office of the assessing officer on a particular date",
                " To produce any evidence to support the details given in the tax return"
            ]
        },
        {

            text:"However, w.e.f Assessment Year 2021-2022, no notice shall be served on the assessee after the expiry of three months from the end of the year in which the return is furnished."
        }

]
    },
    {


        question:"What are the consequences if an assessee fails to comply with the notice served under section 143(2)?",
        items:[
            {
                text:"Failure to comply with the notice served by an assessing officer can lead to:"
            },
            {
                type:"list",
                list:[
                 " Ex-parte Best Judgement Assessment under Section 144 ",
                 " Penalty under Section 271 (1)(b)i.e. Rs 10,000."
                ]
            }
        ]
    },
    {


        question:"Is it necessary to make a claim in the tax return for a deduction to be allowed?",
        items:[
            {
                text:"Yes."
            },
            {
                text:"As per Section 80A(5), tax deduction will be allowed only if a specific claim is made in the return of income under: "
            },
            {
                type:"list",
                list:[
                    "Section 10A or Section 10AA or Section 10B or Section 10BA",
                    "Sections 80-1A to 80 RRB."
                ]
            }    
    ]

    },
    {



        question:"What are the new provisions laid down for Scrutiny assessment ? ",
        itens:[
            {
                text:"As per Section 143(3A)/ (3B)/ (3C), the Central Government may make a scheme to make the tax assessment procedure more efficient, transparent and accountable."
            },
            {
                text:"This can be done by: "
            },
            {
                type:"list",
                list:[
                    " The elimination of the interface between the assessing officer and the assessee to the extent feasible technologically.",
                    " Optimizing the utilization of available resources through economies of scale and functional specialization.",
                    " Introducing a team based assessment with dynamic jurisdiction."
                ]    
        }
        ]
    },
    {


        question:"What is meant by ‘Best Judgement Assessment’?",
        items:[
            {
            text:"The ‘Best Judgement Assessment’ is done by a tax officer when, in certain cases, he is obliged to make an assessment of the total income / loss of the assessee, to the best of his judgement, on the basis of all the relevant material he has collected"
            }
        ]
    },
    {


        question:"In which situation does a tax assessing officer make a ‘Best Judgment Assessment?",
        items:[
            {
                text:"Section 144 outlines the conditions under which the tax assessing officer takes recourse to the ‘Best Judgement Assessment’."
            },
            {
                type:"list",
                list:[
                    " Where any person fails to make the return under Section 139(1) and has not made a return or a revised return under Section 139(4) or Section 139(5),",
                    " Where any person fails to comply with all the terms of a notice issued under Section142 (1) or the directions issued under Section 142(2A) for getting the accounts audited.",
                    " Where any person having made a return fails to comply with all the terms of a notice issued under Section 143 (2)."
                ]
            },
            {
                text:"The best judgment assessment can only be made after giving the assessee an opportunity of being heard, by giving him notice to show  cause as to why the assessment should not be completed under Section144."
            },
            {  
                text:"However, this notice  is not necessary if a notice under Section 142(1) has already been served before making the best judgement assessment."
            }    
    ]
    },
    {

        question:"Can books of account be rejected by an assessing officer ?",
        items:[
            {

                text:"As per Section 145(3), an assessing officer can reject the books of account which are unreliable,  incorrect or incomplete and make the best judgement assessment under Section 144. "
            },
            {

                text:"The tax official can do so, IF:"
            },
            {
                type:"list",
                list:[
                    "he is not satisfied about the correctness or completeness of the accounts of the assessee.",


                    "the method of accounting adopted by the assessee is such that the total income / profits cannot be computed correctly even though the accounts of the assessee are correct / complete.",


                    "the method of accounting used by the assessee has not been followed uniformly by him.",


                    "the income has not been computed in accordance with the Income Computation and  Disclosure Standards notified by the Central Government under Section 145(2)."
                ]
            }    
    ]

    },
    {


        question:"What is meant by ‘Faceless Assessment’?",
        items:[
            {
                text:"The provision for ‘Faceless Assessment’ has been laid down in Section 144B and is applicable w.e.f.1.4.2021."
            },
            {
                text:"It is a procedure wherein : "
            },
            {
                type:"list",
                list:[
                    "Assessees are spared the trouble of going to tax authority offices in connection with their tax returns. ",
                    "There is no direct interaction between the taxpayer and the tax authorities.",
                    "Tax related queries are settled through streamlined online processes.",
                    "The tax system becomes more efficient, convenient and transparent",
                    "A coordinated system of Regional Faceless Assessment Centres and Assessment Units with a National Faceless Assessment Centre at the top have been set up for this purpose."
                ]
            }
        ]
    },
    {


    question:"What are the functions of the Faceless Assessment Units ?",
    items:[
        {
            text:"The functions of the Faceless Assessment Units are: "
        },
        {
            type:"list",
            list:[
                " To identify relevant points / issues for the determination of any liability (including refund) under the Act",
                " To seek information or clarification on the points / issues identified",
                " To analyze the material furnished by the assessee or any other person",
                " Such other functions as may be required for the purpose of making faceless assessment"
        ]    
    }
    ]
    },
    {

    question:"What is the need for the Verification Units with regard to faceless assessment?",
    items:[
        {
            text:"Faceless assessment of tax returns calls for  a number of  procedures to ensure efficiency and accuracy."
        },
        {
            text:"These functions are : "
        },
        {
            type:"list",
            list:[
                " enquiry",
                " cross verification of details",
                " examination of books of account,",
                " examination of witnesses",
                " recording of statements",
                " any other functions required for verification"
            ]
        },
        {
            text:"Verification Units are set up for the purpose of fulfilling these functions [Section 144B(3)]. "
        }
        ]
    },
    {

    question:"What is the importance of Technical Units in the field of faceless assessment of tax returns? ",
    items:[
        {
            text:"The process of faceless assessment of tax returns involves a number of technical, legal and other vital factors. The tax authorities, therefore, need to have access to advice / assistance in fields like:" 
        },
        {
            type:"list",
            list:[
                "legal,",
                "accounting,",
                "forensic,",
                "information technology,",
                "valuation,",
                "transfer pricing,",
                "data analysis,",
                "management",
                "any other technical matter deemed necessary"
            ]
        },
        {
            text:"It is for this purpose that Technical Units have to be set up.[Section 144B(3)]"
        }
    ]
    },
    {

        question:"What is the role of Review Units under the Faceless Assessment scheme?",
        list:[
            {
                text:"There is no direct contact between the taxpayer and the tax authorities under the Faceless Assessment scheme. It is, therefore, important to have a proper system to thoroughly check and review the assessment drafts before the assessment orders are sent to the assessees."
            },
            {
                text:"The Review Units ensure that:"
            },
            {
                type:"list",
                list:[
                    "the relevant  material evidence has been brought on record,",
                    "the relevant points of fact and law have been duly incorporated in the draft order,",
                    "the applicable judicial decisions have been considered and dealt with in the draft order;",
                    " arithmetical calculations are correct",
                    "any other function required for review has been carried out."
                ]
            }
        ]
    },
    {

        question:"How is the tax assessment order in the case of Faceless Assessment sent to the assessees?",
        items:[
            {
                text:"The tax authority has a well defined way to send the tax assessment order to an assessee in the case of Faceless Assessment."
            },
            {

                text:"An authenticated copy of every notice, order or any other communication has to be sent to the assessee’s: "
            },
            {
                type:"list",
                list:[
                " registered account",
                " registered email address OR",
                " mobile App, followed by an Alert OR",
                " registered email address of the assessee’s representative, followed by an alert"
                ]          
}        
]
    },
    {

        question:"How can an assessee file his response to any notice / order / communication sent by the tax authority in the case of Faceless Assessment of tax return?",
        items:[
            {
                text:"The mode of response to any communication received from the tax authority in the case of Faceless Assessment has been laid out in Section 144B(7)."
            },
            {
                text:"The assessee has to respond to any notice / order / any other electronic  communication through his registered account." 
            },
            {
                type:"list",
                list:[
                    "On the successful submission of response, the National Faceless Assessment Centre (NFAC) will send an acknowledgement which contains the generated hash result.",


                    "The response submitted by the assessee is then deemed to be authenticated.",


                    "The time and place of dispatch and receipt of electronic record shall be determined in accordance with the provisions of Section 13 of the Information Technology Act, 2000.",


                    "The assessee shall not be required to appear either personally or through his authorized representative in connection with any proceedings before the Income Tax Authority at the National Faceless Assessment Centre or Regional Faceless Assessment Centre or any unit set up under this subsection."
                ]
            }
        ]
    },
    {

        question:"What is the procedure if the tax authority detects any income of an assessee which has escaped assessment ?",
        items:[
            {
                text:"Section 147 lays down the rule to deal with a situation where an assessing officer feels that any income of the assessee which was tax liable has escaped tax for any assessment year."
            },
            {   
                text:"Subject to the provisions of Sections 148 to 153, the assessing officer can: "
            },
            {
                type:"list",
                list:[
                    "Assess or reassess the income which he feels has escaped assessment,",


                    "Re-calculate the loss / depreciation allowance / any other allowance  for the relevant assessment year.",


                    "If, during such reassessment under Section 147, the assessing officer finds any other income chargeable to tax which has also escaped assessment, he can assess or reassess such income also."
                ]
}

    ]

    },
    {


    question:"What are the situations in which income is said to have ‘escaped assessment’?",
    items:[
        {
            text:"According to Explanation 2 of Section 147, income is deemed to have escaped assessment in the following situations:"
        },
        {
            type:"list",
            list:[
                " No return has been filed by an assessee and no assessment has been made on his total income or the total income of any other person in respect of which he is assessable under the Act though the income is above the maximum amount which is not chargeable to tax in any previous year.",


                " A return has been furnished by the assessee but no assessment has been made and the assessing officer notices that the assessee has understated the income or claimed excessive loss / deduction / allowance / relief in the return.",


                " The assessee has not furnished a report in respect of any international transaction  or specified domestic transaction which he is required to do under Section 92E.",


                "  Assessment under Section 143 (3) or Section 144 has been made, but "
            ]   
    },
        {   
            text:"the income chargeable to tax has been under assessed / assessed at a lower rate / excessive loss or depreciation allowance has been calculated."
        }
    ]

    },
    {

        question:"Is there any provision for rectification of mistakes committed by the tax authority?",
        items:[
        {
            text:"Yes. Section 154 of the Income Tax Act lays down provisions for rectification in case any mistake is committed by the tax authority while passing any order of assessment, appeal, refund, revision etc."
        },
        {

            text:"As per Section 154(1), rectification is allowed when: "
        },
        {
            type:"list",
            list:[
                " a mistake is apparent from the record.",
                " rectification can be done by the authority who passed the order."
            ]
        }    
    ]

    },
    {

        question:"What are the mistakes the tax authority is authorized to rectify under the Income Tax Act?",
        items:[
            {
                text:"The tax authority is empowered to rectify its mistakes in connection with : "
            },
            {
                type:"list",
                list:[
                    "any order of assessment or refund or any other order passed by him.",


                    "any intimation or deemed intimation under Section 143(1).",


                    " any intimation sent to assessees.",


                    "processing of the statement of tax deducted at source under Section 200A(1).",
 
                    " processing of TCS statements [Section 206CB(1)].",


                    "any order passed by the Commissioner in revision under Section 263 or Section 264.",


                    "any order passed by the Commissioner ( Appeals) under Section 250.",


                    " any order passed by other tax authorities under Section 116."
                ]
            }
        ]

    },
    {

        question:"Who can initiate rectifications in the case of mistakes made by tax assessing authorities?",
        items:[
{
    text:"As per Section 154(2), the Income Tax Authorities may make the rectification: "
},
{
    type:"list",
    list:[
    "  On its own motion, ",
    " On application made by the assessee / deductor / collector bringing the mistake to the notice of the income tax  authority concerned.",


    " Where the income tax  authority concerned is the Commissioner (Appeal), a mistake can be brought to his notice by the assessing officer also."

    ]
} 
]
    },
    {

        question:"Under which Sections of the Income Tax Act  can mistakes made by a tax / appellate authority be rectified?",
        items:[
        {
            type:"list",
            list:[   
                "Rectification of any matter other than the matter considered and decided in appeal/ revision can be done according to the provisions of Section154.",


                "Any matter which has been considered and decided by way of appeal or revision can be rectified under Section 254(2). This is because the Appellate Tribunal is not an Income Tax Authority."
            ]
        }
        ]

    },
    {

        question:"What is the meaning of ‘enhancement ‘ in the context of a rectification order?",
        items:[
            {
                text:"In the context of rectification, ‘enhancement’ implies : "
            },
            {
                type:"list",
                list:[
                    "Increase in tax liability of the assessee / deductor / collector",
                    "Reduction in the refund already made"
                ]
            }
    ]

    },
    {
        question:"What is the procedure when a rectification leads to an increase in a taxpayer’s liability?",
        items:[
            {
                text:"In a case where a rectification order leads to an ‘enhancement’ : "
            },
            {
                type:"list",
                list:[
                    "The tax authority has to give a notice of demand to the assessee / deductor / collector in the prescribed form specifying the sum to be paid [Section 154(6)]",


                    "The assessee also has to be given an opportunity of being heard as per Section 154(3)."
                ]  
        }
        ]
    },
    {

        question:"What is the procedure if a rectification of error in tax assessment leads to a reduction in tax?",
        items:[
            {
                text:"As per Section 154(5), when a rectification of error or an amendment in assessment leads to a reduction in tax liability of the assessee / deductor / collector, the assessing officer needs to refund the due amount to that assessee deductor or collector."
            }
            ]

    },
    {

        question:"Is there any time limit for rectification?",
        list:[
            {
                text:"The time limit for rectification depends under which Section of the Income Tax Act the application for amendment is filed."
            },
            {

                text:"Under Section 154(7) : Rectification of an order under this Section can be made only within four years from the end of the financial year in which the order, sought to be amended, was passed."
            },
            {
                type:"list",
                list:[
                "Rectification of a rectified order:  The time limit is 4 years from the end of the financial year in which the earlier rectification  order was passed.",

                "Appellate Order - A fresh time limit of 4 years"
                ]
            },
            {

                text:"Under Section 154(8) : The tax authority shall pass an order within 6 months from the end of the month in which the application is received, whether it is to correct the mistake or refuse the claim."
            }
        ]
    },
    {

        question:"How is Employees Stock Option (ESOP) taxed under the Income Tax Act?",
        items:[
            {
                text:"Section 156(2) lays down the rules for taxation in case of sweat equity shares issued by eligible startups."
            },
            {
                text:"The tax provision for ESOP is made up of two components."
            },
            {
                type:"list",
                list:[
                    " Tax on perquisite to be paid at the time of exercising the option.",
                    " Tax on income from Capital Gain at the time of sale."
                ]
            },
            {

                text:"Since ESOP is a payment in kind (as equity) by the employer, it might lead to cash-flow problems for the employee / assessee of eligible startups (Section 80-IAC) to pay tax at the time of exercising the option."
            },
            {
                text:"Therefore, Section 156(2) lays down that ( on or after 01.4.2021), the tax or interest on such income included in the notice of demand referred to in section 156(1) shall be payable by the assessee within 14 days : "
            },
            {
                type:"list",
                list:[
                    "After the expiry of 48 months from the end of the relevant assessment year, OR",
                    "From the date of the sale of such specified security or sweat equity share by the assessee, OR",
                    "From the date of the assessee ceasing to be an employee of the employer who allotted or transferred him such specified security or sweat equity share, whichever is the earliest."
                ]
}
]
    }

]

export {qandas}