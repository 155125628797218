import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const Whythisbook = ({  }) => {

    var items = [
        {
            type:'paragraph',
            text:"Over the years, I have seen how apprehensive people are of accounting. Whether it is a student trying to acquire a degree in commerce or accountancy or people running a business, a basic understanding of the subject can bring a transformation in their work and lives. Infact, the level of understanding of accountancy reflects the difference between a successful and a not-so-successful business."
        },
        {
            type:'paragraph',
            text:"With startups blossoming across India and the world, it is critical for young/new founders to embrace accounting and not shy away or be afraid of it."
        },
        {
            type:'paragraph',
            text:"I, therefore, decided to put my understanding of the subject on paper to allow others to realize how easy accounting is and how learning it can truly enrich their lives."
        }
    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Why Accounting Language ? </p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default Whythisbook
