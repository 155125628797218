//Computation of Total Income

var qandas = [

    {
        question:"How is income earned by an individual classified?",
        items:[
            {
                text:"As per Section 14 of the Income Tax Act, 1961, the income earned by an assessee in the previous year has been classified under the following heads :"
            },
            {
                type:"list",
                list:[
                    "Salary [Sections 15 to 17]",
                    "Income from House Property [Sections 22 to 27],",
                    "Profits and gains of Business or Profession [Sections 28 to 44 DA],",
                    "Capital Gains.[Sections 45to55A],",
                    "Income from Other Sources. [Sections56 to59],"
                ]
            }
        ]
    },
    {

    question:"What is the rule regarding expenditure incurred on exempt income?",
    items:[
        {
            text:"No deduction is allowed for expenditure incurred on the exempt income. [Section (14A)]"
        },
        {
            text:"The Finance Act 2022 has amended section 14A(1) so as to include a non-obstante clause in respect of  other provisions of the IncomeTax Act and provided that no deduction shall be allowed in relation to the exempt income, notwithstanding anything to the contrary  contained in this Act." 
        }
]
}


]

export {qandas}

//—-------------------------------------------------------