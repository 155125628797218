//Profits and Gains from Business or Profession

var qandas = [
{
   question:"What are the incomes which are subject to Income tax under the Head ‘Profits and Gains from Business or Profession’ ?",
   items: [
   {
      text:"The following incomes shall be chargeable  to Income tax under the Head ‘Profits and gains from Business or Profession’ ( Section 28) :"
   },
   {
      type:"list",
      list:[
   "Any compensation or other payment received or receivable in relation to the business carried on by the assessee.",
   "Any income in connection with termination or modification of the contract for managing the affairs of a company.",
   "Income received for holding an agency of any business enterprise or  any modification or termination of the same agency.",
   "Any income in connection with vesting in the Government or vesting in  any Corporation owned by the Government by any law imposed on the management of the property or business.",
   "Income received in connection with modification of the terms and conditions or termination of any business.",
   "Income derived by a trade or professional association for specific services rendered. ",
   "Export incentives, which include profits on sale of import licenses, cash assistance received, duty drawbacks, transfer of duty free replenishment certificate etc.",
   "Any benefit or perquisite received from a company during the course of carrying on the business or profession will be treated as income from business or profession. The benefit  or perquisite is chargeable to tax  even if  they are received in cash or in kind or partly in cash and partly in Kind. Section 28(iv).",
   "Any interest, salary, bonus, commission etc received by a partner from the partnership firm.",
   "Any sum received either in cash or in kind under an agreement for:-",
   "1) not carrying out any business or profession",
   "2) not to share information about the business or profession including, technical knowhow, copyright, patents, license, franchise, etc",
   "The difference between the fair market value and the cost of inventory as on the date of conversion of inventory into capital asset will be treated as profits and gains from  business."
      ]
   }
]
},
{

   question:"How is profit or gain of business or profession computed?",
   items:[
      {
         text:"The profits and gains of business or profession is to be computed in accordance with the provisions contained in Sections 30 to 43D, including the provisions in Sections 44 to 44D."
      },
      {
         type:"list",
         list:[
            "Method of Accounting.[Section145] : Any assessee doing business or profession has to maintain books of account either in cash system or mercantile system of accounting.The business or profession should follow a uniform method of accounting continuously from year to year. [Section 145(1)]. The Income  Tax Department approves only the Mercantile System of accounting for computation of income under the Heads ‘ Profits and Gains of  Business or Profession’  and ‘Income from Other Sources’.", 
            "Income Computation and Disclosure standards. The Central Government has notified ‘Income Computation and Disclosure Standards’  to be followed by business concerns from the previous year 2016-2017 onwards [Section 145(2)] "
         ]   
   }
   ]
},
{

   question:"What is the rule regarding taxation of income from subsidy, grant etc.?",
   items:[
      {
         text:"According to Section 145B: Notwithstanding anything to the contrary contained in Section 145,  the following incomes received will be taxed in the previous year in which  it is received:"
   },
   {
      type:"list",
      list:[
   "Interest received as compensation or enhanced compensation.",
   "Increase in the contract price due to escalation in the contract or export incentives.",
   "Subsidy or grant or cash incentive received from the Government."
      ]
   }
   ]
},
{

   question:"What are the expenses which are allowed as deduction in connection with generation of business income?",   
   items:[
      {
         text:"All the expenses incurred wholly and exclusively in connection with generation of income, are allowed as deduction of business or profession (Sections 30 to 37). The normal expenses allowed are:"
      },
      {
         text:"1) Repairs and maintenance of the building.[Section30]"
      },
      {
         type:"list",
         list:[
         "If the building is leased, the rent paid and the repairs and maintenance, if any, incurred will be allowed as specified in the lease deed.",
         "If the premises are owned and used for own business all the repairs and maintenance on the building will be allowed as deduction from the business income",
         "Insurance on the building  used for business.",
         "Land rent and property tax paid on the land and the building owned and used for  the business."
         ]
         },
      {
         text:"2) Repairs and maintenance and insurance on the plant and machinery, furniture, office  equipment, vehicle etc."
      },
      {
         text:"All the expenses incurred during the previous year on the fixed assets, excluding land,is allowed as deduction from the profits and gains of business or profession."
      }
   ]
},
{

   question:"What is meant by Depreciation ?",
   items:[
      {
         text:"Depreciation is the diminution in the value of an asset due to normal wear and tear and due to obsolescence (Section 32)."
      },
      {
         text:"The following conditions must be satisfied to claim depreciation:"
      },
      {
         type:"list",
         list:[
            "The asset must be owned by the assessee.",
            "The asset must be used for the purposes of business or profession,",
            "The asset must be used during the previous year."
         ]
      }
   ]
},

{

   question:"How is Depreciation computed?",
   items:[
      {
         text:"The normal methods of computing depreciation are:"
      },
      {
         type:"list",
         list:[
         "Straight Line method, and",
         "Written down value method. "
         ]
      },
      {
         text:"The Income Tax Department approves only the Written down value method of computing depreciation, except in the case of power generating and distributing companies where the Straight Line method of depreciation is allowed by the Income Tax Department."
      }
]
},

{

   question:"What are the types of Depreciation allowed in connection with Income Tax?",
   items:[
      {
         text:"The types of depreciation allowed by the IncomeTax Department are:" 
      },
      {
         type:"list",
         list:[
         "Normal Depreciation  is allowed on all the assets used in business except land.",
         "Additional depreciation is allowed subject to certain conditions. ",
         "Incentive allowance/depreciation is allowed to certain industries set up in the  backward  states."
         ]   
      }
   ]
},
{

   question:"How are assets classified?",
   items:[
{
      text:"Assets are classified as tangible assets and intangible assets."
},
{
      text:"Tangible assets (assets physically present) are:"
},
{
      type:"list",
      list:[
      "building",
      "plant and machinery",
      "furniture and fittings"
      ]
},
{
      text:"Intangible assets are:"
},
{
      type:"list",
      list:[
         "knowhow",
         "patents, copyrights, trademarks,",
         "licences,",   
         "franchises or",
         "any other business or commercial rights of similar nature not being goodwill of a business or profession"
      ]
   }
   ]
},
{

   question:"What is meant by ‘block of assets’?",
   items:[
      {
         text:"Block of assets. [Section2 (II)] means a group of assets falling within a class of assets on which the rate of depreciation is the same."
      },
      {
         text:"How is Normal Depreciation calculated?"
      },
      {
         text:"Normal Depreciation is calculated on the block of assets."
      }
   ]
},
{

   question:"What are the rates of depreciation on different blocks of assets?",
   items:[
      {
         text:"The rates of depreciation on different block of assets are:"
      },
      {

         text:"(I)Buildings"
      },
      {
         type:"list",
         list:[
            "Block I- Buildings used for residential purposes other than hotels and boarding houses. The rate of depreciation is 5%.",
            "Block II-Buildings used for any purpose other than for residence. The rate of depreciation is 10%.",
            "Block III- Buildings  related to water projects, such as water supply, sewage, desalination; demineralization etc .The building is used for housing plants and machinery related to water projects. The rate of depreciation is 40 %.",
            "Block IV -Purely temporary erections, wooden structure, etc. The rate of depreciation is 40%. "
         ] 
      },
      {

         text:"(II) Furniture and fittings including electrical fittings, fans, electrical wiring, switches etc. The rate of depreciation is 10%."
      },
      {

         text:"(III) Plant and machinery"
      },
      {
         type:"list",
         list:[
            "Block 1-(i) Motor cars are purchased in the name of the business enterprise  or professional  body and are used for their own business or profession and not for running on hire.",
            "(ii) General Machinery and Plant other than covered in block( 2) to block (5). ",
            "The Rate of depreciation on( i) and (ii))above is 15%",
            "Block 2 - Ships and vessels plying in inland waters including speed boats. The rate of depreciation is 20%.",
            "Block 3 (i) Plant and Machinery used in semiconductor industry, moulds used in rubber and plastic factories.",
            "(ii) The motor cars, acquired after 23.8.2019 but before 1/4/2020 and used for running them on hire on or before 1.4.2020.",
            "The rate of depreciation is 30%.",
            "Block 4 (i)Aero planes, Specified life saving equipments",
            "(ii)Containers made of glass or plastics used as refills.",
            "(iii) Computers including computer software",
            "(iv) Books, gas cylinders including  valves and regulators",
            "(v) Rollers used in flour mills, wind mills",
            "(vi)  Plant and machinery used in water treatment plant or water supply system",
            "(vii)Wooden parts used in artificial silk manufacturing plants",
            "(viii) Cinematograph films",
            "(ix) Match factories, wooden match frames.",
            "(x)Tubs, winding ropes, haulage ropes, and sand stowing pipes, and safety lamps used in mines and quarries.",
            "(xi)Salt Works-Salt Pans, reservoirs and condensers, etc made of earthy, sandy or clayey material and any other similar material.",
            "(xii)Books owned by assessees carrying on a business in lending- library.",
            "(xiii) Air Pollution Control Equipments, Water Pollution Control Equipment, Solid Waste Control Equipment, and Solid Waste Recycling and Resource Recovery Systems",
            "The rate of depreciation for the above items (i) to (xiii)  is 40%.",
            "Block 5 - Motor buses, Motor lorries and Motor taxis used in a business of running them on hire acquired on or after 23.8.2019, but before 1.4.2020 and are put to use before 1.4.2020.",
            "The rate of depreciation is 45%"
         ]
      }
   ]
      

},
{
   question:"What is the depreciation on intangible assets?",
   items:[
      {
         text:"Intangible Assets- Knowhow, patents, copyrights, trade marks, Licences, Franchises, or any other business or commercial rights of similar nature"
      },
      {
         text:"The rate of depreciation is 25%."
      },
      {
         text:"However, goodwill is not treated as an intangible asset and no depreciation is allowed on it. Amendment by Finance Act , 2021 [w.e.f. A.Y.2021-2022]"
      },
      {
         text:"(I)The Finance Act 2021 has amended section 43(6) as under:-"
      },
      {
         text:"Section 43(6) has been amended to provide that for the purpose of charging depreciation w.e.f. A.Y.2021-2022 in the case of block of intangible assets, the written down value of the goodwill computed in the prescribed manner shall be reduced from the written value of the block of intangible assets."
      }
      ]

},
{
   question:"How is depreciation on assets used in business computed?",
   items:[
      {
         text:"To compute the depreciation on assets used in business or profession in any Previous Year:"
      },
      {
         type:"list",
         list:[
         "The written down value of the fixed assets  at the close of the immediately preceding previous year is taken as the opening written down value of the Previous Year. ",
         "Add the purchases/additions  of fixed assets  during the previous year with the opening  balances of each block of assets.",
         "Deduct the sale proceeds, the value of assets discarded, demolished and destroyed during the previous year from the gross value of  each block of assets."
         ]
   },
      {

         text:"The resultant figure is the written-down value for computing  depreciation." 
      }
   ]
},
{

   question:"What is meant by ‘Value of assets sold, discarded, demolished or destroyed’?",
   items:[
      {
         text:"The value of assets sold, discarded, demolished or destroyed includes:"
      },
      {
         type:"list",
         list:[
         "Any insurance, salvage or compensation money received  in respect of the assets damaged ,destroyed or discarded.",
         "Where the building, machinery, plant or furniture is sold, the price for which it is sold."
         ]
      }
   ]

},
{
   question:"What is the rule relating to depreciation on less-used assets?",
   items:[
      {
      text:"Depreciation on the assets acquired during the previous year but used less than 180 days in the business or profession is 50% of the full depreciation computed in the previous year."
      }
   ]

},
{

   question:"What is meant by Additional Depreciation?",
   items:[
      {  
         text:"Additional Depreciation has been dealt with in Section 32(1)(iia]."
      },
      {
         text:"Apart from normal depreciation, additional depreciation at the rate of 20% is allowed on  new plant and machinery acquired and installed for the manufacture or production of any article  or generation and distribution of power by an industrial undertaking or enterprise."
      }
]

},
{

   question:"What is the rule relating to deductions in the field of scientific research?",
   items:[
      {
         type:"list",
         list:[
            "Under Section 35, an assessee is entitled to a deduction of an amount spent on scientific research and development.",
            "The scientific research may be carried on by:",
            "(i) the assessee himself relating to his business (in-house research), or",
            "(ii) by making payment to outside agencies engaged in scientific research work.",
            "The assessee can claim deduction of all the revenue expenses and capital expenditure incurred on the in-house research in the same previous year in which it is incurred.",
            "The Finance Act 2023  has amended this Section35  to remove the  condition of activity  in connection with  these expenses to be carried out by a concern  approved by the Central  Board of Direct Taxes . Instead,  the assessee shall be required to furnish  a statement  containing  the particulars  of this expenditure  within the prescribed  period  to be prescribed authority  in the prescribed  form and manner.",
            "As per Section 35 (1) (i), all the revenue expenses ( cost of material, manpower, administrative expense etc.) incurred  in the previous year on the in-house scientific research are allowed as deduction from the business income.",
            "The above expense incurred for three years prior to the commencement of the actual research incumbency period will be treated as the expense of the previous year actually  starting the research.",
            "All the capital expenditure incurred, except cost of land, will also  be allowed as deduction in the previous year in which it is incurred in the case of inhouse research ( Section 35 (1) (IV) read with Section 35 (2).",
            "Similarly, all the capital expenditure incurred for three years prior to the commencement of business will be allowed as deduction in the previous year of commencement of research."
         ]
      }
   ]

},
{

   question:"What is the rule relating to sale of an asset used for scientific research?",
   items:[
      {
      type:"list",
      list:[
         "It is necessary that the capital asset sold during the previous year was used by the assessee only for scientific research before being sold.",
         "When the scientific asset is sold without being used for other purposes, the net sale price or the cost of the asset allowed as deduction earlier, whichever is less, is taken as income from business or profession.",
         "The difference between the market price and the original cost will be treated as capital gain."
      ]
   }
   ]

},
{

   question:"What is the rule regarding deduction for payments made to research institutions?",
   items:[
      {
         text:"1) When a payment is made by an assessee to a research association, university, college or other institution which use these funds for scientific research, whether related to his business or not, 150% of the amount paid is allowed as deduction from the business or profession under Section35(1)(ii)&(iia)."
      },
      {
         text:"2) When a payment made to a Research Association which has its main object the undertaking of research in social science or statistical research and also payment made to a University or other institution to be used for research in social science or statistical research i.e. research related to human behaviour and statistical research  means research  related to numbers (marketing, etc),  the deduction allowed is 100% of the amount paid under Section 35(1) (iii)."
      },
      {
         text:"3) According to Section 35 (1), 100% of the payment made to the  scientific company to be used by it for scientific research is allowed as deduction  from the business or profession subject to the following conditions:"
      },
      {
         type:"list",
         list:[
            "(i) The company is registered in India.",
            "(ii) It has its main object the scientific research and development,",
            "(iii) It is approved by the prescribed authority in the prescribed manner for scientific research  and it fulfills such other conditions as may be prescribed." 
         ]
      }
   ]
},
{


   question:"What is the tax deduction allowed in the case of expenditure on ‘specified business’?",
   items:[
      {
         text:"As per Section 35AD, an assessee shall be allowed a deduction in respect of the whole of any capital expenditure incurred, wholly and exclusively for the purpose of any specified business carried on by him during the previous year in which such expenditure is incurred by him."
      },
      {

         text:"The capital expenditure incurred prior to the commencement of a specified business is allowed as deduction in the previous year of commencement of business provided that they are capitalized in the books of account in the previous year of commencement of business."
      },
      {

         text:"The Finance Act 2020 has amended this section and made it optional for the assessee to claim the deduction under this subsection."
      },
      {

         text:"What are the businesses included under ‘specified business’ in order to avail tax deduction on expenditure?"
      },
      {

         text:"The deduction under Section 35AD is available to all the assessees who are carrying on any of the following businesses:"
      },
      {
         type:"list",
         list:[
"Setting up and operating a cold chain facility.",


" Setting up and operating a warehousing facility for storage of agricultural produce.",


"Laying and operating cross –country natural gas or crude or petroleum oil pipeline network  for distribution including storage facilities being an integral part of such network,",


" The business of building and operating anywhere in India a hotel of two stars and above category as specified by the Central Government,",


" Building and operating anywhere In India a hospital with at least 100 beds for treatment of patients.",


"Developing and building a housing project under a scheme for slum redevelopment or rehabilitation framed by the Central Government or State Government as the case may be and notified by theCentral Board of Direct Taxes in accordance with the guidelines as may be prescribed.",


"Developing and building a housing project under a scheme for affordable housing framed by the Central Government or State Government as the case may be and notified by the Central Board of Direct Taxes in accordance with the guidelines as may be prescribed.",


" Production of fertilizers in India.",


" Setting up and operating an Inland Container Depot or Container Freight Station notified and approved under the Customs Act, 1962.",


"Bee keeping includes production of honey and beeswax.",


"Setting up and operating a warehousing facility for storage of sugar.",


"Laying and operating slurry pipeline for transportation of iron ore.",


"Setting up and operating a semiconductor wafer fabrication manufacturing unit, if such unit is notified by the  Central Board of direct Taxes in accordance with the guidelines.",


"Developing, maintaining and operating or developing, maintaining and operating any infrastructural facility."
         ]
   }
   ]

},
{

      question:"What is the amount of deduction allowed on expenditure incurred on ‘specified business’ ?",
      items:[
         {
            type:"list",
            list:[
"The deduction allowed under the Act is 100% of capital expenditure incurred wholly and exclusively on the’ specified business’ in the previous year in which the business commenced.",


"The capital expenditure incurred prior to the commencement of business will be allowed as deduction in the previous year in which the business commenced operation.",


" This deduction is applicable only to an assessee who set up a new specified business."
            ]
   }
   ]


},
{

   question:"What is the deduction allowed on company expenditure on skill development?",
   items:[
      {
         text:"Section 35 CCD allows 150 % deduction of the expenditure incurred on skill development of employees of a manufacturing company in the previous year."
      },
      {
      text:"This will not be available if the assessee opts for the new alternative tax regime w.e.f.1.4.2020."
      }
   ]

},
{

   question:"What is meant by ‘preliminary expense’ of a company?",
   items:[
      {
         text:"Preliminary expense means any expenditure incurred prior to the formation of a business  or expenses incurred on the extension of an existing undertaking."
      }
   ]
},
{

   question:"Which are the  preliminary expenses that qualify for deduction under the Income Tax Act?",
   items:[
      {
         text:"Section 35 lays down the types of  preliminary expenses which qualify for deduction :"
      },
      {
         text:"1. In the case of a business entity other than a company :"
      },
      {
         type:"list",
         list:[
"Preparation of a Feasibility Report,",


"Preparation of a Project Report,",


" Market survey or any other survey necessary for the business,",


"Engineering services relating to business,",


" Legal charges for drafting any agreement between the assessee and any other person relating to the setting up of the business."
         ]   
   },
      {

      text:"2. Where the assessee is a company :"
      },
      {
         type:"list",
         list:[
"The expense incurred for drafting the Memorandum and Articles of Association of the company",


"Printing charges on the Memorandum and Articles of Association of the company,",


" Fees for registering the company under the Companies Act, 1956, as repealed in 2013",


" Expenses incurred  in connection with issue of financial instruments, being underwriting commission, brokerage and charges for drafting, typing, printing and advertisement of the Prospectus of the company,",


"Such other expenses as may be prescribed by theCentral Government,",


"This is apart from the normal preliminary expenses incurred in the case of all assessees other than a company."
         ]   
   }
   ]
},
{


   question:"What is the amount of deduction available for preliminary expenditure incurred by a company ?",
   items:[
      {
         text:"The amount qualifying for deduction :"
      },
      {
         text:"In the case of a company, higher of the following two : 1) 5% of the of the Project cost, or 2) 5% of the Capital employed in the business."
      },
      {
         text:"In any other case : 5% of the project  cost"
      },
      {

         text:"The preliminary expenses will be allowed as  deduction in five equal annual installments commencing from the previous year in which the commercial operation started."
      },
      {

         text:"The deduction under this section shall be allowed only when the expenses incurred are audited by a Chartered Accountant or otherwise the accounts of the assessee are audited by a chartered Accountant under any other Act."
      }
   ]
},
{


   question:"What is meant by pre-operative expenses?",
   items:[
      {
         text:"The expense incurred after formation of a company or legal entity, but before commencement of regular business is called pre-operative expenses."
      }
   ]

},
{

   question:"What is the rule relating to expenditure incurred  on amalgamation or demerger?",
   items:[
      {
         text:"According to Section 35DD, the expenses incurred wholly and exclusively in connection with Amalgamation or Demerger of a company will be written off in five equal annual installments beginning with the year in which the amalgamation or demerger takes place."
      }   
   ]
},
{

   question:"What is the rule relating to expenses incurred under Voluntary Retirement Scheme ?",

   items:[
      {
         text:"As per Section 35DDA, the amount paid to an employee on voluntary retirement is written off in five equal yearly installments starting from the year in which the voluntary retirement took place."
      }
   ]
},
{

   question:"Is Securities TransactionTax allowed as deduction from business income?",
   items:[
      {
         text:"As per Section 36(1)(iv), the Securities Transaction Tax paid by the assessee in the previous year in respect of taxable securities transactions entered into in the course of his business will be allowed as deduction provided the income from these transactions are included in the income from business or profession."
      }
   ]
},
{

   question:"Is Commodities Transaction Tax paid by an assessee allowed as deduction from business income?",
   items:[
      {
         text:"Section 36(1)(xvi) lays down that Commodities Transaction Tax paid by the assessee on transactions of commodities entered into through a recognized stock exchange in the course of his business in the previous year will be allowed as deduction provided the income arising from such transactions is included in the income from business or profession."
      }
   ]

},
{

   question:"Is Marked to market loss or other expected loss allowed as deduction?",
   items:[
      {
         text:"According to [Section 36(1) (xviii)], Marked to market loss or any other loss computed as per ICDS under Section 145 (2) will be allowed as deduction."
      }
   ]
},
{
   question:"What are the general deductions allowed under the Income Tax Act?",
   items:[
      {
         text:"As per Section 37(1),  dealing with general deductions,  all the manufacturing, trading, administrative, financial, marketing and selling expenses, incurred wholly and exclusively for the type of business run by the assessee, is allowed as deduction from the taxable income from such business or profession "
      }
   ]
},
{


   question:"What are the expenses which are not allowed as deduction from the taxable income?",
   items:[
      {
         text:"According to Section 37(1), the following expenses specified shall not be allowed as deduction from the taxable income as they are not deemed to have been incurred for business or profession:"
      },
      {
         type:"list",
         list:[
            "Any expenditure incurred by an assessee for any purpose which is an offense or  prohibited by any law.",
            "Expenses borne to provide any benefit or perquisite in whatever form to a person whether or not  carrying on a business or exercising a profession; and ", 
            "Any expenditure incurred by an assessee on the activities relating to corporate social responsibility referred to in Section 135 of the Companies Act."
         ]   
   }
   ]
},
{

   question:"What expenditure is allowed as deduction as per Income tax rules?",
   items:[
      {
         type:"list",
         list:[
          "Remuneration to employees",
         "Payment of penalty /damages",
         "Legal expenses",
         "Expenditure on raising loans",
         "Expenditure on advertising"
         ]
      },
      {
      
      text:"Expenses Allowable under Specific Instructions of CBDT ",
      },
      {
         type:"list",
         list:[
" Diwali and Muhurat Expenses,",
"  Payment for telephone /internet/fax connection,",
"  Fee paid to the Registrar of companies to comply with the statutory requirements as per the Company’s Act, 2013,",
" Annual listing fee paid to a Stock Exchange,",
"  Professional tax by the business assessee",
"  Fee paid to the Registrar of Companies for making changes in the Memorandum and Articles of Association."
         ]
   }
   ]
},
{

   question:"Which are the expenses not allowed as deduction from income from business or profession? ",
   items:[
      {
         text:"The following expenses are not allowed as deduction:"
      },
      {
         type:"list",
         list:[
"Any expense incurred by an assessee either personal or of a capital expenditure and not related to business.",
  
"Any interest, royalty, fees for technical services paid outside India on which no tax was deducted, unless the tax on the above expenses is deducted and paid to the respective authorities and deduction is allowed in the year in which the tax deducted is paid."
         ]   
   },
   {

      text:"In the case of Non-Residents and Foreign Companies "
   },
{
   type:"list",
   list:[
   "1) Expenses incurred on behalf of relatives or payments made to relatives for goods supplied or services rendered other than at arm’s length. ",
   "2) Payment or expenditure considered to be excessive or unreasonable having regard to:",
   "(i) the fair market value of goods, services, or facilities, or",  
   "(ii) The legitimate business needs of the assessee’s business or profession, or",
   "(iii) The benefit derived or accruing to the assessee from the payment."
   ]
},
{

   text:"If the above conditions are satisfied, the assessing officer can disallow the expenditure to the extent which he feels is unreasonable."
},
{
   type:"list",
   list:[
         "(i) If the payment for any expense related to business or profession is more than Rs.10, 000 paid by cash to a person in a day, the entire expense is disallowed. [Section 40A (3) (a)]",

         "(ii) In the case of payments for plying, hiring or leasing goods carriages, the amount of payment by cash exceeding  Rs.35,000  to a person in a day, is disallowed."
   ]
} 
]

},
{

   question:"Which payments  by cash  are allowed under the Income Tax Act?",
   items:[
      {
         text:"The Act allows the following payments by cash:"
      },
      {
         type:"list",
         list:[
" to  banks",
" to Co-operative or Land Mortgage Bank",
" to any Primary Agricultural Society or any Primary Credit Society",
" to the Life Insurance Corporation of India",
"to  the Government or the rules made there under,",
" Payment made through bank or bank instrument,",
" Set-off of debits and credits for supply and  purchase of goods and services by book adjustment entries,",
" Payment made for products of cottage industries",
" Retirement benefit given to an employee not exceeding Rs. 50,000,",
" Salary paid to an employee after deduction of tax at source on his temporary transfer for more than 15 days where he does not maintain any bank account",
" Where payment is required to be made on a bank holiday,",
" Where payment is made to an agent who is required to pay cash on behalf of the person for goods or services provided,",
" Where payment is made to a Money Exchanger or a dealer dealing in Foreign Exchange for exchange of currency,"
         ]
      }
   ]
},
{


      question:"What are the rules regarding maintenance of accounts by persons carrying on business or profession?",
      items:[
         {
            text:"According to Section 44AA of the Act and Rule 6F, persons have been classified as specified professionals and non-specified  professionals and businessmen for the purpose of maintaining  the books of account."
         },
         {

            text:"(a) Maintenance of accounts by Specified Professionals. [Section 44AA (1)]"
         },
         {

            text:"The Central Board of Direct Taxes has categorized  certain professionals as Specified Professionals who should compulsorily maintain books of account as prescribed by the  CentralBoard of DIrect Taxes if their gross receipts from the profession exceed more than Rs.1,50,000 in all the three previous years immediately preceding the previous year."
         },
         {

            text:"Further, in the case of a newly set up Specified Profession, if his gross receipts for the previous year is expected to exceed Rs 1,50000 then also they should  maintain books of account as per Rule 6B."
         }
   ]
},
{


         question:"Who are the professionals classified as Specified Professionals?",
         items:[
            {
               text:"Specified Professionals are lawyers, engineers, architects, chartered accountants, company secretaries, interior decorators, doctors, film artists, authorized representatives and information technology experts etc. "
            },
            {
               text:"However, the Government can include any other person as Specified Professionals and notify the same in the official Gazette." 
            }
      ]
},
{


         question:"What is meant by Books of Account?",
         items:[
            {
               text:"The  Finance Act  2022 has amended  the definition of Books or Books of Account, Section  2(12A) with effect from Assessment Year 2022-2023.",
            },
            {
               text:"Books or Books of Account include ledgers, day books, cash books, account books  and other books, whether kept in written form  or in digital  form  or as print outs of data stored  in such  electronic form or in digital form or in a floppy, disc, tape or any other form of  electromagnetic data storage device. "
            }
         ]
},
{
 
      question:"What is the rule relating to maintenance of books of account by a person carrying on a non-specified profession / business?",
      items:[
         {
            text:"According to Section 44AA (2), in the case of a person (other than individuals and HUF) carrying on non-specified  profession / any form of business or trade: "
         },
         {
            type:"list",
            list:[
               "if  total income from the non-specified profession or from any  business carried on by a person exceeds Rs 1,20,000 or the turnover or gross receipts exceeds  Rs 10 lakhs in any of the three previous years immediately preceding the previous year,or",
               "in the case of a newly set up non-specified profession or any business or trade, if the estimated income is more than Rs.1,20,000 or the total turnover is more than Rs.10 lakhs, then, books of account as specified in the Income Tax Rules, have to be maintained."
            ]
         },
         {
            text:"In the case of Individuals and HUF, the income limit has now been enhanced from Rs.1,20,000 to Rs 2,50,000 and turnover limit from Rs.10 lakhs to Rs Rs 25 lakhs for the purpose of  compulsory  maintenance of books of account."
         }
      ]

},
{


      question:"In which cases is the maintenance of books of account not necessary  (not mandatory) ?",
      items:[
         {
            text:"There is no need to maintain  books of accounts and documents if the following conditions are satisfied :"
         },
         {
            type:"list",
            list:[
               "(I) if the income or turnover is less than the specified limits as referred to in Sections 44AA(1)and 44AA(2), as referred to in the above cases of Specified Professionals and  Non-Specified Professionals and persons carrying on business.",
               "(ii)  If the persons are covered under Sections 44AD, 44ADA, 44AE 44BB, 44BBB i.e. those who need not declare income lower than that which is prescribed in the above mentioned  relevant sections. ( those who follow presumptive or estimated income method)"
            ]
         }
         ]
},
{


   question:"What are the consequences of failure to maintain books of accounts as per Section 44AA?",
   items:[
      {
         text:"Section 271A of the Income Tax Act levies a penalty of a lump sum payment of Rs 25,000 for  non-maintenance of books of accounts as per the Income Tax Rules."
      }
      ]
},
{

   question:"Under what conditions is compulsory Audit  of Accounts  required?",
   items:[
      {
         text:"In the case of  Specified and Non-Specified Professionals,  if the gross receipts in the previous year exceed Rs 50 lakhs, then they should get their accounts audited by a Practicing  Chartered Accountant and submit the audit report in the prescribed form at least one month before the due date for filing the return of income."
      },
      {
         text:"Where a person is  carrying on a profession referred to in Section 44ADA and claims that :"
      },
      {
         type:"list",
         list:[
            "(a)income  from such profession is lower than 50% of the gross receipt of the profession ;and ",
            "(b) his income exceeds  the maximum amount which is  not chargeable to income tax in any previous year ,"
         ]
      },
      {
         text:"He shall get his accounts of such  previous year audited  by a Chartered Accountant and submit a report in the prescribed form from him on or before the due date for filing the return of income. "
      },
      {
         text:"In the case of a person carrying on a business in whose case Section 44AD(4) is applicable and his income exceeds the maximum amount which is not chargeable to income tax in any previous year, he should get his accounts of  such previous year  audited by a  Chartered Accountant  and submit the report in the prescribed form on or before the due date for filing the return of income."
      }
   ]
},
{

   question:"How is Income Tax calculated under the presumptive income method?",
   items:[
      {
         text:"The method of computing income tax under the presumptive income method is as follows:"
      },
      {
         type:"list",
         list:[
            "The estimated profits or gains of the business i.e the taxable income from business will be calculated at 8% of the turnover of the previous year. The income tax is calculated on the  estimated income so arrived at 8%.",
            "Apart from income tax, surcharge and health and education cess will be levied on the income tax  so arrived at.",


            "However, in the case of a business enterprise whose total gross receipts or turnover in the previous year is either through bank or by other digital mode of payment (no payment is received by cash), then the estimated profits or gains from business will be calculated at 6% instead of 8% of the gross receipts or turnover .",
            "Surcharge and health and education cess will be added with the tax computed above to arrive at the total tax liability."
         ]
   }
   ]


},
{

   question:"Is interest on loan given by a partner to a partnership firm allowed as deduction while computing tax on business?",
   items:[
      {
         text:"According to Section 40(b), "
      },
      {
         type:"list",
         list:[
   "Interest on Capital/ Loan from the Partners is allowed as deduction only when actual payment of interest is made to the Partners by the partnership firm.",
   "The maximum amount of interest allowed as deduction is the interest mentioned in the Partnership Deed or 12% per annum whichever is less.",
   "Similarly, any salary, bonus, or commission paid to a working Partner is allowed as deduction only when it is mentioned in the Partnership Deed.",
   "The maximum amount allowed for salary, bonus or commission is either the amount mentioned in the Partnership Deed of the firm or if the book profit is up to Rs 300,000, it is 90% of the book profit or Rs 150,000 whichever is more and on the balance of the book profit, it is 60% for all the partners together."
         ]
      }
   ]
},
{

   question:"Is there any provision for deduction for gratuity while computing tax liability of a business?",
   items:[
      {
         text:"Section 40A (7] lays down provisions with regard to gratuity."
      },
      {
         type:"list",
         list:[
"Gratuity is a liability that normally arises according to the service of the employee. The liability would accrue from year to year.",


"As per Section 47A (7), gratuity is allowed as a deduction from the profits and gains of business, only when the amount of gratuity has actually become payable during the previous year or when a provision has been made by way of contribution to an Approved Gratuity Fund.",


"The deduction is allowed only for contributions made for Approved Social Security Schemes like Recognized Provident Fund, Approved Gratuity Fund and Approved Superannuation Fund.",


" Contribution made to Non Recognised Social Security Schemes will not be allowed as deduction from the business income."
         ]
   }
   ]
},
{


question:"Which payments are allowed as deduction from business income when actual payments have been made? ",

items:[
   {
      text:"In the following cases deductions are allowed from business income only when actual payments are made (Section 43B):-"
   },
   {
      type:"list",
      list:[
"(a) Payment of duty, tax, cess or fee,",
"(b) Contributions to employees’ welfare funds,",
"(c) Bonus or commission paid  to employees for services rendered.",
"(d) Any interest payable to any Financial Corporation on loan or borrowing availed for business or profession",
"(e) Any interest payable to a bank on loan or advance taken for business.",
"(f) Any sum payable to an employee as leave salary.",
"(g) Any sum payable to Railways for use of its assets.",
"(h) Any sum payable to Micro Small  and Medium enterprises (MSMEs)."
      ]
   },
   {

      text:"In the above cases, the deduction is allowed only if the actual  payment is made in the previous year or before the due date for filing the return of income under Section 139(1)."
   }
]
},
{

      question:"How are profits / gains from Construction Contracts or Service Contracts computed?",
      items:[
         {
            text:"The profits and gains arising from a Construction Contract or Service Contract for providing service is determined on the basis of percentage of completion method, in accordance with the Income Computation and Disclosure Standards notified under Section 145(2)."
         },
         {

            text:"However, the profit or gain arising from the following services shall be determined as under:"
         },
         {
            type:"list",
            list:[
"Contract for providing services with duration less than 90 days shall be determined on the basis of Project Completion method.",


"Contract for providing services involving an indeterminate number of acts over a specific period of time shall be determined on the Straight Line method.",


"Further, for the purposes of percentage of completion method or straight line method referred to above, the contract revenue shall include the retention money also. The cost of the contract shall not be reduced by any incidental income in the nature of interest,dividend or capital gains received."
            ]
      }
   ]

},
{

   question:"What is the rule in the case of recovery of bad debts allowed as deduction  in any earlier previous year?",
   items:[
      {
         text:"According to {Section 41}, where any bad debt has been allowed as deduction in any previous year and later on it has been recovered , it will be included in the profits and gains of business or profession in the previous year in which it is recovered."
      },
      {
         text:"The reserves created by the financial institutions,if allowed as deductions from the profits and gains of  business or profession in the earlier previous years, will be included in the profits and gains of  business or profession of the previous years in which the  reserve is withdrawn."
      },
      {
         text:"Deemed income in the hands of a legal heir in the case of a discontinued business or profession. [Section 176(3A) and (4)]"
      },
      {
         text:"In the case of a discontinued business or profession, any amount received after the discontinuance of the business will be charged to profits and gains in the hands of the legal heir in the year in which it is received as if the business or profession is being  continued."
      }
   ]

}

]


export {qandas}


//___________________________________________________