//Minimum Alternate Tax

var qandas = [

    {
        question:"What is Minimum Alternate Tax ?",
        items:[
            {
                text:"As per Section  115JB(1), Minimum Alternate Tax is the tax liability of a company whose income tax payable on the total income computed under the Income Tax Act is less than 15% of the book profit. "
            },
            {
                text:"Such book profit shall be deemed to be the total income of the assessee."
            },
            {
                text:"Thus in the case of a company, the income tax payable shall be higher of the following two amounts:"
            },
            {
                type:"list",
                list:[
                    "Tax on the income computed as per the normal provisions of the Act, by charging applicable normal rates and special rates, if any income included in the total income of the company is charged at special rates.",


                    " 15% of the book profit."
                ]
            },
            {
                text:"The Minimum Alternate Tax in the case of a company located in any international financial services centre is 9%."
            },
            {
                text:"The tax paid under the MAT will be allowed to be set off against the  tax payable calculated under the normal income tax rules in the subsequent 10 years, Section 115JAA."
            },
            {
                text:"Minimum Alternate Tax is not applicable in the case of domestic companies which have opted for a new regime of taxation as included in the Finance Act 2020."                             
            }
    ]
    }

]

export {qandas}