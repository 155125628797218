import { 
    UPDATE_SELECTION,
    CLEAR_SELECTION,
    TOGGLE_SELECTION,
    TOGGLE_SELECTION_ON,
    TOGGLE_SELECTION_OFF,
    CALLBACK_SELECTION
 } from "../constants/selectionConstants"




export const selectionReducer = (state = { selectionmode:'block', startElem:null, endElem:null, elements:[], callbackFun:null}, action) => {

    switch(action.type){

        case UPDATE_SELECTION:
            return { loading: false, selectionmode: action.payload.mode, elements:action.payload.elements, callbackFun: state.callbackFun, startElem:action.payload.start, endElem:action.payload.end }

        case CLEAR_SELECTION:
            return { loading: false, selectionmode:'block', elements:[],callbackFun: state.callbackFun }

        case CALLBACK_SELECTION:
            return { loading: false, selectionmode: state.selectionmode, elements:state.elements, callbackFun: action.payload, selectStart:null, selectEnd:null }

        default:
            return state
    }


}


export const selectionToggleReducer = (state = { isSelection: false}, action) => {

    switch(action.type){

        case TOGGLE_SELECTION:
            return { loading: false, isSelection: !state.isSelection }

        case TOGGLE_SELECTION_ON:
            return { loading: false, isSelection: true}

        case TOGGLE_SELECTION_OFF:
            return { loading: false, isSelection: false }


        default:
            return state
    }


}
