import {

    GET_PROJECTS,
    PROJECTS_ERROR,
    ADD_PROJECT,
    EDIT_PROJECT,
    GET_PROJECTBYID,

    SELECT_PROJECT,
    SELECT_PROJECT_ERROR,
    CLEAR_SELECT_PROJECT

} from '../constants/projectConstants.js';

export const projectlistReducer = (state = { projects:[]}, action) => {

    switch(action.type){

        case GET_PROJECTS:
            return { loading: false, projects: action.payload }
        case ADD_PROJECT:
            return { loading: false, projects: [action.payload, ...state.projects] }
        case EDIT_PROJECT:
            return { loading: false, projects: action.payload }
        case PROJECTS_ERROR:
            return { loading: false, error: action.payload, projects:state.projects }
        default:
            return state
    }

}


export const projectDetailReducer = (state = { currProject:null}, action) => {

    switch(action.type){

        case SELECT_PROJECT:
            return { loading: false, currProject: action.payload }
        case SELECT_PROJECT_ERROR:
            return { loading: false, currProject: state.project, error:action.payload}
        case CLEAR_SELECT_PROJECT:
            return { loading: false, currProject: null }
        default:
            return state
    }

}