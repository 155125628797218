import React from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'



const Header = () => {

    const dispatch = useDispatch()


    const logoutHandler = () => {
        
    }

    const navigateToHome = () => {

    }



    return (
        <header>

            <Navbar bg="dark" variant='dark' expand="lg" collapseOnSelect style={{padding:'0px'}}>
                <Container>
                <LinkContainer to='/'>
                        <Nav.Link>
                        <img
                        src="/acllogo.png"
                        height="40"
                        className="d-inline-block align-top"
                        alt="ACL Logo"
                        style={{marginRight:'10px'}}
                         />
                        </Nav.Link>

                    </LinkContainer>
                    <LinkContainer to='/' style={{marginLeft:'20px'}}>
                        <Nav.Link>
                        <Navbar.Brand> Accounting Language </Navbar.Brand>
                        </Nav.Link>

                    </LinkContainer>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">

                
                        <Nav className="ml-auto">


                            <LinkContainer to='/'>
                                <Nav.Link><i class="fa fa-home" aria-hidden="false"></i></Nav.Link>
                            </LinkContainer>  

                            <LinkContainer to='/accounting'>
                                <Nav.Link>Accounting</Nav.Link>
                            </LinkContainer>  

                            <LinkContainer to='/income-tax-faqs'>
                                <Nav.Link>Income-Tax-FAQs</Nav.Link>
                            </LinkContainer>  

{/* 
                            <LinkContainer to='/tables'>
                                <Nav.Link>Tables</Nav.Link>
                            </LinkContainer>   */}

                             
                            

                            {/* <LinkContainer to='/'>
                                <Nav.Link>Business</Nav.Link>
                            </LinkContainer> 


                            <LinkContainer to='/personal'>
                                <Nav.Link>Personal</Nav.Link>
                            </LinkContainer>  


 


                            <LinkContainer to='/learn'>
                                <Nav.Link>Learn</Nav.Link>
                            </LinkContainer>                         */}


                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header
