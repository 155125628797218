import {

    ADD_CURRENT_ASSETS_ITEM,
    CURRENT_ASSETS_FAIL,

} from '../constants/balanceSheetConstants.js';



export const currentAssetsReducer = (state = { currentassets:[]}, action) => {

    switch(action.type){

        case ADD_CURRENT_ASSETS_ITEM:
            return { loading: false, expenses: [action.payload, ...state.currentassets] }
        case CURRENT_ASSETS_FAIL:
            return { loading: false, error: action.payload, currentassets:state.currentassets }
        default:
            return state
    }

}
