//Assessment of Companies - Some definitions

var qandas = [

   {
      question:"What is a ‘company’ according to the Income Tax Act in India?",
      items:[
         {
            text:"Section 2 (17) of the Income Tax Act defines a company as:"
         },
         {
            type:"list",
            list:[
               "Any Indian company, OR",


               "Any corporate body incorporated by or under the laws of a country outside India, OR",


               "Any institution, association or body which was assessed as a company for any assessment year under The Income Tax Act, 1922 or was assessed under this Act as a company for an assessment year before 1970, OR",


               "Any institution, association or body, whether incorporated or not and whether Indian or  foreign which is declared as a company by a general or special order of the  Central  Board of DirectTaxes  "
            ]

      }   
   ]

   },
   {
         question:"What is meant by a company in which ‘the public is substantially interested’?",
         items:[
            {
               text:"Section [2(18)] describes a company in which the public is ‘substantially interested’. It includes: "
            },
            {
               type:"list",
               list:[

   " A company owned by the Government or Reserve Bank  Of India.",


   "A company having Government participation i.e. a company in which not less than 40% of the shares are held by the Government of India or Reserve Bank of India or a Corporation owned by the Government of India or Reserve Bank of India.",


   "Companies registered under Section 8 of the Companies Act 2013. (These companies aim to promote commerce, art, science, charity or any other object of general public utility and do not have any profit motive. They, therefore, lose this status if, at any time, they declare dividends.)",
                                                              
   "A company without any share capital and declared by the Central Board of Direct Taxes, on the basis of its objectives, nature and composition, to be a company in which the public is ‘substantially interested’.",
 
   "A Mutual Benefit Finance Company whose principal business is the acceptance of deposits from its members and it has been declared by the Central Government to be a Niche or Mutual Benefit Society.",


   " A company having a co-operative society participation in which at least 50% or more of equity shares are held by one or more co-operative societies.",


   "A public limited company (which is not a private limited company as defined in  the Companies Act,1956) and fulfills either of the following conditions:",

   "a)   Its equity shares are listed on a recognized stock exchange as on  the last day of the relevant previous year,",
   " b ) Its equity shares carrying  not less than  50% of the voting power (in the case of an industrial company, the limit is 40%) were beneficially held throughout the relevant previous year by the  Government, a Statutory Corporation, a Company in which the  public are substantially interested or wholly owned subsidiary of such company."
   ]
   }
         ]
      

   },
   {

      question:"What is an industrial company?",
      items:[
         {
            text:"An industrial company is one whose business consists mainly of:"
         },
         {
            type:"list",
            list:[
             "the construction of ships OR",
             "the manufacturing or processing of goods OR",
             "mining OR ",
             "the generation or distribution of electricity OR",
             "any other form of power."
            ]
         }
      ]

   },
   {

      question:"What is a ‘widely held company’?",
      items:[
         {
            text:"It is a company in which the public are ‘substantially interested’"
         }
      ]

   },
   {
      question:"What is meant by a ‘closely held company’ ?",
      items:[
         {
            text:"A ‘closely held’ company is one in which the majority of shares are held by a few people. Its shares are not traded publicly."
         }
      ]
},
   {

      question:"What is an Indian company according to the Income Tax Act in India?",
      items:[
         {
            text:"Section 2(26) of the Income Tax Act describes an Indian company as a company formed and registered under the Indian Companies Act, 1956 and includes:"
         },
         {
            type:"list",
            list:[
                "A company formed and registered under any law relating to companies formerly in force in any part of India (other than the state of Jammu and Kashmir and Union Territories)",


                "A corporation established by or under a Central, State or Provincial Act.",


                "Any institution, association or body which is declared by the Central Board of Direct Taxes as a company.",


                "In the case of, the state of Jammu and Kashmir, a company formed and registered under any law for the time being in force in that state.",


                "In the case of any of the Union Territories of Dadra and Nagar Haveli, Goa, Daman and Diu and Pondicherry, a company formed and registered under any law for the time being in force in those Union Territories.",


                "Provided that the registered or  the principal office of the company, corporation, institution, association or body is in India."
            ]

      }

      ]
   },
   {


         question:"What is a Domestic company? ",
         items:[
            {
                text:"According to Section 22(2A) of the Income Tax Act:"
            },
            {
               text:"A domestic company means an Indian company or any other company which, in respect of its income liable to income tax under the Income Tax Act, has made prescribed arrangements for the declaration and payment of dividends within India (including dividends on preference shares)." 
            }

]

   },
   {

      question:"What is a Foreign company?",
      items:[
         {
            text:"According to Section 2(23A), a foreign company means a company which is not a domestic company."
         }
      ]
   },
   {
      question:"What is an Investment Company?",
      items:[
         {
            text:"An Investment Company is a company whose gross total income consists mainly of income chargeable under the Heads - Income from House Property, Capital Gains and Income from Other Sources."
         }
      ]
   },
   {
         question:"What is meant by a ‘resident’ / ‘non-resident’ company?",
         items:[
            {
               text:"Section 6(3) classifies companies on the basis of their residence into ‘resident’ and non-resident’."
            },
            {

               text:"A company is said to be ‘resident’ In India in any previous year, if :It is an Indian company, or Its place of effective management (POEM) in that previous year is India."
            },
            {

               text:"A company is a ‘non-resident’ company in India in any previous year if :  It is not an Indian company, or Its place of effective management in that year is not in India."
            }
         ]

   },

   {

      question:"What is the rule relating to issue of shares for a consideration more than the fair market price ?",
      items:[
         {
            type:"list",
            list:[
                "According to Section56(2)(vii b), in the case of a company in which the public is not substantially interested, any consideration received on the issue of shares in excess of the market price, is treated as income under the Head ‘Income from other sources’ under the Income Tax Act.",

                "However, this provision is not applicable in the case of a Venture Capital Undertaking which has received consideration for issue of shares from a Venture Capital Company / Venture Capital Fund."
            ]   
      }
      ]
   },
   {

      question:"What is the liability of Directors of private limited companies in the event of liquidation of that company?",
      items:[
         {
            text:"Section 179 of the Income Tax Act lays down that where any income tax is due from a private company in respect of any income of any previous year which could not be recovered from the company, then : "
         },
         {
            type:"list",
            list:[
            "every person who was a Director of that company at any time during the relevant previous year",
            " shall be jointly and severally liable for payment of such tax",
            " unless he proves that the non-recovery could not be attributed to any gross neglect or breach of duty on his part in relation to the affairs of the company."
            ]
         }
      ]

   },
   {

      question:"What is the meaning of ‘tax due’ ?",
      itens:[
         {
            text:"As per Section 179 of the Income Tax Act, the expression ‘tax due’ includes any tax, penalty, interest, fine or any other sum payable  to the Income tax department under the Income Tax Act."
         }
      ]
   },
   {


      question:"What is the tax liability on distributed income of a domestic company due to buy back of its own shares?",
      items:[
         {
            text:"Section115(QA)(1 )lays down the rule relating to tax liability on distributed income to shareholders due to share buy back."
         },
         {

            text:"When a domestic company, whether it is listed or not in any recognized stock exchange, buys back its own shares from the shareholders:"
         },
         {
            type:"list",
            list:[
               " income tax is payable by the domestic company at the rate of 20% plus12% surcharge and ",


               "4% health and education cess on the difference between the share issue price and the buyback price.",


               " The tax is payable within 14 days from the date of buy back of its own shares.",


               " This is applicable to a domestic company whether it is liable to pay tax on the profits and gains made from the business or profession or not. ",


               " This is considered as final payment of tax and no further income tax is payable by the shareholder on the buyback of his shares by the company. " 
       
            ]
      }
      ]

   },
   {


      question:"Is the income received by a shareholder on the buyback of his shares by a company, tax exempt ?",
      items:[
         {
            text:"According to Section10 (34A} : "
         },
         {
            text:"Any income arising to an assessee, being a shareholder, on account of buy back of  his shares  by the company, as referred to in Section 115QA, shall be exempt. "
         }
      ]
},
{

      question:"Is there any tax liability on dividends received from foreign companies ?",
      items:[
         {
            items:"According to Section 115BBD of the Income Tax Act : "
         },
         {
            type:"list",
            list:[
               "Where the total income of an Indian company includes any income by way of dividends declared, distributed or paid by a specified foreign company, ",


               "the income tax payable will be 15% of the dividends received ,and ",


               "the tax on the income, other than the dividend received, will be taxed at the normal rate.",


               " No deduction is allowed from the dividend received from the specified company in respect of any expenditure incurred on the dividend. [Section 115BBD (2)] ",


               "The Finance Act 2022 has withdrawn the concessional rate of tax on dividends received from specified foreign companies.",


               "Thus, the dividend received from a specified foreign company will be subject to  the same rate of tax applicable to dividend received from a domestic company, plus surcharge and health and education cess."
            ]
         }
      ]

},
{


      question:"What is the last date for the filing of income tax returns for a company?",
      items:[
         {
            type:"list",
            list:[
               "The principal officer of a company is under an obligation to file the income tax return of the company for the previous year on or before the 31st  day of October of the relevant assessment year.",


               "In the case of a company covered under Section 92E ( having international transactions or specified domestic transactions), the due date for filing the tax return of the previous year is 30th November of the relevant assessment year."
            ]
      }
      ]
},
{


   question:"What is the difference between the tax liability of a company and any other assessee?",
   items:[
      {
         text:"A company’s liability differs from that of other assessees in two aspects; "
      },
      {
         type:"list",
         list:[
            "There is no basic exemption limit for a company like an individual or HUF .",


            "A company pays income tax at a flat rate instead of slab rate on its taxable income."
         ]
   }
]


},
{


   question:"Is there a method of computation of Profit where there is close connection between the company and any other person ?",
   items:[
      {
         text:"According to Section 115 BAB (6), while assessing a company :" 
      },
      {
         type:"list",
         list:[
            " if the assessing officer feels that there is a nexus between the company and any other person in doing business",
            " such that the transactions between them provides to the company more than the expected ordinary profits,",


            "  the assessing officer shall, in computing the profits and gains of such business, take the amount of profits as may reasonably deemed to have been derived therefrom.",


            " In case the aforesaid arrangement involves a specified domestic transaction referred to in Section 92BA, the amount of profit from such transaction shall be determined having regard to the arm’s length price as per clause (ii) of Section 92F.",


            " The amount of profits determined by the assessing officer shall be deemed to be the income of the person. "
         ]
   }
   ]

}



]

export {qandas}