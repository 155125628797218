import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const Typesofregisters = ({  }) => {

    var items = [
        {
            type:'paragraph',
            text:"REGISTERS are maintained by every business unit to record the details of its varied commercial activities. Since this involves a wide spectrum of transactions, it is prudent to maintain separate registers depending on the field of activity."
        },
        {
            type:'header',
            text:"Fixed Assets Register"
        },
        {
            type:'paragraph',
            text:"This register shows the details of all the individual fixed assets, namely :"
        },
        {
            type:'list',
            list:[
                "the date of purchase",
                "the cost of the asset",
                "depreciation written off in the current year",
                "the depreciation written off so far",
                "the cost of the asset sold during the year",
                "the cost of asset written off during the year and",
                "the net written-down value at the close of the year."
            ]
        },
        {
            type:'header',
            text:"Raw Materials Stock Register"
        },
        {
            type:'paragraph',
            text:"Raw materials  form part of the finished goods. Therefore, it is very important to maintain its stock register to ensure that there is adequate stock of raw materials at any point of time for un-interrupted production."
        },
        {
            type:'paragraph',
            text:"This register shows :"
        },
        {
            type:'list',
            list:[
                "the  quantity and value of opening stock",
                "the quantity  and value  of raw materials purchased  during the year",
                "the quantity and value of  raw materials consumed  during the year",
                "the quantity and value of raw materials  at the end of the year."
            ]
        },
        {
            type:'paragraph',
            text:"The total quantity and value of the stock of raw materials at the close of the year should tally with the sum total of the quantity and value of raw materials in the stock ledger."
        },
        {
            type:'header',
            text:"Finished Goods Stock Register"
        },
        {
            type:'paragraph',
            text:"This register has details of :"
        },
        {
            type:'list',
            list:[
                "the quantity and cost of opening stock of finished goods",
                "the quantity  and cost  of finished goods produced during the year",
                "the cost and quantity of finished goods sold during the year",
                "the quantity and cost of finished goods in stock at the close of the year"
            ]
        },
        {
            type:'paragraph',
            text:"Normally, the closing stock of finished goods is always valued at cost or market price whichever is less ."
        },
        {
            type:'header',
            text:"Purchase Register"
        },
        {
            type:'paragraph',
            text:"This register shows :"
        },
        {
            type:'list',
            list:[
                "the serial number",
                "the date of purchase",
                "the name and address of the supplier",
                "the net purchase price",
                "the GST",
                "the total purchase price",
                "the creditor’s ledger folio."
            ]
        },
        {
            type:'header',
            text:"Sales Register"
        },
        {
            type:'paragraph',
            text:"This register contains :"
        },
        {
            type:'list',
            list:[
                "the serial number",
                "the date of sale",
                "the name and address of the buyer",
                "the invoice number",
                "the net sales value",
                "the GST",
                "the total invoice price",
                "the debtor’s ledger folio"
            ]
        },
        {
            type:'header',
            text:"Purchase Return Register"
        },
        {
            type:'paragraph',
            text:"Purchase Return Register contains the details of goods purchased but returned to the supplier due to some issue, like manufacturing defect, sub-standard quality, etc."
        },
        {
            type:'header',
            text:"Sales Return Register "
        },
        {
            type:'paragraph',
            text:"Sales Return Register shows the details of goods sold by the business unit but returned by the buyer because of some problem or defect."
        },
        {
            type:'header',
            text:"Stores and Spares Stock Register"
        },
        {
            type:'paragraph',
            text:"The details shown in this register are similar to those in the Raw Materials Stock Register."
        },
        {
            type:'paragraph',
            title:'Fuel Stock Register',
            text:"Fuel Stock Register also contains details similar to those in the Raw Materials Register."
        },
        {
            type:'paragraph',
            title:'Postage Register',
            text:"This register shows :"
        },
        {
            type:'list',
            list:[
                "the postage stamps purchased",
                "the value of stamps used",
                "the name and the postal address of each addressee",
                "the total postage stamps used written off as expense",
                "the balance at the close of the year"
            ]
        },
        {
            type:'paragraph',
            title:'Stationery Register ',
            text:""
        },
        {
            type:'list',
            list:[
                "This Register holds item wise details of all the stationery items purchased and issued to each department.",
                "The total stationery consumed during the year is written off by passing monthly journal entries.",
                "The closing balance of individual items in the Stationery Register should tally with the total closing balance in the Stationery Account in the General Ledger."
            ]
        },

    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Types of Registers</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default Typesofregisters
