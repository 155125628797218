//Incomes not part of Total Income

var qandas = [
    {
        question:"Which are the incomes which are exempted from Income Tax?",
        items:[{
            text:"There are certain incomes received by the assessees in the previous year which are exempted  under Section 10 of The Income Tax Act."
        },
        {

            text:"Some of them are given below:-"
        },{
            type:"list",
            list:[
                "Agricultural income is not taxable, but included in the total income to arrive at the rate of Income Tax on non-agricultural income. [Section 10(1)]",
                "Any sum received by a member from HUF. The income of HUF is taxed  as a separate legal entity[Section 10 (2)].",
                "Share of profits of a partner from a Partnership Firm. The income of the firm  is assessed and taxed  separately [Section 10(2A).",
                "In the case of a non -resident, any income by way of  interest  on such securities  or bonds  as the Central  Government  may, by notification  in the Official Gazette, specify in this regard, including income by way of premium on the redemption of such bonds."
                ]    
            }
        ]
    },
{

 question:"What are the exemptions provided to non-residents on income from financial transactions?",
items: [
    {
        text:"The Finance Act 2022 has inserted a new  Section 10(4G) to exempt certain incomes received by a non-resident from:"
    },
    {
        type:"list",
        list:[
        "portfolio  of securities ",
        "financial products ",
        "funds managed or administrated  by any portfolio manager on behalf of such non – resident, in an account maintained with an Offshore Banking Unit in any International  Financial Services Centre referred to in section 80LA(!A),to the extent such income accrues  or arises outside India and is not deemed to accrue or arise in India ."
        ]
    }
    ]
},
{

    question:"What is the rule relating to Leave Travel Concession provided to individuals ?",
        items:[
            {
            text:"In the case of an individual, the leave travel concession received by him from his employer, which should not exceed the actual expense incurred by him and subject to limits laid down under section 17 {Section 10 (5)], is tax exempt."
        },{
            text:"The Finance Act l 2021 has exempted cash received  in lieu of the leave travel concession due to COVID pandemic."
        }
        ]
},
{
    question:"What is the rule relating to tax exemptions granted to a foreign company?",
    items:[
    {
        text:"According to Section (10(6C) any income arising to a foreign company by way of royalty or fees for technical services rendered in pursuance of an agreement entered into with the Government of India for providing services outside India on projects connected with security of India is tax exempt."
    }
]
},
{
    question:"What is the rule regarding allowances paid to a government employee of India for working abroad?",
    items:[{
        text:"Section 10(7) lays down that any allowances or perquisites paid or allowed as such outside India by the Government to a citizen of India for rendering service outside India, is tax exempt."
    }
    ]
},
{
    question:"What is the tax exemption rule in connection with the Sukanya Samroddhi account?",
    items:[
        {
            text:"According to Section 10(11A), any amount withdrawn from the Sukanya Samriddhi Deposit account including the interest accrued thereon is fully exempt."
        }
    ]
},
{
    question:"What is the tax rule on payments received from the National Pension Scheme?",
    items:[ 
        {
            text:"If any payment is received from the National Pension Scheme under Section 80CCD on account of closure or opting out of the scheme, a sum equal to 60% of the amount received will be exempt from income tax."
        },
        {
            text:"However, in the case of death of the person, the amount received by the nominees will be fully exempt [Section 10 (12A)]. "
        },
        {

            text:"Any payment from the National Pension System Trust to an employee under Section 80CCD on partial withdrawal, to the extent it does not exceed 25% of the amount of the contributions made by him, shall be exempt [Section 10(12B)] . "
        }
    ]
},
{


    question:"What is the rule relating to receipts from Agniveer Corpus Fund?",
    items:[
        {
            text:"Receipts  from the Agniveer Corpus Fund  by a person  enrolled under the  Agniveer scheme  2022 is exempt from tax  under Section 10(12C)."
        },
        {

            text:"Is there an income tax exemption if the income of a minor child is clubbed with the income of the parent?"
        },
        {

            text:"As per Section 10(32) of the Act, the parent will get a deduction of Rs1,500 per child if the income of the minor child included in the parents income is more than Rs 1500, "
        },
        {

            text:"If the income of the  minor child clubbed is less than Rs 1500, the deduction per minor child is restricted to the actual amount clubbed in the relevant previous year."
        }
]
},
{

    question:"What is the rule regarding income from buy back of shares?",

    items:[
        {
            text:"According to Section 10(34A), any income arising to an assessee, being a shareholder of a company, on account of buy back of his shares including the shares listed on a recognized stock exchange  as referred to in Section 115QA,  shall be exempt."
        }
     ]
},
{

    question:"What is the tax rule relating to capital gains on compulsory acquisition of agricultural land?",

    items:[
        {
            text:"Section 10(37) Exemption of capital gains on compensation received on compulsory acquisition of  agricultural land situated within specified urban limits."
        },
        {
            text:"Any  capital gain (whether short Term or Long Term )arising to an individual or a Hindu Undivided Family from transfer of an agricultural land by way of compulsory acquisition  shall be exempt, provided the compensation or enhanced compensation or consideration, as the case may be, is received  on or after 1/4/2004."
        },
        {

            text:"The exemption is available only when  such land  has been used for agricultural purposes during the period of two years immediately preceding the date of compulsory acquisition by such individual or a parent of him or by such Hindu Undivided Family."
        }
        ]
},
{
    question:"What is the income tax rule for the  amount received as loan under the Reverse Mortgage Scheme?",
    items:[
        {
            text:"As per Section 10(43), any amount received by an individual as a loan, either as lump sum or in installments, in a transaction of reverse mortgage referred to in Section 47(xvi) shall be exempt."
        },
        {

            text:"Is there any exemption on income of New Pension SchemeTrust?"
        },
        {

            text:"Section 10(44) provides that any income received by a person for or on behalf of New Pension Scheme Trust under the provisions of IndianTrust Act of 1882 shall be exempt from income tax."
        }
]

},
{

    question:"What is the tax exemption provided to the income of Infrastructure Debt Funds?",
    items:[
        {
            text:"Section 10(47) lays down that any income from an Infrastructure Debt Fund set up in accordance with the guidelines as may be prescribed which is notified by the Central Government in the official gazette for the purposes of this clause, shall be exempt."
        }
    ]
},
{

    question:"What are the conditions under which income tax exemption is granted to certain foreign companies?",
    items:[
        {
            text:"According to Section 10(48), any income of a foreign company received in India in Indian currency on account of sale of crude oil, or any other goods or rendering of services, as may be notified by the Central Government to any person in India, shall be exempt subject to the following conditions being satisfied:"
        },
        {
            type:"list",
            list:[
                "The receipt of money is under an agreement or arrangement which is either entered into by the Central Government or approved by it;",
                "The foreign company and the arrangement or agreement has been notified by the Central Government having regard to the national interest in this regard.",
                "The receipt of money is the only activity carried out by the foreign company in India."
            ]
        }
    ]
},
{
    question:"Is there income tax exemption in the case of institutions which finance infrastructural development?",
    items:[
    {
        text:"Section 10(48D) lays down that Income of a notified institution established for financing of infrastructure development is tax exempt ."
    },
    {
        text:"Any income accruing or arising to an institution established for financing infrastructure development set up under an Act of Parliament and notified by the Central Government for the purposes of this clause, shall be exempt for a period of ten consecutive assessment years beginning from the assessment year relevant to the previous year in which such institution is set up."
    }
    ]
},
{

    question:"Is there any income tax exemption to units set up in Special Economic Zones?",
    items:[
        {
            type:"list",
            list:[
            "Section( 10AA) provides income tax exemption to newly established units in Special Economic Zones. ",
            "Deduction under this Section is available to all categories of assessees being entrepreneurs, i.e. Individuals, Firms, Companies etc who derive any income from the undertaking, being a unit engaged in the export of goods or providing service.",
            "This is applicable only to units manufacturing or producing  articles or service on or before 1/4/2024.",
            "Deduction under Section 10AA shall only be allowed if the proceeds  from the sale of goods or provision of services  are received  within six months  from the end of the previous year or within  such further period  as the competent  authority  may allow  on this behalf."
            ]
    }
    ]
}
]

//                 ___________________________________________

export {qandas}