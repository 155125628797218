import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const Profitandloss = ({  }) => {

    var items = [
        {
            type:"paragraph",
            text:"The Profit and Loss Statement/Account of a company reveals the financial health of the company, i.e. whether it is making any profit from its operations."
        },
        {
            type:"paragraph",
            text:"Profit / Loss is calculated by taking the revenue coming into the company from product sales or services provided and subtracting from it the different costs associated with running the company for the specified period."
        },
        {
            type:"paragraph",
            text:"The Profit and Loss Account is always computed for a time period, generally  a financial quarter or a financial year."
        },
        {
            type:"paragraph",
            text:"Let us look at an Example of a Profit and Loss Statement. We are showing you the Profit and Loss statement of HDFC Bank for the 2021-22"
        },
        {
            type:'table',
            url:"https://www.hdfcbank.com/content/bbp/repositories/723fb80a-2dde-42a3-9793-7ae1be57c87f/?path=/Footer/About%20Us/Investor%20Relation/annual%20reports/pdf/HDFC-Bank-IAR-FY22-21-6-22.pdf",
            title:"Consolidated Profit and Loss Account for HDFC Bank, March 31, 2022, for 21-22 FY, in crore Rs",
            headers:['', 'Schedule', 'Year ended Mar 31, 2022', 'Year Ended mar 31, 2021'],
            table:[
                ["I Income", " ", " ", ""],
                ["Interest earned", "13", "135,936.41", "128,552.40"],
                ["Other income", "14", "31,758.99", "27,332.88"],
                ["Total", "",  "167,695.40", "155,885.28"],
                ["II EXPENDITURE","","",""],
                ["Interest expended", "15", "58,584.33",  "59,247.59"],
                ["Operating expenses",  "16",  "40,312.43",  "35,001.26"],""
                ["Provisions and contingencies [Refer Schedule 18 (12)]", "",  "30,647.74",  "29,779.66"],
                ["Total", "",  "129,544.50",  "124,028.51"],
                ["III PROFIT", " ", "", ""],
                ["Consolidated Net Profit for the year before minorities' interest", "",  "38,150.90",  "31,856.77"],
                ["Less : Minorities' Interest", "",  "98.15",  "23.56"],
                ["Consolidated Net Profit for the year attributable to the group", "",  "38,052.75", "31,833.21"],
                ["Add: Brought forward consolidated profit attributable to the group",  "","78,594.20", "61,817.68"],
                ["Total"," ","116,646.95", "93,650.89"],
                ["IV APPROPRIATIONS", "", "", ""],
                ["Transfer to Statutory Reserve", " ", "9,444.38",  "7,879.70"],
                ["Dividend pertaining to previous year paid during the year", "", "3,592.40",  "-"],
                ["Transfer to General Reserve", "",  "3,696.14", "3,111.65"],
                ["Transfer to Capital Reserve", "", "666.47", "2,291.68"],
                ["Transfer to / (from) Investment Reserve Account", "", "233.13", "61.66"],
                ["Transfer to / (from) Investment Fluctuation Reserve", "", "-", "1,712.00"],
                ["Transfer to / (from) Minority Interest (opening adjustment)", "", "(48.34)", "-"],
                ["Balance carried over to consolidated balance sheet", "", "99,062.77", "78,594.20"],
                ["Total",  " " ,"116,646.95", "93,650.89"],
                ["V EARNINGS PER EQUITY SHARE (Face value Rs 1 per share)","", "Rs", "Rs"],
                ["Basic","", "68.77", "57.88"],
                ["Diluted", "","68.31", "57.61"] 

            ]
        }
    ]


    return (

        <>
        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Profit and Loss Statement</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default Profitandloss
