import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const Booksofaccounts = ({  }) => {

    var items = [
        {
            type:'paragraph',
            text:'Accounting / financial transactions are recorded in Books of Accounts. These are standardized formats that ensure that anybody who understands accounting can read the Books and understand the financial details of the company.'
        },
        {
            type:'paragraph',
            text:"Whether you are a small single-person business, a fast growing startup or a global business behemoth, recording every financial transaction in the right way is critical to the short and long term financial success of the company. Recording them in a standardized Books of Accounts format, thus, ensures that errors are minimized and it is easy for auditors and other financial personnel to understand the financial story of the company."
        },
        {
            type:'paragraph',
            text:"Maintaining detailed and accurate accounts helps to:"
        },
        {
            type:'list',
            list:[
                "keep a record of all financial transactions",
                "give a picture of the financial health of the company",
                "analyze the company’s performance and find potential areas of improvement",
                "project / forecast the company’s performance in the future and predict any future issues."
            ]
        },
        {
            type:'paragraph',
            text:"Basic financial transactions are recorded or show up in raw form as : "
        },
        {
            type:"list",
            list:[
                "Receipts through cheques, bank drafts, online transfers, net banking, cash deposited in the Bank, etc.",
                "Payments through cheques, bank drafts, net banking, online transfers, cash withdrawals"
            ]
        },
        {
            type:'paragraph',
            text:"We take the data from the records in raw form and add it into the Books of Accounts. "
        },
        {
            type:'paragraph',
            text:"In this article/chapter, we list out the different Books of Accounts and give quick descriptions of each of them. We will then go over each book in more detail in subsequent chapters."
        },
        {
            type:'paragraph',
            title:'Cash Book',
            text:"All the cash transactions of a company are entered in the Cash Book. The actual cash held by the company should match the difference between the credit and debit cash. The cash book can sometimes be treated as a page in the company’s General Ledger."
        },
        {
            type:'paragraph',
            title:'Bank Book',
            text:"All transactions going through the company’s bank accounts are entered in the Bank Book. This Book can sometimes be treated as a page in the company’s General Ledger."
        },
        {
            type:'paragraph',
            title:'Cash cum Bank Book',
            text:"Instead of maintaining cash book and bankbook separately, one single Cash cum Bank Book can be maintained to record all the cash and bank transactions."
        },
        {
            type:'paragraph',
            title:'Ledger',
            text:"All the accounting transactions are recorded in the company’s Ledger. The ledger has the different ‘Heads of Accounts’ under which the transactions are recorded."
        },
        {
            type:'paragraph',
            title:'Trial Balance',
            text:'The trial balance at a particular date contains the debit and credit balances of all the accounts in the ledger as well as the bank book and cash book. If the accounting books are correct, the sum of the debit and credit amounts of the accounts should be the same.'
        },
        {
            type:'paragraph',
            title:'Profit and Loss Account',
            text:'The Profit and Loss Account or Profit and Loss Statement reflects the profitability of the company. It takes the revenue of the company and computes the profitability by subtracting the costs incurred by the company in generating the profit.'
        },
        {
            type:'paragraph',
            title:'Balance Sheet',
            text:"A Balance Sheet summarizes the assets and liabilities of the company. The shareholder’s equity is included in the liabilities section. Any idea why that is so? If you are not sure, don’t worry. When you are done with all the different sections/chapters you will be able to answer it. The Total Assets should match the Total Liabilities of the company if the Balance Sheet is prepared correctly."
        },
        {
            type:'paragraph',
            title:'Cash Flow Statement',
            text:"As the name suggests, a Cashflow statement tracks the flow of cash into and out of a company. It is a very effective way to analyze how well the company is actually functioning. Here, cash implies money which could be actual paper cash or bank transactions."
        }


    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Books of Accounts</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default Booksofaccounts
