//Capital Gains Tax

var qandas = [

  {

    question:"What is Capital Gains tax?",
    items:[
      {
        text:"Capital Gains tax is a tax payable by an assessee on the capital gains made during the previous year on the transfer of any capital asset unless it is exempted under the provisions in the Act [ Sections 45 to 55A]."
      }
  ]
  },
  {
    question:"On what basis is Capital Gains tax levied?",
    items:[
      {
      text:"The conditions for levying capital gains tax in any previous year under Section 45(1) are:",
      },
      {
        type:"list",
        items:[
          " The capital asset must be owned by the assessee  in the previous year,",
          " The same capital asset must be transferred by the assessee and,",
          " There must be profits or gains on transfer of  the  capital asset in the previous year which is called capital gain."
        ]
      }

    ]
  },
  {

    question:"Are all assets treated as Capital Assets?",
    items:[
      {
      text:"No. The following assets are not treated as capital assets:"
      },
      {
        text:"(a) Stock in trade, raw materials and consumable stores (inventories used in business)."
      },
      {

      text:"(b) Personal effects i.e. movable assets used  for personal  use in the house, such as utensils, furniture and wearing apparel, but do not include the following:"

      },
      {
        type:"list",
        list:[
          "Jewellery",
          "Archaeological collections",
          "Drawings",
          " Paintings",
          " Sculptures",
          " Any art work"
        ]
      },
      {

        text:"(c) Rural Agricultural Land."
      },
      {

        text:"(d) Deposit certificates issued under the Gold Monetization scheme by the Reserve Bank Of India in  2015."
      },
      {

        text:"Note: Though the  building used for one’s own residence is a personal asset; it is not treated as a personal asset for computation of capital gain."
      }

  ]
  },

  {
      question:"How are Capital Assets classified?",
    items:[
      {   
        text:"The classification of Capital Assets is done on the basis of the period of holding of the asset."
      },
      { 
        text:"The period of holding of a capital asset is the period from the date of acquisition till the date of transfer. " 
      },
      {
        text:"On this basis, Capital Assets are classified into two :"
      },
      {
        type:"list",
        list:[
          " Short term capital assets",
          " Long Term capital assets"
        ]
      }
    ]
  },

  {


    question:"What are Short Term Capital Assets?",
    items:[
      {
        text:"Section 2(42A) lays down the different categories of short term capital assets on the basis of the period of holding."
      },
      {
        text:"1. The following  capital assets if held less than 12 months before the transfer:"
      },
      {
        type:"list",
        list:[
        "  Securities, including shares, listed in any recognized Stock Exchange in India",
        "  Units of Equity Oriented Fund",
        "  Zero Coupon Bonds"
        ]
      },
      {
        text:"2. The following assets if held less than 24 months before the transfer:"
      },
      {
        type:"list",
        list:[
          "Unlisted share of a company (not being a share listed in any recognized stock exchange  in  India",
          "Immovable property such as land and building or both"
        ]
      },
      {

        text:"3.  In the case of all other capital assets if they are held less than 36 months before the transfer of the asset."
      },
      {

        text:"The transfer of a capital asset without change of ownership to a third person is not considered a transfer for the purpose of computation of capital gain tax." 
      },
      {

        text:"For example, change of proprietorship to a partnership firm or a  company if there is only change in the legal entity.  "
      }
]

  },

  {


    question:"What are the conditions under which a period of holding is excluded or included?",
    items:[
        {
          text:"Under certain conditions a particular period of holding may be excluded or included."
        },
        {
          text:"Exclusion"
        },
        {
          type:"list",
          list:[
              "In the case of shares held in a company under liquidation, the period the company is under the liquidator is excluded."
          ]
        },
        {
          text:"Inclusion"
        },
        { 
          type:"list",
          list:[
            "Some of the situations wherein a particular period of holding is included are:",


          "Property acquired in any mode given under Section49 (1) i.e by  way of Gift, Will, Inheritance etc:  include the period of holding of the asset  by the previous owner.",


          "Inventory converted into or treated as capital asset by a person carrying on business [Section 28(via)]: the period will be reckoned from the date of conversion of the asset.",


          " Shares in an amalgamated company acquired in a scheme of amalgamation: the period of holding of shares in the amalgamating company by the assessee is included.",


          "Shares in an Indian resulting company acquired by the assessee in a scheme of demerger: include the period of holding of shares in the demerged company",
          " Trading or clearing rights of a Recognized Stock Exchange pursuant to its demutualization or corporatization: include the period for which the person was a member of the recognized Stock Exchange in India.",


          " Equity shares in a company acquired by a person pursuant to the demutualization or corporatization of a Recognized Stock Exchange: include the period for which the person was a member of the Recognized Stock Exchange in India.",


          " Capital asset being a unit of a business trust, allotted pursuant to transfer of share or shares as referred to in Section 47(xvii), any transfer of a capital asset being share of a special purpose vehicle to a business trust in exchange of units allotted to that trust :  include the period for which the share or shares were held by the assessee.  ",


          "Unit or units which become the property of the assessee in consideration of a transfer referred to in Section 47 (xviii) : include the period for which the unit or units in the consolidated scheme of the mutual fund were held by the assessee.",


          "The right to subscribe to the shares acquired by renouncement of an existing shareholder, the period shall be reckoned from the date of allotment of new shares.",


          " Period of holding of a financial asset allotted without any payment but on the basis of holding of any other financial asset e.g. bonus shares : the period will be reckoned from the date of allotment of such financial assets (not from the date of allotment of original shares).",


          "Period of holding of specific security or sweat equity shares allotted or transferred directly or indirectly, by the employer free of cost or at concessional rate to his employees and to former employees: the period will be reckoned from the date of allotment or transfer of such specific security or sweat equity shares."
          ]

      }

    ]

  },

  {


    question:"What does transfer of a capital asset mean?",
    items:[
      {
        text:"As per section 2(47), transfer in relation to a capital asset means and includes:"
      },
      {
        type:"list",
        list:[
          "Sale, exchange or relinquishment of any capital asset",


          "The extinguishment of any rights in the capital asset",


          "Compulsory acquisition of any capital asset under any statue by any empowered authority",


          "Conversion of capital asset into stock in trade in a business carried on by the assessee",


          " The maturity or redemption of zero coupon bonds."
        ]
      }
    ]

  },
  {

    question:"When is Capital Gain Tax due and payable?",
    items:[
      {
          text:"In the normal course, the Capital Gain should arise in the previous year in which the transfer of the capital asset takes place."
      },  
      {
          text:"However, in the following cases, the capital gain tax is payable only in the previous year in which the actual payment is received by the assessee:"
      },
      {
        type:"list",
        list:[
          "Conversion of capital assets into stock in trade. Capital gain tax is payable in the previous year in which the capital asset converted as stock-in-trade is sold",
          "Compensation received on damage or destruction of any capital asset. Capital gain tax is payable in the  previous year in which the compensation is received and not in the year the asset is damaged,", 
          "Compensation received on compulsory acquisition of a capital asset; Capital gain tax is payable in the  previous year, in which the ; compensation is received and not in the year in which the asset is ;acquired by the competent authority.,",
          "Transfer of land and building or both by an individual or HUF to a developer under a development agreement",
          "The  capital gain will arise in the previous year in which the building; completion certificate  is issued by the competent authority and not in; the year in which the asset is handed over to the developer."
        ]
        }

    ]

  },

  {

    question:"How is Capital Gain computed?",
    items:[
        {
          text:"Capital gain is basically the difference between the consideration received and the cost of acquisition of the capital asset, subject to indexation of the cost on the long term capital asset, if indexation is applicable."
        }
    ]

  },

  {

    question:"What is meant by the full value of consideration of a capital asset ?",
    items:[
      {
        text:"Full value of consideration of a capital asset is the amount received or receivable by the transferor on transfer of the capital asset without any deduction being made from the consideration."
      }
  ]

  },

  {

      question:"What is taken as consideration, on transfer of a Capital Asset, for the purpose of calculating Capital Gains Tax?",
      items:[
        {
          text:"While computing capital gains, the full value of consideration  is to be taken into account."
        },
        {
          text:"Full value of consideration of the capital asset is the amount received or receivable by the transferor on transfer of the capital asset without any deduction being made from the consideration."
        },
        {
          text:"The consideration has nothing to do with the market value of the capital asset if the consideration received is in cash."
        },
        {
          text:"In the case of exchange of goods, the market value of the goods exchanged by the transferee on the date of transfer is the full value of consideration. "
        }
    ]
  },

  {


      question:"How is short term capital gain computed?",
      items:[
        {
          text:"Short term capital gain arises on the transfer of a short term capital asset."
        },
        {
          text:"The gain is the difference between the full value of consideration received or receivable and the cost of the short term capital asset."
        },
        {
          text:"This is computed as:"
        },
        {
          type:"list",
          list:[
            "Full value of consideration of the short term capital asset received or receivable, ",
            "LESS",
            "Cost of short term capital asset which comprises of:",
            "Cost of acquisition,",
            "Cost of improvement, and" ,
            "Transfer cost."
          ]
        }
      ]

  },

  {

      question:"How is long term capital gain calculated?",
      items:[
          {
              text:"The long term capital gain is the difference between the full value of consideration received or receivable and the indexed cost of the long term capital asset."
          },
          {
              text:"This is computed as:"
          },
          {
            type:"list",
            list:[
              "Full value of consideration of the Long Term Capital asset",
              "Less",
              "Indexed Cost of the long term capital asset, which includes:",
              "Indexed cost of acquisition,",
              "Indexed cost of improvement, and",
              "Transfer cost."
            ]
          }
      ]

  },
  {

      question:"Is indexation allowed in the case of short term capital assets?",
      items:[
        {
          text:"No. Indexation is not allowed in the case of short term capital assets."
        },
        {
          type:"list",
          list:[
            "Indexation is assumed to be the increase in the cost of the capital asset due to changes in the cost of living index.",

            "The cost of living index is the effect on the prices of consumer goods due to inflation which is called the Consumer Price Index. ",


            "The indexation cost i.e. cost inflation index, in the case of long term capital asset is taken at 75% of the consumer price index to arrive at the indexed cost of acquisition and indexed cost of improvement for computation of long term capital gain.",


            "In the case of transfer of a long term capital asset, indexation is taken as the increase in the cost of the asset from the previous year of acquisition till the previous year in which the capital asset is transferred."
          ]
      },
        {

          text:"The Income Tax Department publishes an updated indexation table every year."
        }
    
    ]

  },

  {



    question:"What is meant by the cost of acquisition of a capital asset?",
    items:[
      {
        text:"According to [Section 55(2)], the cost of acquisition of a capital asset includes:"
      },
      {
        type:"list",
        list:[
          "the actual price paid for the capital asset",
          "duty and tax ",
          "freight charges",
          "loading and unloading charges",
          " transit insurance",
          " interest paid on the borrowing cost",
          " installation charges",
          " other charges  incurred on the capital asset till the asset is put into use."
        ]
      }
    ]

  },

  {


    question:"What is meant by Deemed Cost of Acquisition of a Capital Asset?", 
    items:[
      {
        text:"According to {Section49 (1)}, If an assessee has not paid for the capital asset, but acquired it by any other means including gift, will or inheritance etc, the cost of the previous owner will be treated as the cost of acquisition."
      },
      {
        text:"If the previous owner did not incur any cost, then the cost of acquisition will be nil."
      }
    ]
  },

  {


      question:"What is meant by ‘Slump Sale’?",
      items:[
        {
          text:"‘Slump sale’ means the transfer of one or more undertakings, by any means, for a lump sum consideration without values being assigned to the individual assets and liabilities."
        }
      ]
  },

  {


    question:"What is the capital gain on maturity or redemption of Zero Coupon Bonds?",
    items:[
      {
        type:"list",
        list:[
          "The capital gain on redemption or maturity of Zero Coupon Bonds will be treated as long term Capital Gain and taxed at 10%.",


          " The difference between the amount paid for the bond on issue and the amount received  on redemption or maturity will be the long-term capital gain.",
          "No indexation is allowed on the cost of Zero Coupon Bonds. "
        ]
      }
    ]
  },

  {


    question:"Does  Capital Gain apply on buy back of shares or securities by a company?",
    items:[
      {
        text:"When a company buys back its own shares or securities from the holders of shares or securities through a Recognized Stock Exchange after paying Securities Transaction Tax, capital gain tax is applicable on the difference between the cost and buyback price ."
      },
      {
        text:"If the equity shares and units of equity-oriented funds are brought back by the company within one year, it is a short term capital asset and if brought back after12 months,  it is a long term capital asset."
      }
    ]

  },

  {

    question:"Is there Capital Gains tax on transfer of shares and securities acquired by a non-resident in foreign currency?",
    items:[
      {
        text:"Capital Gain tax is payable on all  the shares and  securities purchased by a non-resident in foreign currency. "
      },
      {
        text:"Surcharge and Health and Education cess will be added separately along with the IncomeTax."
      },
      {
        text:"Long TermCapital Gains tax exempted [Section 54, 54B, 54D,54E,54EE, 54F, 54G ,54G1,54G2]."
      },
      {
        text:"Is there exemption of long term capital gain on transfer of a residential house property? "
      },
      {
        text:"According to Section 54, (applicable only to individuals and HUF),the long term capital gains arising to an individual or HUF in any previous year from the transfer of a Residential house property, is exempted,"
      },
      { 
        text:"IF the proceeds are  re-invested in new, one or two residential house properties, up to a maximum of Rs 2 crores, either by:"
      },
      {
        type:"list",
        list:[
          " purchase within one year before or two years after the date of  transfer, or,",
          " constructed  one or two new  residential house properties  within three years from the date of transfer."
        ]
    }
]
  },

  {

    question:"What is the rule if the capital gain is not utilized  within the specified time limit?",
    items:[
        {
          text:"If the capital gain is not fully used on or before the due date for filing the return of income of the previous year in which the transfer took place, then:"
        },
        {
          type:"list",
          list:[
            "the unused portion of the capital gain should be deposited in a Capital Gains Bank Account under the Capital Gains Account scheme with any approved bank, and ,",
            "When funds are required for purchasing or constructing the new residence or  residences, the amount deposited in the Capital Gains bank account can be withdrawn."
          ]
      },
        {

          text:"However, if the capital gain is not fully utilized within three years from the date of transfer  for the new house or houses:"
        },
        {
            type:"list",
            list:[
              "The unutilised portion of the capital gain will be subject to long term capital gain tax under Section 45 in the previous year in which the three years after the date of transfer expires.",
              "Similarly, if the newly acquired house or houses are transferred within three years from the date of acquisition, the exemption on the long term capital gains tax allowed previously will be forfeited and the capital gain tax will be payable in the previous year in which the newly acquired house or houses from the capital gains transferred."
            ]
      },
        {
          text:"The Finance Act 2023  has  provided that the maximum  exemption  under section 54 is Rs 10 crores for individuals and HUF."
        }
    ]
  },

  {

    question:"What is the capital gain rule regarding transfer of urban agricultural land?",
    items:[
      {
        text:"The rule relating to Capital gain on transfer of  an Urban Agricultural Land owned by an individual or HUF has been laid down in Section 54B."
      },
      {
        type:"list",
        list:[
          " If the long term capital gain on the transfer of an Agricultural Land is reinvested in another agricultural land within two years from the date of transfer, the full amount or the actual amount spent, whichever is less, will be allowed as deduction.",


          "One of the conditions for exemption is that the  agricultural land transferred should have been used by the assessee himself or his parents for agricultural purposes at least for two years before the transfer.",


          "If the capital gain is not fully utilized for purchasing  the new agricultural land before filing the return of income, then the unutilized portion should be deposited in a Capital Gains  Bank Account under the Capital Gains Account Scheme with an approved bank. It can be withdrawn from the bank at any time for the purchase of new agricultural land but before two years from the date of transfer. ",


          "If the full amount is not utilized within two years from the date of transfer for the purchase of another urban agricultural land, the unutilised  portion of capital gain will be subject to capital gain tax in the previous year in which the  period of two years expires  from the date of original transfer. ",


          " If the new agricultural land is sold within three years from the date of purchase, the exemption will be forfeited and the capital gain tax will be charged on the full amount of capital gain after the expiry of three years."
        ]

      },
    {

      text:"Capital Gain on compulsory acquisition of land and building of an industrial undertaking. [Section 54D]"
    },
    { 
      text:"The capital gain on compulsory acquisition of land and building or rights in the land and building forming part of an industrial undertaking is exempted, provided:"
    },
    {
      type:"list",
      list:[
        "the land and building should have been used by the assessee for industrial purposes at least two years before the date of  compulsory acquisition,  AND ",
        "the capital gain is reinvested in another land and building or rights  in the land and building of an industrial undertaking within three years from the date of transfer either by purchase or construction of another industrial building."
      ]
    },
    {
      text:"In the case of a building being used in the  industrial undertaking and being a depreciable asset, the capital gain will be short term whereas the capital gain on the land can be either short or long term capital gain. "
    },
    {
      type:"list",
      list:[
        "If the assessee does not reinvest the full  capital gain on another  land and building on or before the due date for filing the return of income, then the unused portion of capital gain should be deposited in a capital gains account under the capital gains account scheme with an approved bank and as and when  funds are  needed in future  for the  land and building ,it can be withdrawn from  the capital gains  account.",
        "If the newly acquired land and industrial building is transferred within three years from the date of acquisition ,the capital gain exempted earlier will be forfeited and the same will be subjected to capital gains tax in the year in which the transfer of  newly acquired land and building took place."
      ]
}

    ]

  },
  {

    question:"Does investment of capital gains on Specified Bonds secure exemption from capital gains tax?",
    items:[
        {
          text:"Long term capital gains on transfer of  land and building, if invested  on certain Specified Bonds will be exempted. [Section 54EC]"
        },
        {
          type:"list",
          list:[
            "If the long term capital gains on the transfer of land and building or both are invested in specified bonds notified by the Central Government within six months from the date of transfer, the amount of capital gain will be exempted to the extent of the capital gain invested on the bonds subject to a maximum of Rs 50 lakhs .",


            "If the investment on the specified bonds is encashed or used as security for raising money within the next five years, the exemption will be forfeited in the same year in which it is used for the above purpose and the capital gain will be subject  to capital gains tax.",


            " Similarly the bond should not be sold within the next five years from the date of purchase  and if done so, the exemption will be forfeited and capital gains tax will be applicable on the full amount of capital gains.",
            "The specified bonds are the bonds issued by National Highway Authority of India or Rural Electrification Corporation of India."
          ]
    }

    ]

  },

  {


    question:"Can capital gains be invested in Mutual Funds to secure exemption from taxation?",
    items:[
      {
        text:"According to Section 54EE, long term capital gain on the transfer of all the  long term capital assets will be exempted, if invested within six months from the date of transfer, on the units of mutual funds approved and notified by the Central Government."
      },
      {
        type:"list",
        list:[
            "The investment in the units of mutual funds should not be encashed or used as security for raising money .",


            "They should not be transferred within the next three years from the date of purchase and if done so the exemption will be automatically cancelled and capital gain tax will be levied on the full amount of capital gain in the previous year in which the above conditions are violated. ",


            "he maximum amount exempted under Section  54EE is Rs 50 lakhs."
        ]
      }
    ]
  },
  {


      question:"What is the rule if long term capital gain on a long term capital asset, other than residential property, is invested in residential property?",
      items:[
          {
            text:"Section 54F lays down the rule in this connection."
          },
          {

            text:"The long term capital gain on any long term capital asset other than residential house property, will be exempted, IF"
          },
          {
            type:"list",
            list:[
              " invested in any other residential house property either by purchase within one year before or two years after the date of  transfer, OR",


              " constructed any other  residential house property within three years from the date of transfer.",


              "However, the assessee should not own more than one residential house property excluding the one newly purchased as on the date of transfer.",
              "Further, he should not purchase another residential house property within a period of  one year from the date of original transfer.",


              " The assessee should not transfer the new house property within the next three years from the date of acquisition.",


              " If the full value of consideration is not invested in the new residential house property on or, before the due date of filing the return of income,the unused amount should be deposited in the Capital Gains Bank  Account under the Capital Gains Account Scheme and can be withdrawn as and when needed for the new residential house property.",


              "If the amount deposited in the capital gains account is not utilized within three years from the date of transfer, the unused amount will be subjected to long term capital gains tax after the expiry of three years from the date of original transfer.",


              "The Finance Act has provided that the maximum exemption under this section for individuals and HUF is Rs 10 crores. "
            ]
      }
    ]
  },

  {


                              
    question:"What is meant by the term ‘eligible company’?",
    items:[
      {
        text:"Eligible company means a company which fulfills the following conditions:"
      },
      {
        type:"list",
        list:[
          "It is a company incorporated in India during the period from 1st April of the previous year in which the capital gain arises to the due date of filing the return of income of the same previous year under Section 139(1).",


          "It is engaged in the business of manufacture of an article or eligible business.",


          "It is a company in which the assessee has more than 25% share capital or 25% voting rights after the subscription by the assessee. ",


          "It is a company which qualifies to be a small or medium enterprise under the Micro, Small and Medium Enterprises Act 2006 or is an eligible start up."
        ]
      }
    ]

  },

  {

      question:"When does the period for acquiring new assets or depositing / investing the capital gain amount begin, in case of compulsory acquisition?",
      items:[
          {
            text:"The period for investment or deposit will be reckoned from the date of receipt of compensation or enhanced compensation instead of the original date of transfer because capital gains tax is payable in the previous year in which the compensation is received."
          }
    ]

  },

  {


      question:"What is the short term capital gain in the case of equity shares, units of equity oriented fund and units of business trust and unit linked insurance policy?",
      items:[
          {
            text:"As per Section 111A and Section 111A (ULIP) where there is  transfer of short term capital assets, being equity shares in a company, units of equity oriented fund, units of business trust and unit-linked insurance policy through a recognized stock exchange, and transaction tax has been paid, the  short term capital gains tax on these assets is 15%."
          }
      ]
  }

]


export {qandas}

//—----------------------------