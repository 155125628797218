
import './App.css';

import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import AboutMe from './components/layout/AboutMe'
import Disclaimer from './components/layout/Disclaimer'

import HomeScreen from './screens/HomeScreen'
import TaxFAQsScreen from './screens/TaxFAQsScreen'
import TablesScreen from './screens/TablesScreen'
import TablesScreenV2 from './screens/TablesScreenV2'
import TableBaseScreen from './screens/TableBaseScreen'
import TableExampleScreen from './screens/TableExampleScreen'

import TablesIndexScreen from './screens/TablesIndexScreen'

import LandingPageScreen from './screens/LandingPageScreen'

import AccountingApp from './screens/FinApps/AccountingApp'

import FintabbScreen from './screens/FintabbScreen'

const App = () => {
  return (

    <Router>
      <Header />
      <main className='py-3'>

        <Container fluid>

        <Switch>

          <Route path='/' component={LandingPageScreen} exact />

          <Route path='/accounting' component={HomeScreen} exact />
          
          <Route path='/accounting/:section_url' component={HomeScreen} exact />


          <Route path='/itataglance' component={TaxFAQsScreen} exact />

          <Route path='/itataglance/:section_url' component={TaxFAQsScreen} exact />



          <Route path='/income-tax-faqs' component={TaxFAQsScreen} exact />

          <Route path='/income-tax-faqs/:section_url' component={TaxFAQsScreen} exact />

          
{/*           
          <Route path='/fintabb' component={FintabbScreen} exact /> */}


          {/* <Route path='/fintabb' component={TablesIndexScreen} exact />

          <Route path='/fintabb/:example_id' component={TableExampleScreen} exact />


          <Route path='/fintabbs' component={FintabbScreen} exact />



          <Route path='/tables' component={TablesScreen} exact />

          <Route path='/tablesv2' component={TablesScreenV2} exact />

          <Route path='/tablesbase' component={TableBaseScreen} exact /> */}


          {/* <Route path='/accountingapp/:case_id' component={AccountingApp} exact />
 */}



          <Route component={HomeScreen} />


        </Switch>

        </Container>


      </main>
      <AboutMe />
      <Disclaimer />
      <Footer />
    </Router>
  )
}





export default App

