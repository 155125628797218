import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const Journal = ({  }) => {

    var items = [
        {
            type:'paragraph',
            text:'Every firm makes financial transactions on a daily basis. These may be related to purchase, sale or any other financial activity. It is very important that the details of all these transactions are recorded properly and systematically in such a way that it is easy to compile and use at any point of time. It is for this purpose that Journals are maintained.'
        },
        {
            type:'paragraph',
            text:"There are mainly three kinds of Journals."
        },
        {
            type:'list',
            list:[

                "Purchase Journal :  contains journal entries for credit purchases and issue of debit notes for goods returned by the buyer to the seller.",
                "Sales Journal : contains journal entries for credit sales and issue of credit notes for the goods returned by the buyer.",
                "General Journal : used for passing journal entries relating to accrued expenses & income and adjustments & rectification of mistakes.",
            ]
        },
        {
            type:'header',
            text:"Purchase Journal"
        },
        {
            type:'paragraph',
            text:"This contains:"
        },
        {
            type:'list',
            list:[
                "Journal entries for credit purchases of goods.",
                "Debit Notes for goods returned by the buyer to the seller due to complaints like defect in the goods, poor quality etc."
            ]
        },
        {
            type:'paragraph',
            text:"Purchase Journal and Purchase Return Journal can, also, be maintained separately."
        },
        {
            type:'header',
            text:"Sales Journal"
        },
        {
            type:'paragraph',
            text:"This contains:"
        },
        {
            type:'list',
            list:[
                "Journal entries for credit sales of goods.",
                "Credit Notes issued by the supplier for goods returned by the buyer because of poor quality, defects etc."
            ]
        },
        {
            type:'paragraph',
            text:"Sales Journal and Sales Return Journal can be maintained separately in case of large number of such transactions."
        },
        {
            type:'header',
            text:"General Journal"
        },
        {
            type:'paragraph',
            text:"This is used to record :"
        },
        {
            type:'list',
            list:[
                "transactions which have not been recorded in other journals",
                "entries relating to accrued expenses and income",
                "entries relating to adjustment and rectification of mistakes",
                "transfer of funds from one account to another"
            ]
        },
        {
            type:'paragraph',
            text:"The transaction details which are entered in the Journals are then posted on the Ledgers."
        },
        {
            type:'header',
            text:"Passing Journal Entries"
        },
        {
            type:'paragraph',
            text:"A Journal Entry is a way to record every debit and credit involved in a financial transaction. Transactions that are not simple enough to be recorded on an invoice or a voucher can be recorded in a Journal Entry. The entries from the Journal can then be posted in the corresponding ledger account."
        },
        {
            type:'paragraph',
            title:"Types of Journals",
            text:""
        },


    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Journal</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default Journal
