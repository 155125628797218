//Assessment of Association of Persons

var qandas = [

{
    question:"How are Association of Persons (AOP), Body of Individuals (BOI) and Artificial Judicial Person (AJP) assessed for the purpose of Income Tax?",
    items:[
        {
            text:"The  Finance Act, 2023 has made the new tax  regime applicable  to every Association of Persons, Body of Individuals  and Artificial Judicial Person."
        },
        {
            type:"list",
            list:[
                "No basic exemption",
                "No income slab system ",
                "Rate of tax is 30% ",
                "Surcharge and Health and Education Cess are applicable."
            ]        
        }


        ]
}

]


export {qandas}
                        