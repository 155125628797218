import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const Ledger = ({  }) => {

    var items = [

        {
            type:'paragraph',
            text:"A Ledger is a book which is used to record the financial activities of a business under different Heads of Account. It may be maintained in book form or digitally."
        },
        {
            type:'paragraph',
            text:"Bases on the purpose and scope of use, ledgers can be classified into three kinds."
        },
        {
            type:'header',
            text:"General Ledger"
        },
        {
            type:'paragraph',
            text:"This is the main or principal book of account in which details of all financial transactions of an enterprise are recorded on the basis of Journal entries. It contains all the Nominal Accounts and Control Accounts. It forms the base or foundation for the preparation of financial statements like Trial Balance, Balance Sheet, etc."
        },
        {
            type:'header',
            text:"Creditors’ Ledger"
        },
        {
            type:'paragraph',
            text:"This is also known as Purchase Ledger or Accounts Payable Ledger. It is used to note and maintain the Accounts of all the creditors / suppliers from whom credit purchases have been made. It, therefore, reveals the total amount owed by the enterprise to its suppliers."
        },
        {
            type:'header',
            text:"Debtors’ Ledger"
        },
        {
            type:'paragraph',
            text:"It is also known as Sales Ledger. It is used to maintain the Debtors’ Accounts, i.e. the financial details relating to the customers or buyers who have purchased goods on credit from the firm. It, therefore, helps to track which customers owe money to the enterprise and how much. Now that we know the basic terms relating to accounting, let’s learn how financial details are entered in the Books of Account."
        },


    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Ledger</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default Ledger
