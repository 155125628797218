import { 
    ADD_FORMULA,
    UPDATE_FORMULA,
    DELETE_FORMULA,
    FORMULA_FAIL,
    CLEAR_FORMULA,

    SELECTED_FORMULA,
    SELECTED_FORMULA_UPDATE,
    SELECTED_FORMULA_FAIL

 } from "../constants/formulaConstants"


export const formulalistReducer = (state = { formulas:[]}, action) => {

    switch(action.type){
        case ADD_FORMULA:
            return { loading: false, 
                formulas: [action.payload, ...state.formulas], 
                }

        case UPDATE_FORMULA:
            return { loading: false, 
                formulas: state.formulas.map(formula => formula.id === action.payload.id ? { ...formula, expression: action.payload.expression, operations: action.payload.operations} : formula)
            }

        case DELETE_FORMULA:
            return { loading: false, 
                plots: state.formulas.filter(formula => formula.id !== action.payload),
            }

        case FORMULA_FAIL:
            return { loading: false, error: action.payload, formulas: state.formulas }

        case CLEAR_FORMULA:
            return { loading: false, formulas: [] }


        default:
            return state
    }

}



export const selectedformulaReducer = (state = { selectedformula:null}, action) => {

    switch(action.type){
        case SELECTED_FORMULA:
            return { loading: false, 
                    selectedformula: action.payload, 
                }

        case SELECTED_FORMULA_UPDATE:
            return { loading: false, 
                selectedformula: action.payload, 
            }

        case SELECTED_FORMULA_FAIL:
            return { loading: false, 
                selectedformula: {}, 
            }


        default:
            return state
    }

}