import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

import ToolMain from '../tool/ToolMain'
import ToolIntro from '../tool/ToolIntro'

const ACLTool = ({  }) => {

 


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>FinTabb</p>
            </Card.Header>  
        </Card>

        {/* <p>We are giving you access to some simple modules to help you try out the accounting concepts we have covered in this smart book.</p> */}


        <ToolIntro />


        {/* <ToolMain /> */}


        </>


    )
}


export default ACLTool
