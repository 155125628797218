import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';


import Meta from '../components/Meta'

import BalanceSheet from '../components/book/BalanceSheet'
import Casestudy from '../components/book/Casestudy'
import Cashflow from '../components/book/Cashflow'
import FinancialTransactions from '../components/book/FinancialTransactions'
import Invoicesandreceipts from '../components/book/Invoicesvsreceipts'
import Journal from '../components/book/Journal'
import Ledger from '../components/book/Ledger'
import Profitandloss from '../components/book/Profitandloss'
import Ratios from '../components/book/Ratios'
import TrialBalance from '../components/book/TrialBalance'
import Whatisaccounting from '../components/book/Whatisaccounting'
import DoubleEntryBookkeeping from '../components/book/DoubleEntryBookkeeping'
import Cashbasisvmercantile from '../components/book/Cashbasisvmercantile'
import Booksofaccounts from '../components/book/Booksofaccounts'
import Incomevsexpenditure from '../components/book/Incomevsexpenditure'
import Assetsandliabilities from '../components/book/Assetsandliabilities'
import Typesofregisters from '../components/book/Typesofregisters'
import Whythisbook from '../components/book/Whythisbook'
import BankBook from '../components/book/BankBook'
import CashBook from '../components/book/CashBook'
import AnalysisFinancialStatements from '../components/book/AnalysisFinancialStatements'
import ACLTool from '../components/book/ACLTool'


import {accSEOTitle, accSEODescription, accSEOTags} from '../seo/AccountingSEO'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";



export const HomeScreen = ({ match, history }) => {


    const dispatch = useDispatch()


    const curl = match.params.section_url

    const [chapterSelect, setChapterSelect] = useState("whythisbook") 
    const toggleChapterSelect = (chapter_in) => {

        console.log("Selected Chapter ", chapter_in)
        setChapterSelect(chapter_in)

    }


    const url_chapter_map = {
        'why-this-book':'whythisbook',
        'the-fintabb-tool':'acltool',
        'what-is-accounting':'whatisaccounting',
        'double-entry-bookkeeping':'doubleentrybookkeeping',
        'invoices-and-receipts':'invoicesandreceipts',
        'cash-basis-vs-mercantile':'cashbasisvsmercantile',
        'income-vs-expenditure':'incomevsexpenditure',
        'assets-and-liabilities':'assetsandliabilities',
        'books-of-accounts':'booksofaccounts',
        'types-of-registers':'typesofregisters',
        'what-is-a-ledger':'ledger',
        'what-is-a-journal':'journal',
        'what-is-a-bankbook':'bankbook',
        'what-is-a-cashbook':'bankbook',
        'basics-of-a-trialbalance':'trialbalance',
        'understanding-a-balancesheet':'balancesheet',
        'decoding-a-profit-and-loss-statement':'profitandloss',
        'what-is-a-cashflow-statement':'cashflow',
        'analysis-of-financial-statements':'analysisfinancialstatement',
        'list-of-financial-ratios':'ratios'

    }


    useEffect(() => {
 
        if(curl){
            setChapterSelect(url_chapter_map[curl])
        }


    }, [curl])




    return (
        <>

            {curl ? (
            <Meta title={accSEOTitle[curl]} description={accSEODescription[curl]} keywords={accSEOTags[curl]}/>

            ):
            (
                <Meta title={accSEOTitle['why-this-book']} description={accSEODescription['why-this-book']} keywords={accSEOTags['why-this-book']}/>

            )}

            <p className='h3' style={{textAlign:'center'}}>Learn the Basics of Accounting</p>


            {/* <Form>

            <InputGroup>
            <Form.Control style={{padding:'5px', margin:'5px'}} type='text' placeholder='Search for what you want' value={searchText} onChange={(e) => setSearchText(e.target.value)}> 
            </Form.Control>

            <Button onClick={setSearchRequest} className='button1' style={{paddingTop:'0px', paddingBottom:'0px', borderRadius:'10px'}}>
                Search
            </Button>
            </InputGroup>
            </Form> */}


            {isMobile && (

                <Row>
                    <Col>


                <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Chapters
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('whythisbook')}>Why this book?</Dropdown.Item>

                    <Dropdown.Item onClick={()=>toggleChapterSelect('whatisaccounting')}>What is Accounting?</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('doubleentrybookkeeping')}>Double Entry Method of Bookkeeping</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('invoicesandreceipts')}>Invoices vs Receipts</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('cashbasisvsmercantile')}>Cash vs Mercantile</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('incomevsexpenditure')}>Income vs Expenditure</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('assetsandliabilities')}>Assets and Liabilities</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('booksofaccounts')}>Books of Accounts</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('typesofregisters')}>Types of Registers</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('ledger')}>Ledger</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('journal')}>Journal</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('bankbook')}>Bank Book and Cash Book</Dropdown.Item>
                    {/* <Dropdown.Item onClick={()=>toggleChapterSelect('cashbook')}>Cash Book</Dropdown.Item> */}
                    <Dropdown.Item onClick={()=>toggleChapterSelect('trialbalance')}>Trial Balance</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('balancesheet')}>Balance Sheet</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('profitandloss')}>Profit and Loss</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('cashflow')}>Cash Flow</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('analysisfinancialstatement')}>Analysis of Financial Statements</Dropdown.Item>
                    <Dropdown.Item onClick={()=>toggleChapterSelect('ratios')}>Ratios</Dropdown.Item>
                    {/* <Dropdown.Item onClick={()=>toggleChapterSelect('casestudy')}>Case Studies</Dropdown.Item> */}
                    <Dropdown.Item onClick={()=>toggleChapterSelect('acltool')}>The FinTabb Tool</Dropdown.Item>

                </Dropdown.Menu>
                </Dropdown>
                    
                    
                    </Col>
                </Row>

            )}




            <Row>


                {!isMobile && (

<Col style={{maxWidth:'15vw', maxHeight:'95vh', overflow:'scroll', padding:'0px', paddingLeft:'5px'}}>

                    

<ListGroup>
    <ListGroup.Item style={{backgroundColor:'gray', color:'white'}}>
        <p className='h5'>Sections</p>
    </ListGroup.Item>

    <ListGroup.Item>
        <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('whythisbook')}>
            <p className='h5'>Why this book?</p>
        </Card>
    </ListGroup.Item>

    <ListGroup.Item>
        <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('whatisaccounting')}>
            <p className='h5'>What is Accounting?</p>
        </Card>
    </ListGroup.Item>

    <ListGroup.Item>
        <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('doubleentrybookkeeping')}>
            <p className='h5'>Double Entry Method of Bookkeeping</p>
        </Card>
    </ListGroup.Item>

    <ListGroup.Item onClick={()=>toggleChapterSelect('invoicesandreceipts')}>
    <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('invoicesandreceipts')}>
        <p className='h5'>Invoices vs Receipts</p>
    </Card>
    </ListGroup.Item>

    <ListGroup.Item onClick={()=>toggleChapterSelect('cashbasisvmercantile')}>
    <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('cashbasisvmercantile')}>
        <p className='h5'>Cash vs Mercantile</p>
    </Card>
    </ListGroup.Item>

    <ListGroup.Item onClick={()=>toggleChapterSelect('incomevsexpenditure')}>
    <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('incomevsexpenditure')}>
        <p className='h5'>Income vs Expenditure</p>
    </Card>
    </ListGroup.Item>

    <ListGroup.Item onClick={()=>toggleChapterSelect('assetsandliabilities')}>
    <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('assetsandliabilities')}>
        <p className='h5'>Assets and Liabilities</p>
    </Card>
    </ListGroup.Item>

    <ListGroup.Item onClick={()=>toggleChapterSelect('booksofaccounts')}>
    <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('booksofaccounts')}>
        <p className='h5'>Books of Accounts</p>
    </Card>
    </ListGroup.Item>

    <ListGroup.Item onClick={()=>toggleChapterSelect('typesofregisters')}>
    <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('typesofregisters')}>
        <p className='h5'>Types of Registers</p>
    </Card>
    </ListGroup.Item>

    {/* <ListGroup.Item onClick={()=>toggleChapterSelect('financialtransaction')}>
    <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('financialtransaction')}>
        <p className='h5'>Financial Transaction</p>
    </Card>
    </ListGroup.Item> */}


    <ListGroup.Item onClick={()=>toggleChapterSelect('ledger')}>
        <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('ledger')}>
        <p className='h5'>Ledger</p>
        </Card>
    </ListGroup.Item>


    <ListGroup.Item onClick={()=>toggleChapterSelect('journal')}>
    <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('journal')}>
        <p className='h5'>Journal</p>
    </Card>
    </ListGroup.Item>

    <ListGroup.Item onClick={()=>toggleChapterSelect('bankbook')}>
    <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('bankbook')}>
        <p className='h5'>Bank Book and Cash Book</p>
    </Card>
    </ListGroup.Item>


    {/* <ListGroup.Item onClick={()=>toggleChapterSelect('cashbook')}>
    <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('cashbook')}>
        <p className='h5'>Cash Book</p>
    </Card>
    </ListGroup.Item> */}


    <ListGroup.Item onClick={()=>toggleChapterSelect('trialbalance')}>
        <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('trialbalance')}>
        <p className='h5'>Trial Balance</p>
        </Card>
    </ListGroup.Item>

    <ListGroup.Item onClick={()=>toggleChapterSelect('balancesheet')}>
        <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('balancesheet')}>
        <p className='h5'>Balance Sheet</p>
        </Card>
    </ListGroup.Item>

    <ListGroup.Item onClick={()=>toggleChapterSelect('profitandloss')}>
        <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('profitandloss')}>
        <p className='h5'>Profit and Loss</p>
        </Card>
    </ListGroup.Item>

    <ListGroup.Item onClick={()=>toggleChapterSelect('cashflow')}>
        <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('cashflow')}>
        <p className='h5'>Cash Flow</p>
        </Card>
    </ListGroup.Item>

    <ListGroup.Item onClick={()=>toggleChapterSelect('analysisfinancialstatement')}>
        <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('analysisfinancialstatement')}>
        <p className='h5'>Analysis of Financial Statements</p>
        </Card>
    </ListGroup.Item>

    <ListGroup.Item onClick={()=>toggleChapterSelect('ratios')}>
        <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('ratios')}>
        <p className='h5'>Ratios</p>
        </Card>
    </ListGroup.Item>


    <ListGroup.Item>
        <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('acltool')}>
            <p className='h5'>The FinTabb Tool</p>
        </Card>
    </ListGroup.Item>

    {/* <ListGroup.Item onClick={()=>toggleChapterSelect('casestudy')}>
        <Card style={{border:'None', padding:'0px', margin:'0px'}} onClick={()=>toggleChapterSelect('casestudy')}>
        <p className='h5'>Case Study</p>
        </Card>
    </ListGroup.Item> */}
</ListGroup>


</Col>

                )}

 

                <Col style={{width:'85vw', maxHeight:'95vh', overflow:'scroll'}}>

                    {chapterSelect == 'whatisaccounting' && (
                        <>
                            <Whatisaccounting />
                        </>
                    )}


                    {chapterSelect == 'acltool' && (
                        <>
                            <ACLTool />
                        </>
                    )}

                    {chapterSelect == 'invoicesandreceipts' && (
                        <>
                            <Invoicesandreceipts />
                        </>
                    )}

                    {chapterSelect == 'financialtransaction' && (
                        <>
                            <FinancialTransactions />
                        </>
                    )}

                    {chapterSelect == 'journal' && (
                        <>
                            <Journal />
                        </>
                    )}

                    {chapterSelect == 'ledger' && (
                        <>
                            <Ledger />
                        </>
                    )}

                    {chapterSelect == 'trialbalance' && (
                        <>
                            <TrialBalance />
                        </>
                    )}


                    {chapterSelect == 'balancesheet' && (
                        <>
                            <BalanceSheet />
                        </>
                    )}
                

                    {chapterSelect == 'profitandloss' && (
                        <>
                            <Profitandloss />
                        </>
                    )}

                    {chapterSelect == 'cashflow' && (
                        <>
                            <Cashflow />
                        </>
                    )}

                    {chapterSelect == 'ratios' && (
                        <>
                            <Ratios />
                        </>
                    )}

                    {chapterSelect == 'casestudy' && (
                        <>
                            <Casestudy />
                        </>
                    )}

                    {chapterSelect == 'doubleentrybookkeeping' && (

                        <>
                            <DoubleEntryBookkeeping />
                        </>

                    )}

                    {chapterSelect == 'cashbasisvmercantile' && (
                        <>
                            <Cashbasisvmercantile />
                        </>
                    )}

                    {chapterSelect == 'booksofaccounts' && (
                        <>
                            <Booksofaccounts />
                        </>
                    )}

                    {chapterSelect == 'incomevsexpenditure' && (
                        <>
                            <Incomevsexpenditure />
                        </>
                    )}

                    {chapterSelect == 'assetsandliabilities' && (
                        <>
                            <Assetsandliabilities />
                        </>
                    )}

                    {chapterSelect == 'typesofregisters' && (
                        <>
                            <Typesofregisters />
                        </>
                    )}

                    {chapterSelect == 'whythisbook' && (
                        <>
                            <Whythisbook />
                        </>
                    )}

                    {chapterSelect == 'bankbook' && (
                        <>
                            <BankBook />
                        </>
                    )}

                    {/* {chapterSelect == 'cashbook' && (
                        <>
                            <CashBook />
                        </>
                    )} */}

                    {chapterSelect == 'analysisfinancialstatement' && (
                        <>
                            <AnalysisFinancialStatements />
                        </>
                    )}
                
                </Col>
            </Row>











        </>
    )
}


export default HomeScreen
