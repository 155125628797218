import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const AnalysisFinancialStatements = ({  }) => {

    var items = [
        {
            type:"paragraph",
            text:"The analysis of financial statements is basically a study of the relationship between the various financial facts and figures given in the financial statements and its interpretation thereof."
        },
        {
            type:"paragraph",
            title:"IT INVOLVES"
        },
        {
            type:"list",
            list:[
                "the critical evaluation of the information contained in the financial statements.",
                "the study of the financial results and assessment of the performance of the concern."
            ]
        },
        {
            type:"paragraph",
            title:" IT HELPS :",
        },
        {
            type:"list",
            list:[
                "to establish connection between the various items in the Balance Sheet and the Profit and Loss account.",
                "to gain insight into the profitability and operational efficiency of the concern.",
                "to assess the firm's current financial stability and also the future prospects as a going concern. ",
                "to understand the efficiency and competence of top management."
            ]
        },
        {
            type:"paragraph",
            text:"Financial analysis is of great use to the management of the business unit and all other stake holders, like, the owners, trade creditors, lenders, investors, labor unions etc."
        },
        {
            type:"paragraph",
            text:"The commonly used techniques for financial analysis are:"
        },
        {
            type:"paragraph",
            title:"Comparison of important financial statements.",
            text:"This is done by comparing the profitability and financial position of the concern for a number of years, also taking into consideration the changes in the financial policies of the Government from year to year."
        },
        {
            type:"paragraph",
            title:"Trend analysis of a business",
            text:"This is a study of change in the trend of business over a period of years due to innovations and rapid changes in technology. It also includes the automation of various activities and its impact on the business carried on by the concern. The management needs to take into account the changes in the business environment."
        },
        {
            type:"paragraph",
            text:"The concern also compares its performance with similar types of enterprises to have an assessment of its own standing in the business."
        },
        {
            type:"paragraph",
            title:"Cash flow Statement",
            text:"This statement throws light on the availability of funds for business and the Heads of expenditure. If the flow of financial resources has been timely and adequate, it reflects efficient management. Similar is the case if surplus funds or idle resources have been parked in income generating schemes and made remunerative. "
        }
    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Analysis of Financial Statements</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default AnalysisFinancialStatements
