//Tax Liability

var qandas = [
    {
question:"What is the scope of Total Income under Income Tax rules?",

items:[
        {   
        text:"Income tax is payable by all persons on the income earned in the Previous Year in India, subject to the deductions and exemptions allowed, if any, under the provisions of the Income tax Act."
        },
        {
            text:"(a) In the case of an ordinarily-resident in India, income tax is payable on the global income earned."
        },
        {
            text:"The income earned includes income"
        },
        {
            type:"list",
            list:[
                "received or deemed to receive in India,",
                "accrued or deemed to accrue in India,",
                "accrues or arises to him outside India."
            ]
        },
        {
            text:"(b)in the case of a not-ordinarily resident in India, "
        },
        {
            type:"list",
            list:[

                "he has to pay tax on all the income received or deemed to receive or accrues or arises to him in India,",
                "he will not pay tax on the income accrued or arising outside India unless it is related to the business or profession controlled in or set up in India."
                
            ]
        },
        {
            text:"(c) The total income of a non-resident in any previous year is all the income received or deemed to receive or accrue or deemed to accrue in India."
        },
        {
            text:"The income received by him in any previous year from whatever source outside India is not taxable in India."
        },
        {
            text:"(d )In the case of Individuals and HUF, only the basic exemption is available under the Income Tax Act."
        },
        {
            text:"(e) No basic exemption to other persons under the IncomeTax Act exists."
        },
        {
            text:"Note : Non-resident individuals are eligible for the basic exemption of R2,50,000, irrespective of age."
        }
    ]
},

{
    question:"What determines the type of taxable income in India ?",
    items:[
        {
        text:"Under the Income Tax Act, the residential status of a person determines the type of income taxable in India [Section 6(a)]."
        },
        {
            text:"(1) An individual is said to be a resident in India in any Previous Year if he" 
        },
        {
            type:"list",
            list:[
            "(a) is in India in the previous year for a period or periods amounting in all to 182 days or more; or",
            "(b) within the four years preceding that year, has been in India for a period or periods amounting to three hundred sixty-five days or more and is in India for a period or periods amounting to sixty days in the previous year."
            ]
        }
    ]
},
{
    question:"What are the cases under which Indian citizens are considered to be residents of India ?",
    items:[
    {
        text:"The new Section 6(1A) was inserted in the Finance Act 2020."
    },
    {
        text:"Notwithstanding anything contained in section 6(1), an individual, being a citizen of India having a total income in India, other than the income from foreign sources, exceeds Rs 15 Lakhs during the previous year shall be deemed to be a resident in India in that last year, if he is not liable to tax on the foreign income in any other country or territory because of his domicile or residence or any other criteria of similar nature."
    },
    {
        text:"In other words, in the above case of an individual, even if he does not stay for a day in India, he will be deemed a resident In India provided he does not pay tax on the foreign income in his country of domicile."
    },
    {
        text:"There are exceptions to this rule in the following cases; "
    },
    {
        type:"list",
        list:[
            "In the case of an individual who leaves India in the previous year for employment outside India, he shall be a resident only when he stays in India in the previous year for more than 182 days or more.",
            "Similar is the case with an individual who leaves India in the previous year as a crew member of an Indian ship, provided he stays in India more than 182 days in the last year."
        ]    
    }
    ]
},
{
    question:"Under what conditions is an individual a not-ordinarily resident in India ?",
    items:[
    {
        text:"An Individual is not-ordinarily resident in India in any previous year"
    },
    {
        text:"Sec 6(6)] provided he satisfies the following two conditions:"
    },
    {
        type:"list",
        list:[
        "(i) He should have been a non-resident in India in nine out of ten previous years immediately preceding the relevant last year, or",
        "(ii). He should have stayed less than 730 days or less in aggregate in the seven previous years immediately preceding the relevant last year."
        ]
    },
    {
        text:"Modification of provisions of Section 6(6) regarding “not ordinarily resident”",
    },
    {
        text:"Section 6(6) has been modified to include the following individual also as “not ordinarily resident in India:-"
    },
    {
        type:"list",
        list:[
            "(I) a citizen of India, or a person of Indian origin, having total income in India other than the income from foreign sources exceeding Rs.15 lakhs during the previous year as referred to in clause (b) of Explanation 1 to Section 6(1), who has been in India for a period or periods amounting to all 120 days or more but less than 182 days, or.",
            "(2) A citizen of India who is deemed a resident of India under Section 6(A). "
        ]
    },
    {
        text:"An individual is non-resident in India if he is not resident in India."
    },
    {
        text:"Individuals and HUF can be either ‘ordinarily resident’ or ‘not-ordinarily residents’. In the case of other persons, they can be residents or non-residents."
    }
    ]
},
{

    question:"What are the rules regarding resident status in the case of a Hindu Undivided Family ( HUF) ?",
    items:[
        {
        type:"list",
        list:[
            "A HUF is said to be an ordinary resident In India if the control and management of the HUF is situated in India.",
            "A HUF is a non-resident if the control and management of the affairs is situated outside India.",
            "A HUF is said to be not-ordinarily resident in India if the Karta of the family has been a resident in India in nine previous years out of ten last years immediately preceding the previous year or he has stayed in India for 730 days out of seven previous years preceding the previous year."
        ]
    }
    
    ]

},
{

    question:"What are the rules regarding Residential status of a PartnershipFirm, AOP, BOI, and any Artificial Judicial Person ?",
    items:[
        {
            text:"Partnership Firm :  If the partners in India make the firm’s decisions, then the firm is a resident person. On the contrary, if partners outside India make the decisions, the firm is a non-resident."
        },
        {
            text:"AOP, BOI, and Artificial Judicial Persons:  If the control and management of its affairs are situated in India, then they are residents. But, on the other hand, if the control and management of its affairs are located outside India, then they are non-residents."
        }
    ]
},
{
    question: "What are the rules regarding Residential status of a company ?",
    items:[
        {
            text:"A company is said to be resident in India ; IF"
        },
        {
            type:"list",
            list:[
                "(i)  it is an Indian company; OR",
            "(ii) the place of effective management of its affairs is situated in India."
            ]
        },
        {
            text:"A company is said to be non-resident : IF",   
        },
        {
            type:"text",
            list:[
                "(I)  it is not an Indian company, or",
                "(ii) the place of its effective management is outside India."
            ]
        }
    ]
    },

    {
        question:"What is the rule regarding dividends and income from Mutual Funds and Business Trusts ?",
        items:[
            {
                text:"Income tax is payable only once on the total taxable income earned in the Previous Year by a person under the Act."
            },
            {
                text:"In the case of dividends and income from units of mutual funds and business trusts, the income tax is payable on the profits made by companies and mutual funds and business trusts themselves and also on the dividend or income received by the shareholders and unit holders."
            }
        ]
    },

    {

    question:"uWhat is the general rule regarding expenditure incurred on generation of income ?",
    items:[
        {
            text:"The deduction of expenditure incurred on the generation of income is allowed only once under the Income Tax Act."
        },
        {
            text:"No double deduction is allowed for the same expense under any other provisions of the Act."
        },
        {
            text:"The basic assumption is that we have to incur expenses if we have to make income. Therefore, all the revenue generated, including income generated abroad from the costs incurred in India, is subject to IncomeTax in India as if the income is generated out of Indian activities."
        },
        {
            text:"If the income is exempted under any provisions of the Income Tax Act, the expense incurred for generating that income is not allowed as a deduction from any taxable income."
        },
        {
            text:"Income Tax Act allows the deduction of all the expenses incurred wholly and exclusively in connection with income generation. Incidents are not allowed as a deduction from taxable income."
        }
    ]
    },
    {
        question: "What is the rule relating to capital expenditure incurred in business or profession ?",
        items:[
            {
                text:"In the case of capital expenditure incurred in business or profession, or income from other sources, depreciation is allowed as a deduction on the capital assets from the profits and gains made from business or profession or income from other sources."
            }
        ]
    },
    {

        question:"What is the meaning of ‘basic exemption’ under Income Tax? Why is it given?",
        items:[
            {
                text:"The basic exemption given to individuals and HUF under the Income Tax  Act is meant to meet family expenses."
            },
            {
                text:"The basic exemption is supposed to change with the increase in the cost of living index every year, but it needs to be done regularly."
            },
            {
                text:"The basic exemption also applies to non-resident individuals and HUF on their income in India."
            },
            {
                text:"The basic exemption limit is the same for all individuals and HUF, irrespective of the income earned in the previous year."
            }
        ]
    },
    {

    question: "Which income is taxable in India?",
    items:[
        {
        type:"list",
        list:[
            "In the case of an ordinary resident, all the income earned in India and abroad is taxable.",
            "In the case of a non-ordinarily resident, all the income earned in India and income earned abroad from business or profession carried out from India.",
            "In the case of non-residents, only income earned in India is taxable."
        ]
        }
        ]
    },
    {

    question:"What is meant by ‘incomes that accrue or arise’ in India?",
    items:[{
        text:"Incomes that accrue or arise in India or are deemed to accrue or arise in India [Section 9] Income Tax Act, 1961"
    },
    {
        type:"list",
        list:[
            "(a)Accrue means to arise or spring as a natural growth or result, to come by or  increase. It indicates growth or accumulation with a definite shape to be receivable.",
            "(b)Arising means to come into existence or notice."
        ]
    },
    {
    text:"Any income which arises directly or indirectly from any activity or a business connection in India is deemed to be earned in India."
    }
    ]
    },

    {

    question:"What is  meant by the term ‘Business connection’ ?",
    items:[
    {
        text:"‘Business Connection’ includes a branch office in India or an agent or an organization of a non-resident in India, subsidiary company in India to carry on the business of a non-resident parent company.",
    },
    {
        text:"Any profit of a non-resident can be reasonably attributed to such part of operations carried out in India through business connections."
    }
    ]
    }


]

export {qandas}

//______________________________________________________________________