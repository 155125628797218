import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';


const QuestionItem = ({ question,items }) => {


    return (

        <>

        <Card>
        <Card.Header>
            <p className='h5' style={{fontWeight:'900'}}>{question}</p>
        </Card.Header>




        <Card.Body>



        <ListGroup style={{border:'None'}}>

        {items && items.map((item, index)=>(
            <ListGroup.Item style={{border:'None'}}>
                <Card style={{border:'None'}}>

                    {!item.type && (
                        <p className='h5'>{item.text}</p>
                    )}

                    {item.type == 'header' && (
                        <p className='h3' style={{textDecoration:'underline'}}>{item.text}</p>
                    )}

                    {item.type == 'paragraph' && (
                        <>
                            {item.title && item.title && (
                                <p className='h4' style={{textDecoration:'underline'}}>{item.title}</p>
                            )}
                            <p className='h5'>{item.text}</p>
                        </>
                        
                    )}


                    {item.type == 'list' && (
                        <ul>
                        {item.list && item.list.map((litem, index)=>(
                            <li className='h5'>
                                {litem}
                            </li>
                        ))}
                        
                        </ul>

                    )}

                    {item.type == 'table' && (
                        <>
                            <p style={{textAlign:'center'}}>{item.title}</p>
                            <table>
                                <tbody>
                                    <tr>
                                    {item.headers && item.headers.map((head, index)=>(
                                       <td>{head}</td>
                                    ))}
                                    </tr>

                                    {item.table && item.table.map((cols, index)=>(
                                        <tr>
                                            {cols && cols.map((ccol, index)=>(
                                                <td>{ccol}</td>
                                            ))}
                                        </tr>
                                    ))}


                                </tbody>
                            </table>


                        </>
                    )}


                </Card>
            </ListGroup.Item>

        ))}
        </ListGroup>


        </Card.Body>


        </Card>






        </>


    )
}


export default QuestionItem
