import { 
    ADD_PLOT,
    UPDATE_PLOT,
    DELETE_PLOT,
    PLOT_FAIL,
    CLEAR_PLOTS,

    SELECTED_PLOT,
    SELECTED_PLOT_UPDATE,
    SELECTED_PLOT_FAIL

 } from "../constants/plotConstants"


export const plotlistReducer = (state = { plots:[]}, action) => {

    switch(action.type){
        case ADD_PLOT:
            return { loading: false, 
                    plots: [action.payload, ...state.plots], 
                }

        case UPDATE_PLOT:
            return { loading: false, 
                plots: state.plots.map(plot => plot.id === action.payload.id ? { ...plot, title: action.payload.title, type: action.payload.type, labels: action.payload.labels, data:action.payload.data, template: action.payload.template, colorScheme: action.payload.colorScheme} : plot)
            }

        case DELETE_PLOT:
            return { loading: false, 
                plots: state.plots.filter(plot => plot.id !== action.payload),
            }

        case PLOT_FAIL:
            return { loading: false, error: action.payload, plots: state.plots }

        case CLEAR_PLOTS:
            return { loading: false, plots: [] }


        default:
            return state
    }

}



export const selectedplotReducer = (state = { selectedplot:null}, action) => {

    switch(action.type){
        case SELECTED_PLOT:
            return { loading: false, 
                    selectedplot: action.payload, 
                }

        case SELECTED_PLOT_UPDATE:
            return { loading: false, 
                selectedplot: action.payload, 
            }

        case SELECTED_PLOT_FAIL:
            return { loading: false, 
                selectedplot: {}, 
            }


        default:
            return state
    }

}