//Assessment of Individual Income

var qandas = [

    {
        question:"Who is an ‘individual’ for the purpose of taxation?",
        items:[
        {
            questions:"‘Individual’ means a natural person i.e. a human being and includes a single person : male, female or a minor child."
        }
    ]
    },
    {
        question:"How is the income of an individual assessed?",
        items:[
            {
                type:"list",
                list:[
            "The income of each individual is assessed separately in each Previous Year, if he has income earned independently in any Previous Year.",
            "The income of a minor child will be clubbed with the income of either of the parents who has  earned more income in any  previous year."
                ]    
        }    
    ]
    },
    {
        question:"Which are the incomes on which an individual has to pay tax?",
        items:[
            {
                text:"An individual is liable to pay income tax on the following incomes earned in the Previous Year:"
            },
            {
                type:"list",
                list:[
                        "Income earned by an individual in his own capacity.",


                        "Remuneration received by a partner as salary, bonus, commission etc is taxable as income received by an individual from business or profession.",


                        "Interest received by a partner from the firm on the capital / loan given by him will be assessed as income from business or profession of an individual.",


                        "Income received by an individual  as a member of AOP ( Association of Persons) / BOI ( Body of Individuals).",


                        "Where the income of AOP or BOI is taxed at  the normal rates i.e,the rates applicable to an individual, the income received by a member from AOP/BOI will be included in the income of the individual to determine the tax rate and relief will be given under Section 86.",


                        "Where no income tax is chargeable on the income of AOP/BOI, the share of income of a member from AOP/BOI will be chargeable to tax as part of his income."
                ]    
        }    
    ]
    },
    {

        question:"Which are the incomes received by an individual which are not subject to tax?",
        items:[
            {
                text:"The following incomes are exempted from tax in the hands of an individual. "
            },
            {
                type:"list",
                list:[
                    "Income received from a Firm, AOP / BOI ",
                    "Share of profits from partnership firms or limited liability partnership firms. [Section 10(2A] ",
                    "Share of income of a member from AOP/BOI, if the income of the AOP / BOI is taxed at maximum marginal rate [Proviso (a) to Section 86]",
                    "Share of Income received from HUF.[Section 10(2)]"
                ]
            },
            {
            text:"The HUF is taxed independently and therefore the income received by an individual from HUF will not be taxable in the hands of the individual."
            } 
        ]

    },
    {

        text:"Which are the normal five Heads of income on the basis of which Total Income of an individual is calculated?",
        items:[
            {
                text:"The normal five Heads of income used to compute the total income of an individual on the basis of his residential status are : "
            },
            {
                type:"list",
                list:[
                    "Salary ",
                    "Income from House Property ",
                    "Profits and Gains of Business or Profession",
                    "Capital Gains",
                    "ncome from other sources"
                ]
            }
]

    }

]

export {qandas}

//—--------------------------