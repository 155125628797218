//Income from other sources

var qandas = [

{
    question:"What is the income on which tax has to be paid?",
    items:[
        {
            text:"As per the Income Tax Act, 1961,  if the aggregate of all the incomes earned by a person in the previous year exceeds the maximum amount which is not chargeable to income tax,  then the excess of income earned over and above the basic exemption limit is subject to income tax, "
        },
        {
            text:"UNLESS"
        },
        {
            type:"list",
            list:[
                "the income is exempted under various provisions of the Income Tax Act,",
                "deductions are allowed for payments or investments are exempted under Section 80 of the Income Tax Act,",
                "deductions are allowed from the profits and gains of certain new industrial undertakings in priority sectors."
            ]
        }
    ]

},
{

    question:"What is meant by ‘income from other sources’.",
    items:[
        {
            text:"‘Income from other sources’ is the income which is not charged under any  of the following Heads of income:"
        },
        {
            type:"list",
            list:[
                " Income from salary,",
            " Income from house property,",
            " Profits and Gains of  Business or Profession, and",
            " Capital gains,"
            ]
        },
        {
            text:"Therefore,  ‘income from other sources’ is a residual income."
        }
    ]
},
{
    question:"What are the main sources of income under the Head ‘Income from other sources’?",
    items:[
        {
            text:"The main sources of income under the head income from other sources are:"
        },
        {
            type:"list",
            list:[
            "Dividends received from companies,income received on units of mutual funds and  income on units of business trusts.",


            "Winnings from lottery, crossword puzzles, card games, entertainment shows etc. (casual and occasional incomes). Payer has to deduct 30% tax at source  if payment is more than Rs.10,000. No deductions for expenses. [Section56 (2) (ib)].",


            " Employee’s Contribution to social welfare schemes deducted by the employer, but not deposited in the account of the social welfare schemes of the employee before the due date for filing the return of income.",


            "Interest received on securities, i.e. Interest on Government Securities or interest on the bonds or debentures issued by the  Local Authority or Public Companies or Corporations owned by the Government",


            "Money / movable or immovable property / inadequate consideration received on transfer of a movable or immovable property exceeding Rs.50,000 in the previous year from a non-relative.",


            " Income received on hiring of Plant and machinery which is not included under the Head ‘Profits and gain of  business or profession’.",


            "Income from hiring of plant and machinery including land and building forming an integral part of hiring of plant and machinery if the same is not included under the Head ‘Profits and Gains From Business or Profession’.",


            "Any sum received from a key-man insurance policy which is not included under the Head ‘Profits and Gains of Business or Profession’.",


            "Any consideration received from a resident person on the issue of shares by a closely held company in excess of the face value of the shares, The aggregate consideration received in excess of the market value of the shares, will be treated as income under the Head ‘Income from other sources’",


            "Income by way of interest on compensation received or enhanced compensation received.",


            " Compensation received or receivable by an employee on the termination or modification of the employment contract with an employer, will be taxed under the Head ‘Income from other sources’.[Section 56(2) (xi)."
]
}
]

},
{

    question:"What are the other subsidiary incomes which come under ‘Income from other sources’?",
    items:[
        {
            text:"These are:"
        },
        {
            type:"list",
            list:[
"  Income from subletting of house property by a tenant",
"  Insurance commission,",
"  Casual income,",
" Family pension received by legal heirs of a deceased employee,",
"  Directors’ sitting fee for attending Board Meeting,",
" Interest on bank deposit/company deposit, ",
"   Interest on loans,",
"  Income from undisclosed sources,",
" Remuneration received by Members of Parliament (MP’S) and Members of State Legislative Assembly (MLA’S),",
"  Interest on securities of Foreign Government,",
"  Invigilation fees received by teachers from other institutions,",
"  Interest received on the employee’s contribution towards Unrecognised Provident Fund,",
"  Rent from a vacant plot of land,",
"  Agricultural income received from agricultural land situated outside the country,",
"  Interest received on delayed refund of income tax,",
"  Royalty, if not included under the Head ‘Income from business or profession’",
"  Commission received by directors  of a company  for standing as a guarantor to bank,",
"  Commission received by directors for underwriting shares of a new company,",
"  Gratuity received by a director who is not an employee of the company,",
"   Income from racing establishment,",
"  Income from granting of grazing rights,",
"  Income from granting of mining rights,",
" Income from markets, fisheries, rights of ferry or moorings,",
"  Interest received from Government on excess payment of advance tax,",
"   Income received by a person after discontinuance of business.",
            ]    
    }
    ]
},
{

        question:"What is meant by ‘Interest on securities’?",
        items:[
            {
                text:"According to Section 2(28B), ‘Interest on securities’ means,"
            },
            {
                type:"list",
                list:[
                    "Interest on any  security of the Central or State Government,",
                    " Interest on the bonds and debentures issued by any Local Authority.",
                    " Interest on the bonds or debentures issued by Government Companies,",
                    "Interest on the bonds or debentures issued by Corporations established under the Central, State or Provincial Act  "
                    ]
            },
            {

                text:"The interest before deduction of tax at source, is to be taken as ‘Income from other sources’. "
            },
            {
                type:"list",
                list:[
                    " The tax is payable on the interest on accrual or cash basis depending upon the method of accounting followed by the assessee.",
                    " Surcharge and health and education cess on tax will be applicable."
                ]
    }
]
},
{

    question:"Are any deductions allowed on interest on securities?",
    items:[
        {
            text:"Yes. The following expenses are allowed as deduction from the interest on securities:"
        },
        {
            type:"list",
            list:[
                "Collection charges paid to the bank or any other person",
                "Interest on borrowings for investment in securities",
                "Any other expense incurred wholly and exclusively in connection with the generation of interest income."
            ]
        }
    ]
},
{


    question:"What are the deductions allowed on income from letting out buildings, machinery, furniture etc.?",
    items:[
        {
            text:"According to [Section56 (2) (ii)(a): The gross total income received or receivable on the letting out of the above assets is subject to the following deductions:"
        },
        {
            type:"list",
            list:[
                " Repairs and maintenance of buildings, plant and machinery, furniture and fixtures, etc.",
                " Rates and taxes on the above assets.",
                " Insurance premium paid on the building, plant and machinery, furniture and fixtures .etc. ",
                " Any other expense incurred wholly and exclusively in connection with the letting of the above fixed assets."
            ]
    }
]
},
{

question:"What is the rule regarding forfeited advance money?",
items:[
    {
        text:"The rule relating to  forfeiture of advance money received on transfer of a capital asset has been laid down in Section 56(2) (ix)."
    },
    {
        text:"Any advance received from any person related to transfer of a capital asset during the negotiation for transfer, and due to whatever may be the reasons, the advance received is forfeited by the transferor, the forfeited amount will be treated as ‘Income from other sources’"
    }
]
},
{

    question:"What is the provision in the case of receipt of money, property or inadequate consideration on transfer of an asset?",
    items:[
        {
            text:"Any sum of money or property or inadequate consideration received on transfer of an asset in excess of Rs 50,000, the following will be treated as ‘income from other sources’."
        },
        {
            type:"list",
            list:[
            "In the case of money received in excess of Rs 50,000, the whole of the aggregate value of the sum received. ",


            "In the case of property received without consideration, the full market value of the property received  provided  the value of the property is more than Rs 50,000  which  will be subject to tax.",


            "In the case of inadequate consideration received on the transfer of an asset exceeding Rs 50,000 the difference between the market value of the asset and the consideration received will be subject to tax under the Head ‘Income from other sources’ in the  hands of the transferor."
            ]
    },
        {

            text:"Exceptions to the Rule : Section56 (2)(x) does not  apply to any money or property received from the following persons:"
        },
    {
        type:"list",
        list:[
            " Any relative,",
            "  On the occasion of the marriage of the individual",
            "  Under a will or inheritance,",
            " In contemplation of death of the payer or donor, as the case may be,",
            "  From a Local Authority,",
            "  From any fund, or foundation or university or other educational institution or hospital or other medical institution or any trust or institution referred to in Section10 (23C).",
            "  From any trust or institution registered under Sections 12A or 12AA and 12 AB."
        ]

    }    
]

},
{

    question:"What is the rule relating to family pension received by the legal heir of a deceased employee?",
    items:[
        {
            text:"The legal heir will get a standard deduction of 1/3rd of such pension or Rs.15,000 whichever is less as per Section 57(iia)."
        }
    ]

},
{

    question:"What is the meaning of ‘deemed income chargeable to tax’?",
    items:[
        {
            text:"According to Section 59, if any deduction is allowed for an expense on an ‘income from other sources’ in the earlier Previous Years and the same expense is reduced or given remission in any subsequent year, the financial benefit derived therefrom will be treated as ‘income from other sources’."
        }
    ]
},
{

    question:"How is the total income of an individual calculated if the spouse also gets remuneration from the concern in which the individual has substantial interest?",
    items:[
        {
            text:"According to Section 64 (ii), Income of an individual  includes  income of his spouse, minor child, etc."
        },
        {
            type:"list",
            list:[
                "It includes  all such incomes which arise directly or indirectly to the spouse of such an individual by way of salary, commission, fees or any other form of remuneration, whether in cash or kind from a concern in which the individual has a substantial interest.",


                "But, if the remuneration received by the spouse is due to  his/her technical or professional qualification and is solely due to the application of his/ her technical or professional knowledge or experience to the concern, they will be assessed separately.",


                "If both the husband and wife  have substantial interest in the concern and both are in receipt of remuneration from the concern, then the remuneration of the spouse, who is in receipt of less remuneration, will be clubbed with the income of the spouse who has more remuneration from the same concern. ",


                "It is required that when the remuneration is accrued for  both, the relationship between the husband and wife should exist."
            ]
        }
    ]
},
{

    question:"What is the meaning  of ‘substantial interest’ in a business concern? ",
    items:[
        {
            text:"An individual shall be deemed to have a ‘substantial interest’ in a company : "
        },
        {
            type:"list",
            list:[    
            "If he alone or along with his relatives,  holds equity shares having a voting right not less than 20%.",
            "In any other case, if he alone or along with his relatives is entitled to at least 20% of the profit."
            ]
        }
    ]
},
{


    question:"What is the rule relating to income from assets transferred to a spouse?",
    items:[
        {
            type:"list",
            list:[
                " According to [Section 64(1) (iv)],  the total income of an individual, shall include all such incomes which arise directly or indirectly to the spouse of such individuals from assets transferred to the spouse otherwise than for adequate consideration or in connection with an agreement to live apart.",
                "This is not applicable to a house property because in this case the transferor is deemed to be the owner of house property and the annual value of the property is taxed in the hands of the transferor as per Section 27."
            ]
        }
    ]
},
{


    question:"What is the rule regarding  Income from assets transferred to a son’s wife?",
    items:[
        {        
            text:"As per [Section 64(1)(vi)], any income arising directly or indirectly to the son’s wife from the assets transferred by an individual, will be assessed in the hands of the transferor."
        }
    ]
},
{


    question:"How is the income of minor children computed?",
    items:[
        {
            text:"According to  [Section 64(1A)], the income of a minor child has to be clubbed in the hands of either of the parents, while computing the total income."
        },
        {
            type:"list",
            list:[
                "The income of the minor child is to be clubbed in the total income of that parent whose total income, excluding the income of the minor child, is higher.",


                "If the marriage does not exist, the income of the minor child is to be clubbed in the income of the parent who takes care of the minor child.",


                "The parent of the minor with whose income the income of the minor child is clubbed, will be eligible for a deduction of Rs  1500 per annum. ",


                "If the income of the minor child is less, the actual amount included, whichever is less,  will be allowed as a deduction for the parent."
            ]
        }
    ]
},
{

    question:"Is every income of a minor child clubbed with a parent’s income for taxation purposes?",
    items:[
        {
            text:"No. Certain incomes of a minor child are taxable in the hands of  the minor child  himself. Example:"
        },
        {
            type:"list",
            list:[
            "  Any income of a minor child suffering from any disability of the nature specified in Section 80U like physically disabled, totally blindness etc.",
            "  Any income which accrues or arises to a minor child on account of any manual work done by him.",
            "  Any income which accrues or arises to the minor child on account of any activity involving use of his skills, talent or specialized knowledge or experience."
            ]
        }
    ]

},
{


    question:"What is the rule relating to Income from self occupied property converted to joint family property?",
    items:[
        {
            text:"If an individual, who is a member of a HUF,"
        },
        {
            type:"list",
            list:[
                " converts his self acquired property as the property of HUF,",
                " throws the property into the common stock of the family,",
                "  otherwise, transfers his individual property to the family other than for  adequate consideration,"
            ]
        },
        {
            text:"then, the income from such property shall continue to be included in the total income of the individual (transferor)."
        }
    ]
},
{

    question:"Is the transferee always free from tax liability on income from assets transferred to him/her?",
    items:[
        {
            text:"No."
        },
        {
            type:"list",
            list:[
            "Normally,  the income from any asset transferred to another person is included in the total income of the transferor.",

            "However, Section 65 lays down  that the notice of demand in respect of such income may also be served upon the person to whom such asset has been transferred.",

            "On receipt  of such notice, the transferee is liable to pay that portion of tax levied on the transferor which is attributable to the income so included."
            ]
        }
    ]
}

]

export {qandas}
                                                     
//—-----------------------