import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const TrialBalance = ({  }) => {


    var items = [
        {
            type:"paragraph",
            text:"The Trial Balance shows  the balances of all the accounts of income and  expenses  and  all the assets and liabilities as on the date of the Balance Sheet. The Trial Balance is the basis for preparing the Profit and Loss Account and Balance Sheet."
        },
        {
            type:"paragraph",
            text:"The Trial Balance is prepared from the General Ledger, Debtor’s Ledger and Creditor’s Ledger of the enterprise. The normal practice is to get confirmation of balances from the debtors and creditors at the close of the year and ensure that the balances shown in the Books of Account tally with the confirmation certificates received."
        },
        {
            type:"paragraph",
            text:"The net balance in the Debtor’s ledger and Creditor’s ledger should tally with the balances of debtor’s control account and creditors control account in the General Ledger."
        },
        {
            type:"paragraph",
            text:"Before we prepare the Trial Balance, we need to ensure that all the income and expenses and all the assets and liabilities including accrued income and expenses pertaining to the relevant financial year is accounted for. "
        },
        {
            type:"paragraph",
            text:"The total debit balance in the debtors’ ledger is shown as receivables in the Balance Sheet under the Head ‘Current Assets’ and the total credit balance of the debtors’ ledger is shown as advance received from debtors under the Head 'Current Liabilities'."
        },
        {
            type:"paragraph",
            text:"Similarly, total credit balance in the creditors’ ledger is shown as creditors in the Balance Sheet under the Head ‘Current Liabilities’ and the total debit balance in the creditor’s ledger is shown as advances paid to creditors under the Head ‘Loans and Advances’ on the assets side."
        },
        {
            type:"paragraph",
            text:"The closing balances of raw materials stock register, stores and spares stock register, fuel stock register and the balances of fixed assets register, postage register, stationery  register should tally with the balances of respective accounts in the general ledger."
        },
        {
            type:"paragraph",
            text:"It is compulsory to take physical verification of all the assets and inventories at least once at the close of the year and reconcile with the Book balance. Discrepancy, if any, should be scrutinized and proper adjustments made in the Books of Account."
        }

    ]

    return (

        <>
        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Trial Balance</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default TrialBalance
