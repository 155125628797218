import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const Incomevsexpenditure = ({  }) => {

    var items = [

        {
            type:'paragraph',
            text:'There are two aspects to any financial transaction – Income and Expenditure'
        },
        {
            type:'paragraph',
            text:'The receipt of money / consideration / compensation for the supply of goods or services to others, is called income .'
        },
        {
            type:'paragraph',
            text:'The payment of money / consideration / compensation by a person for the goods or services received is called expenditure.'
        },
        {
            type:'header',
            text:'Types of Expenditure'
        },
        {
            type:'paragraph',
            text:'Expenditure can be broken down into two types: Revenue Expenditure and Capital Expenditure.'
        },
        {
            type:'paragraph',
            title:'Revenue Expenditure',
            text:"Revenue Expenses are short term expenses incurred in the current period or within one year. It includes the expenses required to meet the ongoing operational costs of running a business (operational expenses)."
        },
        {
            type:'paragraph',
            title:'Deferred Revenue Expenditure',
            text:"Deferred revenue expenditure is an expense, the benefit of which lasts for more than a year.  Therefore, deferred revenue expenditure is written off annually during the years the benefit of the expense lasts. The balance of deferred revenue expenditure not written off on the date of closing of the accounts is shown in the Balance Sheet under the Head ’Miscellaneous Expenditure’ on the Asset side."
        },
        {
            type:'paragraph',
            title:'Capital Expenditure',
            text:"Capital Expenditure is  the expenditure incurred by a person on acquiring Fixed Assets, such as land, building, plant, machinery, vehicles, office equipment, furniture and fittings, etc. (tangible assets) AND goodwill, patents, copyrights, trademarks, etc.( intangible assets)."
        }

    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Income vs Expenditure</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default Incomevsexpenditure
