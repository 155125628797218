import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';

import QuestionItem from './QuestionItem'
import {qandas} from '../../booktext/taxfaqs/TCh3_TaxLiability'

const TaxLiability = ({ items }) => {


    return (

        <>

        <Card style={{marginBottom:'10px'}}>
            <Card.Header>
                <p className='h3'  style={{textAlign:'left'}}>Tax Liability</p>
            </Card.Header>  
        </Card>

        {qandas.map((qanda, index)=>(
            <QuestionItem question={qanda.question} items={qanda.items}/>
        ))}
        


        </>


    )
}


export default TaxLiability
