//Agricultural Income and its Tax Treatment

var qandas = [

{
    question:"Is agricultural income subject to Income tax?",
    items:[
        {
            text:"Section 10(1) of the Income Tax Act, 1961 exempts agricultural income from income tax."
        },
        {
            text:"However, net agricultural income is added to the total non-agricultural income computed as per the Income Tax Act, for the purpose of determining the rate of income tax on non-agricultural income of an individual, HUF, AOP/BOP or an artificial judicial person."
        }    
   ]
},
{

    question: "What does the term ‘Agricultural Income’ mean ?",
    items:[
        {
            text:"According to Section 2(1A], ‘Agricultural Income’ means : "
 },
 {
    type:"list",
    list:[
        "Any rent or revenue derived from agricultural land.",
        "Any income derived from such agricultural land  or from processing of agricultural produce,",
        "Any income from a farm building.",
        "Any income from saplings or seedlings grown in a nursery. [Explanation 3 of Section 2(1A)]"
    ]
},
 {

    text:"Agricultural Income’ thus also includes,"
 },
 {
    type:"list",
    list:[
     "Income from  horticulture, floriculture, arboriculture and silviculture.",
     "Income from raising of grooves, plantations, rising of grass or pastures.",
     "Income from  cultivation of food items like sugar cane, coffee, tea, mangoes; artistic and decorative plants like flowers, creepers; wood-providing trees like bamboo, timber; medicinal and health plants."
    ]
} 
]
 },
 {

    question:"How is agricultural income calculated when income is partially from agriculture and partially from business ?",
    items:[
        {
            text:"A farmer engaged in the cultivation of agricultural produce may do two things:"
        },
        {
            type:"list",
            list:[
 "He may sell the agricultural product directly to the consumer, like grain, vegetables etc. OR",
" He may process the agricultural raw material and sell it in another form."
            ]
        },
        {

            text:"Income from agriculture = Market price of unprocessed  agricultural produce - Cost of production of agricultural produce."
        },
        {

            text:"Income from business = Market price of processed agricultural produce -(market value of agricultural raw material + processing cost)"
        }
    ]
},
{


    question:"What is the rule relating to Income from growing and manufacturing Rubber? ",
    items:[
{
        text:"As per (Rule 7A), out of the total income derived from the manufacture and sale of rubber products, 30% will be treated as income from business."
},
{
    type:"list",
    list:[
" While computing such income, an allowance shall be made in respect of the cost of planting new rubber plants in replacement of the dead ones / permanently  useless ones,  in an area already planted, if such area has not previously been abandoned.",
"  While determining, such cost, no deduction shall be made in respect of the amount of any subsidy which, under the provisions of Section 10(31), is not includible in the total income."
    ]
}
]
},
{

    question:"What is the tax liability on  Income from growing and manufacturing coffee?",
    items:[
        {
            type:"list",
            list:[
"Rule 7B lays down that Income derived from the sale of coffee grown, cured, roasted and grounded by the seller in India, with or without flavoring, will be computed as if it is income derived from business.",


"40% of such income shall be taxed as income from business."
            ]    
    }
    ]
},
{


question:"What is the tax liability on Income from growing and manufacturing Tea? ",
items:[
    {
        text:"As per (Rule8), in the case of an assessee growing, processing and selling tea: "
    },
    {
        type:"list",
        list:[
"60% of the total income computed, after allowable deductions, will be treated as agricultural income AND",
"The remaining 40% will be treated as income from business."
        ]
    }
]
}

]

export {qandas}


//—-----------------------------------