import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';

import QuestionItem from './QuestionItem'

const ImportantDefinitions = ({ items }) => {


    var qandas = [
        {
            question:'What is meant by Advance Tax in India?',
            items:[
                {
                    type:'paragraph',
                    text:"Advance tax means the tax payable in advance in accordance with the provisions of Chapter XVII of the Income Tax Act,1961.",
                },
                {
                    type:'paragraph',
                    text:"Advance tax is payable on the estimated income of the previous year on or before the  15thMarch of the same previous year. This is applicable only if the estimated income in the previous year exceeds the income which is more than the income not chargeable to income tax. [Section 2(1)]"
                }
            ]
        },
        {
            question:'What does the term ‘Agricultural Income’ mean?',
            items:[
                {
                    type:'paragraph',
                    text:"Agricultural Income. [Section2 (1A)]",
                },
                {
                    type:'paragraph',
                    text:"Agricultural Income means:"
                },
                {
                    type:'list',
                    list:["Any rent or revenue received from agricultural land situated in India.",
                            "Any income received by a cultivator from producing and / or selling agricultural produce.",
                        "Any income received from renting the agricultural land or building used for agricultural purposes."
                        ]
                }
            ]
        },
        {
            question:"What is the definition of Agricultural Land in India?",
            items:[
                {
                    type:'paragraph',
                    text:"According to  [Section 2(14) (iii)], of the Income Tax Act, 1961, agricultural land means any land situated in India used for agricultural purposes as under,"
                },
                {
                    type:'list',
                    list:["In any area comprised within the jurisdiction of a Municipality or Cantonment Board and which has a population less than 10,000; or ",
                        "(i)Not being more than 2 Km  from the local limits of any Municipality or Cantonment Board which has a population of more than 10,000 but not exceeding 1 lakh. (ii)Not more than six km from the local limits of any Municipality or Cantonment Board which has a population of more than 1 lakh, but not more than 10 lakhs. (iii) Not being more than 8 km from the local limits of any Municipality or Cantonment Board, having a population of more than 10 lakhs."
                    ]
                }
            ]
        },
        {
            question:'What is meant by Amalgamation ?',
            items:[
                {
                    type:'paragraph',
                    text:"The term ‘amalgamation’ has been explained in [Section2 (1B)] of the Income Tax Act,1961,",
                },
                {
                    type:'paragraph',
                    text:"Amalgamation is normally related to companies. Amalgamation of companies means the merger of two or more companies with another company or the merger of two or more companies to form a new company. ",
                },
                {
                    type:'paragraph',
                    text:"The company or companies which merge is/are called the amalgamating company / companies and the company with which they merge or the new company formed is called the amalgamated company.",
                },
                {
                    type:'list',
                    list:["(i) All the properties of the amalgamating company will become the properties of the amalgamated company.",
                    "(ii) All the liabilities of the amalgamating company will become the liabilities of  the amalgamated company.",
                    "(iii) The shareholders holding not less than 75% of the value of the shares in the amalgamating company will become the shareholders of the amalgamated company."
                    ]
                }
            ]
        },
        {
            question:'What is an Approved Gratuity fund ? ',
            items:[
                {
                    type:'paragraph',
                    text:"An Approved Gratuity Fund means a gratuity fund approved by the Principal Chief Commissioner or The Chief Commissioner or Principal Commissioner or Commissioner in accordance with the rules contained in Part B of Fourth Schedule. {Section 2(5)] of the Income Tax Act,1961."
                }
            ]
        },
        {
            question:'What is an Approved Superannuation Fund ?',
            items:[
                {
                    type:'paragraph',
                    text:"An Approved Superannuation Fund means a superannuation fund or any part of a superannuation fund which has been and continues to be approved by the Principal Chief Commissioner or the Chief Commissioner or Principal Commissioner or Commissioner in accordance with the rules contained in Part B of Fourth Schedule.[Section 2(6)] of the Income Tax Act. 1961.",
                }
            ]
        },
        {
            question:'Who is a Person under the Income Tax Act in India?',
            items:[
                {
                    type:'paragraph',
                    text:"A Person includes:",
                },
                {
                    type:'list',
                    list:["An individual",
                    "A Hindu Undivided Family",
                    "A Firm",
                    "A Company",
                    "An Association of Persons and Body of Individuals",
                    "Local Authority",
                    "Any other Artificial judicial Person"
                    ]
                }
            ]
        },
        {
            question:'Who is an Assessee for Income Tax purposes?',
            items:[
                {
                    type:'paragraph',
                    text:"An Assessee means a person by whom any tax or any other sum of money is payable under the Income Tax Act, 1961{Section 2(7).",
                },
                {
                    type:'list',
                    list:["Every person in respect of whom any proceeding under this Act has been taken for the assessment of his income or the income of any other person in respect of which he is assessable or of any loss sustained by him or by any other person or of the amount of refund due to him or to such other person.",
                    "Every person deemed to be an assessee under the provisions of the IncomeTax Act.",
                    "Every person deemed to be an assessee in default under the provisions of the Act."
                    ]
                }
            ]
        },
        {
            question:'What is the difference between Calendar Year, Financial Year and Previous Year?',
            items:[
                {
                    type:'paragraph',
                    text:"The Calendar Year is from 1st January to 31st December .",
                },
                {
                    type:'paragraph',
                    text:"The Financial Year is a period of twelve months commencing on the 1st day of April and ending on the 31st day of March of the next year.",
                },
                {
                    type:'paragraph',
                    text:"The Previous Year is the Financial Year immediately preceding the Assessment Year. [Section 2(34)].",
                },
                {
                    type:'paragraph',
                    text:"In the case of a new business, the Previous Year will be from the date the business started till the end of 31st March .",
                }
            ]
        },
        {
            question:'What is meant by Assessment Year under Income Tax rules ?',
            items:[
                {
                    type:'paragraph',
                    text:"The Assessment Year is the year in which the assessment of Income Tax is made. It is usually one year ahead of the Previous Year.",
                }
            ]
        },
        {
            question:'What is the Average Rate of Income Tax ?',
            items:[
                {
                    type:'paragraph',
                    text:"Average Rate of Income Tax means the rate arrived at by dividing the amount of income tax by the total income.[Section 2(10)], Income Tax Act, 1961.",
                }
            ]
        },
        {
            question:'What is meant by Block of Assets ?',
            items:[
                {
                    type:'paragraph',
                    text:"Block of Assets means a group of assets falling within a class of assets comprising of the following:-[Section 2(11)]",
                },
                {
                    type:'list',
                    list:["(a) Tangible assets, being land, buildings, machinery, plant and furniture",
                    "(b) Intangible assets being know-how, patents, copyrights, trade marks ,licenses, franchises  or any business or commercial rights of similar nature(not being goodwill of a business or profession), {inserted  by The Finance Act , 2021]"
                    ]
                }
            ]
        },
        {
            question:'What is the meaning of Board under Income Tax rules in India?',
            items:[
                {
                    type:'paragraph',
                    text:"Board means the Central Board of Direct Taxes constituted under the Central Board of Revenue Act, 1963 [Section 2(12)], Income Tax Act, 1961.",
                }
            ]
        },
        {
            question:'What does Books or Books of Account include?',
            items:[
                {
                    type:'paragraph',
                    text:"Books or Books of Account include ledgers, day-books, cash books, account books and other books whether in written form, electronic form or in digital form or as print outs of data stored in such electronic form or in digital form or in a floppy, disc, tape or any other form of electro-magnetic data storage device.[Section 2(12A)] Income Tax Act, 1961.",
                }
            ]
        },
        {
            question:'What is the scope of ‘Charitable purpose’ under Income Tax rules ?',
            items:[
                {
                    type:'paragraph',
                    text:"‘Charitable purpose’ under Income Tax Act,1961, includes relief of the poor, education, and health care, preservation of the environment (including watersheds, forests and wildlife and preservation of monuments, or places or objects of artistic interest) and advancement of any other object of general public utility. [Section 2(15)]",
                },
                {
                    type:'paragraph',
                    text:"Provided that, the advancement of any other object of general public utility shall not be charitable purpose if it involves the carrying on of any activity of rendering any service in relation to trade, commerce or business for a cess or fee or any other consideration irrespective of the nature of use or application or retention of the income from such activity. ",
                },
                {
                    type:'paragraph',
                    text:"Provided further that the first proviso will not apply if the aggregate value of the receipts from the activities referred to therein will not exceed more than Rs.25 lakhs or less in the previous year.",
                }
            ]
        },
        {
            question:'What does the term Company imply ?',
            items:[
                {
                    type:'paragraph',
                    text:"The term ‘Company’ under Income Tax Act, 1961[Section 2(17)] means:",
                },
                {
                    type:'list',
                    list:[
                    "(i)Any Indian company",
                    "(ii)Any Corporate, incorporated by or under the laws of a country outside India."
                    ]
                }
            ]
        },
        {
            question:'What are the different types of Companies under the Income Tax Act, 1961 ?',
            items:[
                {
                    type:'paragraph',
                    text:"The different types of Companies are:",
                },
                {
                    type:'list',
                    list:["Indian company, which means a company registered under the Indian Companies Act, 1956.[Section 2(26)]",
                    "Domestic company [Section2(22A)} which means an Indian company or any other company which in respect of its income chargeable to income tax in India, has made prescribed arrangements for the declaration and payment of the dividend payable out of such income in India.",
                    "Foreign company [Section 2(23A)] which means a company which is not an Indian company."
                ]
                }
            ]
        },
        {
            question:'What is meant by Infrastructure Capital company?',
            items:[
                {
                    type:'paragraph',
                    text:"According to  [Section 2(26A)] of the Income Tax Act, 1961, an Infrastructure Capital Company means such company which makes investments by way of acquiring shares or providing long term finance to any enterprise or undertaking wholly engaged in the business referred to in subsection (4) of Section 80-IA( Infrastructure development) or subsection 80-IAB. ",
                },
                {
                    type:'paragraph',
                    text:"Such a company is engaged in:",
                },
                {
                    type:'list',
                    list:["Development of infrastructure", 
                    "Development of Special Economic Zone",
                    "Development and building of Housing Projects",
                    "Hotel construction (three star category and above)",
                    "Hospital construction ( with at least 100 beds for patients) "
                    ]
                }
            ]
        },
        {
            question:'What is meant by Interest on Securities ?',
            items:[
                {
                    type:'paragraph',
                    text:"‘Interest on Securities’ [Section2(28B)] Income Tax Act, 1961, means interest on any security of the Central or State Government, interest on debentures or other securities for money issued by or on behalf of the Local Authority or a Corporation established by the Central or State Government.",
                }
            ]
        },
        {
            question:'What is a Public Sector Company ?',
            items:[
                {
                    type:'paragraph',
                    text:"A Public Sector Company means any corporation established by or under any Central, State or Provincial Act or a Government company as defined in Section 617 of the Indian Companies Act, 1956. [Section 2(36A)]",
                }
            ]
        },
        {
            question:'What is a Zero Coupon Bond ?',
            items:[
                {
                    type:'paragraph',
                    text:"A Zero Coupon Bond means a bond issued by an Infrastructure Capital Company or Infrastructure Capital Fund, or a Public Sector Company, or a Scheduled bank in which the holder of the bond will not receive any financial benefit till it is matured or redeemed. [Section 2(48)], Income Tax Act, 1961.",
                }
            ]
        },
        {
            question:'When is a Company said to be ‘resident’ in India?',
            items:[
                {
                    type:'paragraph',
                    text:"A company is said to be resident In India, if",
                },
                {
                    type:'list',
                    list:["(a) it is an Indian company, and", 
                    "(b)the control and management of the affairs of the company during the previous year is in India."
                    ]
                }
            ]
        },
        {
            question:'What is meant by ‘Convertible Foreign Exchange’ ?',
            items:[
                {
                    type:'paragraph',
                    text:"Convertible Foreign Exchange  means foreign exchange i.e, foreign currency  which for the time being is treated by the Reserve Bank of India as convertible foreign exchange for the purpose of the Foreign Exchange Management Act,1999.",
                }
            ]
        },
        {
            question:'What does the term ‘Dividend’ include?',
            items:[
                {
                    type:'paragraph',
                    text:"As per Section [2(22)], Income Tax Act. 1961, ‘Dividend’ includes:",
                },
                {
                    type:'paragraph',
                    text:"(i) any distribution of accumulated profits to its shareholders,",
                },
                {
                    type:'paragraph',
                    text:"(ii) any distribution of debenture, debenture stock or deposit certificates to its shareholders,",
                },
                {
                    type:'paragraph',
                    text:"(iii) any distribution of accumulated profits to shareholders on the liquidation of a company by the liquidator.",
                },
                {
                    type:'paragraph',
                    text:"(iv)any distribution of accumulated profits to its shareholders on reduction of the share capital.",
                },
                {
                    type:'paragraph',
                    text:"(v)any payment by a company, in which the public are not substantially interested, by way of advance or loan to shareholders (not shareholders having a fixed rate of dividend) whether having a voting right or not, holding not less than 10% of the voting power or to any concern in which the shareholder has a substantial interest in the concern as a member or partner of the concern, any payment by such company on behalf of or for the individual benefit of any such shareholder to the extent to which, in either case, possesses accumulated profits, but does not include:-",
                },
                {
                    type:'list',
                    list:["(a) Any distribution of shares issued to a shareholder for full value of consideration.",
                    "(b) Any distribution made to the shareholders of the company out of capitalized profits of the company.",
                    "(c) Any advance or loan given in the ordinary course of business.",
                    "(d) Any dividend paid by a company as set off against the whole or part of any sum paid by it as dividend to the extent to which it is set off.",
                    "(e) Any payment made by the company on purchase of its own shares.",
                    "(f) Any distribution of shares on demerger of a company."
                    ]
                }

            ]
        },
        {
            question:'What is meant by ‘Fair market value’ in relation to an asset.',
            items:[
                {
                    type:'paragraph',
                    text:"According to [Section2 (22B)], Income Tax Act, 1961, ‘fair value’ of an asset means:",
                },
                {
                    type:'list',
                    list:["(i) The price which the asset would ordinarily fetch if sold in the open market on that relevant date.",
                    "(ii) Where the price referred to in subsection (i) is not ascertainable, such price, as may be determined in accordance with the rules made under this Act."
                    ]
                }
            ]
        },
        {
            question:'What does the term ‘Firm’ mean ? ',
            items:[
                {
                    type:'paragraph',
                    text:"The term ‘Firm’ has the meaning assigned to it in the Indian Partnership Act,1932 and includes Limited Liability Partnership under the Partnership Act,2008.[Section 2(23)(i)]"
                }
            ]
        },
        {
            question:'What is the meaning of Maximum Marginal Rate ?',
            items:[
                {
                    type:'paragraph',
                    text:"Maximum Marginal Rate means the rate of income tax ( including surcharge on income tax) applicable in relation to the highest slab of income in the case of an individual, association of persons, body of individuals as specified in the Finance Act of the relevant previous year.[Section[2 (29C)].Income Tax Act, 1961.",
                }
            ]
        },
        {
            question:'What does Slump Sale mean ?',
            items:[
                {
                    type:'paragraph',
                    text:"As per the amendment made by the Finance Act 2021, in Section 2(42C) of the Income Tax Act, 1961:",
                },
                {
                    type:'paragraph',
                    text:"'Slump sale'  means the transfer of one or more undertakings by any means for a lump sum consideration without values being assigned to the individual assets and liabilities in such sales.",
                },
                {
                    type:'paragraph',
                    text:"For the purposes of this clause “transfer “shall have the meaning assigned to it in section 2(47).",
                }
            ]
        },
        {
            question:'What is the definition of a Mutual Fund ?',
            items:[
                {
                    type:'paragraph',
                    text:"Under Section 10(23D) of the Income Tax Act, 1961, a Mutual Fund is : ",
                },
                {
                    type:'list',
                    list:["(i) a fund registered under the Securities and Exchange Board of India Act, 1992 or the Regulations made there under",
                    "(ii) such other mutual funds set up by Public Sector Financial Institution or Public Sector Bank or authorized by Reserve Bank of India and subject to such other conditions as the Central Government may by notification in the official Gazette specify on this behalf.",
                    ]
                }
            ]
        },
        {
            question:'What is meant by Reverse Mortgage ?',
            items:[
                {
                    type:'paragraph',
                    text:"Reverse Mortgage is a mortgage loan or a financial agreement under which a house owner of 62 years or above relinquishes his/her equity in his/her home (ownership) against a lump sum or regular installments to supplement his retirement income. So far as the owner stays in the house, there is no need for him/her to pay back the loan.",
                }
            ]
        },
        {
            question:'What does ‘Demerger’ mean ?',
            items:[
                {
                    type:'paragraph',
                    text:"‘Demerger’ is a form of corporate restructuring in which the entity’s business operations are segregated into one or more components .",
                }
            ]
        },
        {
            question:'What is the meaning of Gig ?',
            items:[
                {
                    type:'list',
                    list:["Gig is a slang word which means ‘a job for a specified period’ .",
                    "Gig employees include freelancers, independent contractors, project- based workers, and temporary or part time hires."
                    ]
                }
            ]
        },
        {
            question:'What is an Equity fund?',
            items:[
                {
                    type:'paragraph',
                    text:"An Equity Fund  is a mutual fund scheme that invests predominantly in equity stocks,",
                },
                {
                    type:'paragraph',
                    text:"As per current Income Tax Rules, an equity oriented fund means a mutual fund scheme where the investible funds are invested in equity shares in domestic companies to the extent of more than 65% of the total proceeds of such fund.",
                },
            ]
        },
        {
            question:'What is a ‘Fund of Funds’ ?',
            items:[
                {
                    type:'paragraph',
                    text:"A ‘Fund of Funds’ is a mutual fund scheme designed to invest in equity oriented and debt oriented funds.The Fund of Funds is not regarded as an equity fund",
                }
            ]
        },
        {
            question:'What is the meaning of ‘Marked to Market loss’ ?',
            items:[
                {
                    type:'paragraph',
                    text:"Marked to Market loss means that the asset value is adjusted to reflect its market price by recognizing the decrease in value. The decrease in the value representing the loss for the purpose of computing total income of the assessee, cannot be made unless the ICDS permits such recognition, valuation of inventory, construction contracts etc.",
                }
            ]
        },
        {
            question:'What does ‘Liable to tax’ mean?',
            items:[
                {
                    type:'paragraph',
                    text:"The Finance Act 2021, inserted Section 2(29AA) to provide the definition of the term “liable to tax”.",
                },
                {
                    type:'paragraph',
                    text:"Section 2(29AA), so inserted, defines as under;",
                },
                {
                    type:'paragraph',
                    text:"“Liable to tax “ in relation to a person and  with reference to a country, means that there is an income tax liability on such person under the law of that country for the time being in force and shall include a person who has subsequently been exempted from such liability  under the law of that country;",
                }
            ]
        },
        {
            question:'What does the term ‘manufacture’ imply?',
            items:[
                {
                    type:'paragraph',
                    text:"According to  [Section2 (29BA)], Income Tax Act, 1961, the term ‘manufacture’ with its grammatical variation means a change in a non- living physical object or article or a thing, resulting in transformation of the object, or article with different name, character and user or bringing into existence a new and distinct object or article or thing with a different chemical composition or integral structure.",
                }
            ]
        },
        {
            question:'What is meant by ‘substantial Interest in a company’ ?',
            items:[
                {
                    type:'paragraph',
                    text:"A person who has ‘a substantial interest in the company’ means a person who is the beneficial owner of shares not being shares entitled to a fixed rate of dividend, whether with or without a right to participate in profits, carrying not less than 20% of voting power.",
                }
            ]
        },
        {
            question:'What is a Venture Capital Fund ?',
            items:[
                {
                    type:'paragraph',
                    text:"A Venture Capital fund means such fund operating under a trust deed registered under the provisions of the Indian Registration Act, 1908, established to raise monies by trustees mainly in equity shares of venture capital undertakings in accordance with the prescribed guidelines.",
                }
            ]
        },
        {
            question:'What is a Venture capital undertaking ?',
            items:[
                {
                    type:'paragraph',
                    text:"A Venture Capital Undertaking means such company whose shares are not quoted in the stock exchange in India and which is engaged in the business of,",
                },
                {
                    type:'list',
                    list:["(a) Software",
                    "(b))Information technology",
                    "(c) Production of basic drugs in the pharmaceutical sector", 
                    "(d) Bio technology",
                    "(e)Agriculture and allied sectors", 
                    "(f)Production of biofuels",
                    "(g) Building and operating a hotel cum convention center with a seating capacity not less than 3,500 persons.",
                    "(h)Developing, or operating and maintaining or developing, operating and maintaining infrastructure facilities.",
                    "(I)Dairy or poultry industry",
                    "(j)Seed research and development", 
                    "(k) Nanotechnology",
                    "(l)Information technology relating to software and hardware",
                    "(m) Such other sectors as the Central Government may notify in this behalf",
                    "(n)Production or manufacture of any article or substance for which a patent has been granted to the National Research Laboratory or any other scientific research Institution approved by the Department of science and Technology."
                    ]
                }
            ]
        },
        {
            question:'',
            items:[
                {
                    type:'paragraph',
                    text:"",
                },
                {
                    type:'list',
                    list:[""]
                }
            ]
        },

    ]


    return (

        <>

        <Card style={{marginBottom:'10px'}}>
            <Card.Header>
                <p className='h3'  style={{textAlign:'left'}}>Important Definitions</p>
            </Card.Header>  
        </Card>

        {qandas.map((qanda, index)=>(
            <QuestionItem question={qanda.question} items={qanda.items}/>
        ))}
        


        </>


    )
}


export default ImportantDefinitions
