import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';
import Section from '../pieces/Section'

const Cashbasisvmercantile = ({  }) => {

    var items = [
        {
            type:'paragraph',
            text:"There are generally two approaches that exist for recording accounting transactions."
        },
        {
            type:'header',
            text:"Cash Basis Accounting System"
        },
        {
            type:'paragraph',
            text:"An organization maintaining books of account on Cash Basis is recording all the cash and bank transactions only. What this means is that the Profit and Loss Account or Income and Expenditure account will show only actual income received and the actual expenses paid on the date on which those incomes were received or expenses incurred."
        },
        {
            type:'paragraph',
            text:"No provision is made for accrued income and accrued expenses."
        },
        {
            type:'paragraph',
            text:"For example, if an invoice shows a date of Dec 1, 2021 and the payment is made on Dec 3, 2021, the expense is recorded on Dec 3, 2021 as per the Cash Basis accounting system."
        },
        {
            type:'paragraph',
            text:"In India, the cash basis system  is usually followed by individuals, sole businesses, religious and charitable trusts, etc."
        },
        {
            type:'header',
            text:"Mercantile System/Accrual System"
        },
        {
            type:'paragraph',
            text:"The Books of Account maintained by an  organization on Mercantile System, record entries including the receivables and the payables on the date of the invoice though they may be due but not received or paid during the year."
        },
        {
            type:'paragraph',
            text:"The Profit and Loss account prepared by an enterprise on Mercantile System of accounting should, therefore, include all the income including receivables and all the expenses including those due as on the date of closing of the accounts."
        },
        {
            type:'paragraph',
            text:"Similarly, the Balance Sheet should also include all the liabilities including the ones accrued and the receivables due, as on the date of closing of accounts."
        },
        {
            type:'paragraph',
            text:"In India, this is the recommended method for businesses."
        }
    ]


    return (

        <>

        <Card>
            <Card.Header>
                <p className='h3'  style={{textAlign:'center'}}>Cash basis vs Mercantile System</p>
            </Card.Header>  
        </Card>

        <Section items={items}/>


        </>


    )
}


export default Cashbasisvmercantile
