//Recent Amendments in Income Tax Rates

var qandas = [
	{
		question:"What are the Income Tax rates under the old tax regime ?",
		items:[
			{
				text:"Income Tax rates under the old tax regime ( from Assessment Year 2018-19)"
			},
			{
				type:"table",
				title:"",
				headers:["Income Slab (Rs) ",  "Tax Rate"],
				table:[
				["Below     2,50,000",                             "nil"],
				["250000       -       5,00,000",                           "5%"],
				["500001       -     10,00,000",                         "20%"],
				["10,00,001 and above ",                                 "30%"]
				]
			},
			{
				text:"In the case of senior citizens,  the basic exemption limit was Rs 3 lakhs  and  super senior citizens Rs 5 lakhs."
			}
		]
	},
	{
 	question:"What are the Income Tax rates under the New tax regime ?",
	items:[
		{
			text:"The tax slabs  for individuals who opt for the new tax regime for the Financial Years 2022-2023 and 2023 -2024 :"
		},
		{
			type:"table",
			title:"Financial Year 2022-2023 (Assessment Year 2023-2024).",
			headers:["S.No.", "Income Slab", "Tax Rate"],
			table:[
	          ["1", "Below  Rs.2,50,000", "Nil"],
	          ["2", "Rs.2,50,001 -    Rs.5,00,000",  "5%"],
	          ["3", "Rs.5,00,001 -    Rs.7,50,000", "10%"],
	          ["4", "Rs.7,50,001 -   Rs.10,00,000", "15%"],
	          ["5", "Rs.10,00,001 - Rs.12,50,000", "20%"],
	          ["6", "Rs.12,50,001 - Rs.15,00,000", "25%"],
	          ["7", "Rs.15,00,001 and above","30%"]
			]
		}
	]
	},
	{
	question:"What are the changes made in Income slabs and tax rates under the Finance Budget 2023?",
	items:[
		{
 			text:"The Finance Budget 2023 has amended the slab under the new tax regime for individuals and HUF which is given below (Financial Year 2023-2024 (Assessment Year 2024-2025)"
		},
		{
			type:"table",
			headers:["S.No.", "Income Slab" ,"Tax Rate"],
			table:[
	     		["1","Below Rs.3,00,000", "Nil"],
	    		["2", "Rs. 3,00,001 -  Rs. 6,00,000", "5%"],
	    		["3", "Rs. 6,00,001 -  Rs. 9,00,000", "10%"],
	    		["4", "Rs. 9,00,001 - Rs. 12,00,000", "15%"],
	    		["5", "Rs. 12,00,001 - Rs.15,00,000", "20%"],
	    		["6", "Rs.15,00,001 and above", "30%"]
			]
		},
		{
			text:"The tax rates under the new tax regime  is the same for all categories of individuals.There is no extra basic exemption limit for senior citizens, super- senior citizens or HUF."
		},
		{
  			text:"The eligibility for income tax rebate has been increased from Rs 5 lakhs to Rs 7 lakhs for those who opt for the new tax regime"
		}
	]
}

]

export {qandas}


 //                           —--------------