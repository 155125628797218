import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {Alert} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form, Table, InputGroup } from 'react-bootstrap'
import {useHistory} from 'react-router-dom'


const ToolIntro = ({  }) => {

    const history = useHistory()

    const [accountname, setAccountname] = useState("") 



    return (

        <>
        
            <p className='h4'>Fintabb is an Excel-like application that helps create tables. Checkout the examples we have created using Fintabb. You can also build custom tables for your own purpose using the app.</p>
        
            {/* <p>Use the Financial Tables to Calculate or Estimate Different Financial Cases</p> */}

            <Link to='/fintabb'>
            <Button style={{borderRadius:'20px', backgroundColor:'#940d31'}}>
                Try out FinTabb
            </Button>
            </Link>


            <ListGroup style={{marginTop:'10px'}}>
            <ListGroup.Item>
                Analyse your Expenses
            </ListGroup.Item>
            <ListGroup.Item>
                Account a Business
            </ListGroup.Item>
            <ListGroup.Item>
                Analyze the Financials of A Company
            </ListGroup.Item>
            <ListGroup.Item>
                Project the Growth of Users
            </ListGroup.Item>
            <ListGroup.Item>
                Estimate Revenue and Cost Growth with Traction
            </ListGroup.Item>
            </ListGroup>



        </>


    )
}


export default ToolIntro
